
<div class="container hide-on-med-and-down">
    <div class="row">
        <div class="col s3 mayor">
           <app-lead-side-bar></app-lead-side-bar>
        </div>
        <div class="col s9">
           <h4 class="center">Mayoral Committee</h4>
           <hr>
           <div class="row">
              <div class="col s12 m4 l4">
                <img src="/assets/Council/Matz Mun_022 w.jpg"  alt="Matzikama Municipality Mayor" class="responsive-img"><br>
                <span class="flow-text center">Executive Mayor Mr. J. Van Der Hoven</span>
            </div>
            <div class="col s12 m4 l4">
                <img src="/assets/Council/Matz Mun_028 w.jpg"  alt="Matzikama Municipality Deputy Mayor" class="responsive-img"><br>
                <span class="flow-text center">Deputy Mayor Mrs. A. Job</span>
            </div>
            <div class="col s12 m4 l4">
              <img src="/assets/Council/IMG_9450.JPG"  alt="" class="responsive-img"><br>
              <span class="flow-text">Cllr. C. White</span>
            </div>
           </div>
           <div class="row">         
            <div class="col s12 m4 l4">
              <img src="/assets/Council/IMG_4651.JPG" alt="" class="responsive-img"><br>
              <span class="flow-text">Cllr. P. Cornelissen</span>
            </div>
            <div class="col s12 m4 l4">
              <img src="/assets/Council/Matz Mun_049 w.jpg" alt="" class="responsive-img"><br>
              <span class="flow-text">Cllr. Kito. Boks</span>
            </div>
          </div>
      </div>
    </div>

</div>
<div class="container hide-on-large-only">
    <div class="row">
        <h4 class="center">Mayoral Committee</h4>
    </div>
    <div class="row">
      <div class="col s12 m6 l6">
        <img src="/assets/Council/Matz Mun_022 w.jpg"  alt="Matzikama Municipality Mayor" class="responsive-img"><br>
         <span class="flow-text center">Executive Mayor Mr. J. Van Der Hoven</span>
    </div>
    <div class="col s12 m6 l6">
      <img src="/assets/Council/Matz Mun_028 w.jpg"  alt="Matzikama Municipality Deputy Mayor" class="responsive-img"><br>
      <span class="flow-text center">Deputy Mayor Mrs. A. Job</span>
    </div>
    </div>
    <div class="row">
        <div class="col s12 m4 l4">
          <img src="/assets/Council/IMG_9450.JPG"  alt="" class="responsive-img"><br>
          <span class="flow-text">Cllr. C. White</span>
      </div>
      <div class="col s12 m4 l4">
        <img src="/assets/Council/IMG_4651.JPG" alt="" class="responsive-img"><br>
        <span class="flow-text">Cllr. P. Cornelissen</span>
      </div>
      <div class="col s12 m4 l4">
        <img src="/assets/Council/Matz Mun_049 w.jpg" alt="" class="responsive-img"><br>
        <span class="flow-text">Cllr. Kito. Boks</span>
      </div>
  </div>
</div>
<app-footer></app-footer>


