
<div class="container">
        <h4 class="center">Strategic Documents</h4>
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible
                [mode]="'expandable'"
                [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                             <i class="material-icons">keyboard_arrow_down</i>
                                Integrated Development Plan
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item >
                                Matzikama Municipality 2025-2026 Draft IDP March 2025
                                <a href="/assets/StrategicDocs/Budget/2025-26/Draft IDP and Budget 2025-26.zip" mz-secondary-content download>
                                    {{idpMarch2526 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2024-2025 Approved Amended IDP May 2024
                                <a href="/assets/StrategicDocs/idp/2024-25/FINAL IDP - AMENDMENT MAY 2024.pdf" mz-secondary-content download>
                                    {{finalIDPMay24  | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                IDP 2024-25 Executive Summary - Matzikama Municpality
                                <a href="/assets/StrategicDocs/idp/IDP Executive Summary - Matzikama Municpality.pdf" mz-secondary-content download>
                                    {{finalIDPSummaryMay24  | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                2024-2025 Amended IDP May 2024 Sector Plans
                                <a href="/assets/StrategicDocs/idp/2024-25/Sector Plans.zip" mz-secondary-content download>
                                    {{finalIDPSectorPlansMay24 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                              </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2024-2025 Draft IDP March 2024
                                <a href="/assets/StrategicDocs/idp/2024-25/DRAFT IDP 25 March 2024.pdf" mz-secondary-content download>
                                    {{idpMarch2425 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                IDP & BUDGET PUBLIC PARTICIPATION SCHEDULE 2024-25
                                <a href="/assets/StrategicDocs/idp/2024-25/IDP-BUDGET NOTICE K352024.pdf" mz-secondary-content download>
                                    {{idp2425pp | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                              </mz-collection-item>
                            <mz-collection-item >
                                2024-2025 Draft IDP March 2024 Sector Plans
                                <a href="/assets/StrategicDocs/idp/2024-25/Sektor Plans - March 2024.zip" mz-secondary-content download>
                                    {{idp2425sp | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                              </mz-collection-item>
                            <mz-collection-item >
                                PUBLIC PARTICIPATION SCHEDULE 2023-24
                                <a href="/assets/municipal-notices/2023-24/Mat_Mun_publieke_deelname GOP (003).pdf" mz-secondary-content download>
                                    {{idp2324 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                              </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2023-2024 Approved IDP May 2023
                                <a href="/assets/StrategicDocs/idp/2023-24.zip" mz-secondary-content download>
                                    {{finalIDPMay2023  | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2023-2024 Draft IDP March 2023
                                <a href="/assets/StrategicDocs/Budget/2023-24/OneDrive_2023-03-29.zip" mz-secondary-content download>
                                    {{draftIDPMarch2023  | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                          <mz-collection-item >
                            IDP Executive Summary Advertisement
                            <a href="/assets/StrategicDocs/idp/Mat_mun_K40.pdf" mz-secondary-content download>
                                {{finalIDPMaySummaryAd2022  | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                          <mz-collection-item >
                            IDP Executive Summary - Matzikama Municpality
                            <a href="/assets/StrategicDocs/idp/IDP Executive Summary - Matzikama Municpality.pdf" mz-secondary-content download>
                                {{finalIDPMaySummary2022  | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                          <mz-collection-item >
                            Matzikama Municipality 2022-2023 Approved IDP & Sector Plans
                            <a href="/assets/StrategicDocs/idp/IDP May 2022 (Amendment).zip" mz-secondary-content download>
                                {{finalIDPMay2022  | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                          <mz-collection-item >
                            Matzikama Municipality 2022-2023 Draft IDP & Sector Plans
                            <a href="/assets/StrategicDocs/idp/OneDrive_2022-03-31.zip" mz-secondary-content download>
                                {{draftIDPMarch2022  | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality IDP BUDGET  PMS TIME SCHEDULE FOR 2021-2022
                                <a href="/assets/StrategicDocs/idp/MATZIKAMA TIME SCHEDULE -APPROVED 27 AUGUST 2021.pdf" mz-secondary-content download>
                                    {{timeSchedule2122UploadDate  | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Integrated Development Plan Final Review : 2017-2022
                                <a href="/assets/StrategicDocs/idp/Final IDP 2021-2022 - Approved by Council 28 May 2021.pdf" mz-secondary-content download>
                                    {{finalIdp2022UploadDate  | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <!-- <mz-collection-item >
                                <a [routerLink]="['/sectorPlans']">Click here for the 2021-22 IDP Sector Plans </a>
                            </mz-collection-item> -->
                            <mz-collection-item >
                                Integrated Development Plan Fourth Review : 2021-22
                                <a href="/assets/StrategicDocs/idp/REVIEWED IDP 2021-2022.pdf" mz-secondary-content download>
                                    {{draftIdp2022UploadDate  | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                <!-- View Integrated Development Plan Second Review : 2020-21 Sector Plans Here -->
                                <a [routerLink]="['/sectorPlans']"> View 2021-22 IDP Sector Plans here </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality IDP BUDGET  PMS TIME SCHEDULE FOR 2020-2021
                                <a href="/assets/StrategicDocs/idp/Matzikama Municipality IDP BUDGET  PMS TIME SCHEDULE FOR 2020-2021 (Approved 28 August 2020).pdf" mz-secondary-content download>
                                    {{timeSchedule2020  | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Amended Integrated Development Plan Third Review : 2020-21
                                <a href="/assets/StrategicDocs/idp/AMENDED IDP 2020-2021.pdf" mz-secondary-content download>
                                    {{finalIdp2021UploadDate  | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Integrated Development Plan Third Review : 2020-21
                                <a href="/assets/StrategicDocs/idp/IDP DRAFT 2020-2021 AMENDMENT - 26 MARCH 2020.pdf" mz-secondary-content download>
                                     {{draftIdp2021UploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                <!-- View Integrated Development Plan Second Review : 2020-21 Sector Plans Here -->
                                <a [routerLink]="['/sectorPlans']"> View 2020-21 IDP Sector Plans here </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                    Integrated Development Plan Second Review : 2019-20
                                    <a href="/assets/StrategicDocs/idp/IDP 28 May 2019.pdf" mz-secondary-content download>
                                        {{websiteLiveDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                             <mz-collection-item >
                                    Integrated Development Plan First Review : 2018-19
                                    <a href="/assets/StrategicDocs/idp/IDP 2018-19.pdf" mz-secondary-content download>
                                        {{websiteLiveDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                    Integrated Development Plan: 2016-17
                                    <a href="/assets/StrategicDocs/idp/IDP Final May 2016-17.pdf" mz-secondary-content download>
                                        {{websiteLiveDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
                <!-- End of IDP Collapsible & Collection -->
            <mz-collapsible-item [active]="true">
                <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                            Budget
                </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item >
                                Matzikama Municipality 2025-26 Draft Budget Documentation
                                <a href="/assets/StrategicDocs/Budget/2025-26/Draft IDP and Budget 2025-26.zip" mz-secondary-content download>

                                    {{draftMarch202526 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                WC011_Matzikama Adjustment Budget Report February 2025
                                <a href="/assets/StrategicDocs/Budget/2024-25/WC011_Matzikama municipality_2024 2025 Adjustment Budget Report_28 Feb 25.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetReportFeb2025 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                WC011_Matzikama municipality  Adjustment Budget B Schedule February 2025
                                <a href="/assets/StrategicDocs/Budget/2024-25/WC011_B Schedule - mSCOA Ver 6.8_2024 2025_28 Feb 25.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetFeb2025 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2024-2025 Approved Budget May 2024
                                <a href="/assets/StrategicDocs/Budget/2024-25/OneDrive_2024-05-21.zip" mz-secondary-content download>
                                    {{finalBudgetMay2024  | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                WC011_Matzikama Adjustment Budget Report May 2024
                                <a href="/assets/StrategicDocs/Budget/2023-24/Adjustment Budget May 2024/WC011_2023 2024_Matzikama Adjustment Budget Report 21 May 2024.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetReportMay2024 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                WC011_Matzikama municipality  Adjustment Budget B Schedule May 2024
                                <a href="/assets/StrategicDocs/Budget/2023-24/Adjustment Budget May 2024/WC011_B Schedule - 21 May 2024.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetMay2024 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2024-25 Draft Budget Documentation
                                <a href="/assets/StrategicDocs/Budget/2024-25/OneDrive_2024-03-27.zip" mz-secondary-content download>

                                    {{draftMarch202425 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                WC011_Matzikama Adjustment Budget Report 26 February 2024
                                <a href="/assets/StrategicDocs/Budget/2023-24/Adjustment Budget February 2024/WC011_Matzikama Adjustment Budget Report 26 February 2024.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetReportFeb2024 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                WC011_Matzikama municipality_B Schedule 26022024_2024
                                <a href="/assets/StrategicDocs/Budget/2023-24/Adjustment Budget February 2024/WC011_Matzikama municipality_B Schedule 26022024_2024.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetFeb2024 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                WC011_Matzikama Adjustment Budget Report October 2023
                                <a href="/assets/StrategicDocs/Budget/2023-24/Adjustment Budget October 2023/WC011_Matzikama Adjustment Budget Report_31 October 2023.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetReportOct2023 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality WC011_BSchedule mSCOA October 2023
                                <a href="/assets/StrategicDocs/Budget/2023-24/Adjustment Budget October 2023/WC011_Matzikama municipality_B Schedule_311023_2023_2024.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetOct2023 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                WC011_Matzikama Adjustment Budget Report June 2023
                                <a href="/assets/StrategicDocs/Budget/2022-23/Adjustment Budget June 2023/MATZIKAMA ADJUSTMENT BUDGET REPORT 29 JUNE 2023.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetReportJune2023 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality WC011_BSchedule mSCOA June 2023
                                <a href="/assets/StrategicDocs/Budget/2022-23/Adjustment Budget June 2023/WC011_BSchedule mSCOA 29 June 23.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetJune2023 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Council Resolution June 2023 Adjustment Budget
                                <a href="/assets/StrategicDocs/Budget/2022-23/Adjustment Budget June 2023/WC011_Council Resolution Adjustment budget 29 June 2023.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetCouncilResJune2023 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2023-2024 Approved Budget 2023
                                <a href="/assets/StrategicDocs/Budget/2023-24/OneDrive_2023-06-01.zip" mz-secondary-content download>
                                    {{finalBudgetMay2023  | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                WC011_Matzikama Adjustment Budget May 2023
                                <a href="/assets/StrategicDocs/Budget/2022-23/Adjustment Budget May 2023/WC011_Matzikama Adjustment Budget 31 May 2023.zip" mz-secondary-content download>

                                    {{adjustmentBudgetReportMay2023 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2023-2024 Draft Budget March 2023
                                <a href="/assets/StrategicDocs/Budget/2023-24/OneDrive_2023-03-29.zip" mz-secondary-content download>
                                    {{draftBudgetMarch2023  | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                WC011_Matzikama Adjustment Budget Report February 2023
                                <a href="/assets/StrategicDocs/Budget/2022-23/Adjustment Budget February 2023/WC011_Matzikama Adjustment Budget Report February 2023.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetReportFebruary2023 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality WC011_BSchedule mSCOA February 2023
                                <a href="/assets/StrategicDocs/Budget/2022-23/Adjustment Budget February 2023/WC011_BSchedule mSCOA February 2023.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetFebruary2023 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality WC011_ B-Schedule 2022-2023 December 2022
                                <a href="/assets/StrategicDocs/Budget/2022-23/Adjustment Budget December 2022/WC011_BSchedule mSCOA 6 December 2022.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetDecember2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality Adjustment Budget Report December 2022
                                <a href="/assets/StrategicDocs/Budget/2022-23/Adjustment Budget December 2022/Matzikama Second Adjustment Budget Report Dec 2022.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetReportDecember2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality WC011_ B-Schedule 2022-2023 30 October 2022
                                <a href="/assets/StrategicDocs/Budget/2022-23/Adjustment Budget October 2022/WC011_B-Schedule Adjustment Budget 25 October 2022.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetOctober2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality Adjustment Budget Report October 2022
                                <a href="/assets/StrategicDocs/Budget/2022-23/Adjustment Budget October 2022/WC011_B-Schedule Adjustment Budget 25 October 2022.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetReportOctober2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality WC011_ B-Schedule 2022-2023 30 October 2022
                                <a href="/assets/StrategicDocs/Budget/2022-23/Adjustment Budget October 2022/WC011_B-Schedule Adjustment Budget 25 October 2022.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetOctober2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality Adjustment Budget Report October 2022
                                <a href="/assets/StrategicDocs/Budget/2022-23/Adjustment Budget October 2022/WC011_B-Schedule Adjustment Budget 25 October 2022.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetReportOctober2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                          <mz-collection-item >
                            Matzikama Municipality WC011_ B-Schedule 2021-2022  _ 30 June 2022
                            <a href="/assets/StrategicDocs/Budget/2021-22/Adjustment Budget June 2022/WC011_ B-Schedule 2021-2022  _ 30 June 2022.pdf" mz-secondary-content download>

                                {{adjustmentBudgetJune2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                <i class=" material-icons download rigth" download>cloud_download</i>

                            </a>
                        </mz-collection-item>
                          <mz-collection-item >
                            Matzikama Municipality Adjustment Budget Report_ 30 June 2022
                            <a href="/assets/StrategicDocs/Budget/2021-22/Adjustment Budget June 2022/WC011_Adjustment Budget Report_ 30 June 2022.pdf" mz-secondary-content download>

                                {{adjustmentBudgetReportJune2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                <i class=" material-icons download rigth" download>cloud_download</i>

                            </a>
                        </mz-collection-item>
                          <mz-collection-item >
                            Matzikama Municipality 2022-2023 Approved Budget & Policies
                            <a href="/assets/StrategicDocs/Budget/2022-23/OneDrive_2022-06-07.zip" mz-secondary-content download>

                                {{finalBudgetMay2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                <i class=" material-icons download rigth" download>cloud_download</i>

                            </a>
                        </mz-collection-item>
                            <mz-collection-item >
                              Matzikama Municipality 2022-2023 Draft Budget & Policies
                              <a href="/assets/StrategicDocs/Budget/2022-23/2022-23 Draft Budget And Policies.zip" mz-secondary-content download>

                                  {{draftBudgetMarch2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                  <i class=" material-icons download rigth" download>cloud_download</i>

                              </a>
                          </mz-collection-item>
                          <mz-collection-item >
                              Matzikama Adjustment Budget Report February 2022
                              <a href="/assets/StrategicDocs/Budget/2021-22/Adjustment Budget Feb 2022/Matzikama Adjustment Budget Report February 2022.pdf" mz-secondary-content download>

                                  {{adjustmentBudgetFebruary2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                  <i class=" material-icons download rigth" download>cloud_download</i>

                              </a>
                          </mz-collection-item>
                          <mz-collection-item >
                              Matzikama Municipality B Schedule - mSCOA Ver 6.5 - Matzikama 28 Feb 2022
                              <a href="/assets/StrategicDocs/Budget/2021-22/Adjustment Budget Feb 2022/B Schedule - mSCOA Ver 6.5 - Matzikama 28 Feb 2022.pdf" mz-secondary-content download>

                                  {{adjustmentBudgetFebruary2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                  <i class=" material-icons download rigth" download>cloud_download</i>

                              </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality Adjustment Budget Report June 2021
                                <a href="/assets/StrategicDocs/Budget/2021-22/Adjustment Budget June 2021/Matzikama Adjustment Budget Report June 2021.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetJune2021 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality B Schedule - mSCOA Ver 6.4 - WC011 June 2021
                                <a href="/assets/StrategicDocs/Budget/2021-22/Adjustment Budget June 2021/B Schedule - mSCOA Ver 6.4 - WC011 June 2021.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetJune2021 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                COUNCIL RESOLUTION ADJUSTMENT BUDGET 29 JUNE 2021
                                <a href="/assets/StrategicDocs/Budget/2021-22/Adjustment Budget June 2021/COUNCIL RESOLUTION ADJUSTMENT BUDGET 29 JUNE 2021.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetJune2021 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2021-2022 MTREF Final Budget Report 28 May 2021
                                <a href="/assets/StrategicDocs/Budget/2021-22/Final Budget May 2021/Matzikama Municipality 2021-2022 MTREF Final Budget Report 28 May 2021.pdf" mz-secondary-content download>

                                    {{finalBudget2022UploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality A1 Schedule - mSCOA vs 6.5 - WC011_28 May 2021
                                <a href="/assets/StrategicDocs/Budget/2021-22/Final Budget May 2021/A1 Schedule - mSCOA vs 6.5 - WC011_28 May 2021.pdf" mz-secondary-content download>

                                    {{finalA12022UploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2021-2022 Final Tariffs
                                <a href="/assets/StrategicDocs/Budget/2021-22/Final Budget May 2021/2021 - 2022  MATZIKAMA FINAL TARIFFS.pdf" mz-secondary-content download>

                                    {{finalTariffs2022UploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                <a [routerLink]="['/finpol']">Click here for the Final 2021-22 Budget Policies</a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2021-2022 MTREF Draft Budget Report
                                <a href="/assets/StrategicDocs/Budget/2021-22/Draft Budget March 2021/Matzikama Municipality 2021-2022 MTREF Draft Budget Report 30 March 2021.pdf" mz-secondary-content download>

                                    {{draftBudget2022UploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                2021-22 Annexure A - A1 Schedule - mSCOA vs 6.5 - WC011
                                <a href="/assets/StrategicDocs/Budget/2021-22/Draft Budget March 2021/A1 Schedule - mSCOA vs 6.5 - 30 March 2021 Final.pdf" mz-secondary-content download>

                                    {{draftA12022UploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Annexure B - Matzikamam Municipality Draft Tariffs 2021 - 2022
                                <a href="/assets/StrategicDocs/Budget/2021-22/Draft Budget March 2021/MATZIKAMA MUNICIPALITY DRAFT 2021 2022 TARIFFS.pdf" mz-secondary-content download>

                                    {{draftTariffs2022UploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                <a [routerLink]="['/finpol']"> View Draft 2021-22 Budget Policies here </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality Adjustment Budget Report March 2021
                                <a href="/assets/StrategicDocs/Budget/2020-21/Adjustment Budget  March 2021/Matzikama Adjustment Budget Report March 2021.pdf" mz-secondary-content download>
                                    {{adjustmentBudgetMarch2021 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality Adjustment Budget B-Schedule March 2021
                                <a href="/assets/StrategicDocs/Budget/2020-21/Adjustment Budget  March 2021/B Schedule - mSCOA Ver 6.4 - WC011 March 2021.pdf" mz-secondary-content download>
                                    {{adjustmentBudgetMarch2021 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality Adjustment Budget February 2021
                                <a href="/assets/StrategicDocs/Budget/2020-21/Adjustment Budget  February 2021/Matzikama Adjustment Budget Report February 2021.pdf" mz-secondary-content download>
                                    {{adjustmentBudgetFebruary2021 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality B-Schedule February 2021
                                <a href="/assets/StrategicDocs/Budget/2020-21/Adjustment Budget  February 2021/B Schedule - mSCOA Ver 6.4 - Working Paper - Final .pdf" mz-secondary-content download>

                                    {{adjustmentBudgetFebruary2021 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>

                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality Adjustment Budget December 2020
                                <a href="/assets/StrategicDocs/Budget/2020-21/Adjustment Budget December 2020/Matzikama Adjustment Budget Report November 2020.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetDecember2020 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality Adjustment Budget Report December 2020
                                <a href="/assets/StrategicDocs/Budget/2020-21/Adjustment Budget December 2020/B Schedule - mSCOA Ver 6.4 - WC011 Second Adjustment budget.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetDecemberReport2020 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality Adjustment Budget August 2020
                                <a href="/assets/StrategicDocs/Budget/2020-21/Adjustment Budget August 2020/B Schedule - mSCOA Ver 6.4 - WC011.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetAugust2020 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality Adjustment Budget Report August 2020
                                <a href="/assets/StrategicDocs/Budget/2020-21/Adjustment Budget August 2020/Matzikama Adjustment Budget Report August 2020.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetAugust2020 | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality Rates Levy Notice
                                <a href="/assets/StrategicDocs/Budget/2020-21/BELASTING ADVERTENSIE - ONS KONTREI.pdf" mz-secondary-content download>

                                    {{ratesLevyUploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2020-2021 MTREF Final Budget Report 29 May 2020
                                <a href="/assets/StrategicDocs/Budget/2020-21/finalBudget/Matzikama Municipality 2020-2021 MTREF Final Budget Report.pdf" mz-secondary-content download>

                                    {{finalBudgetReport2021UploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                <a href="/assets/StrategicDocs/finpol/2020-21/final/MATZIKAMA MUNISIPALITEIT FINAL 2020 - 2021 TARRIFS  LISTING.pdf" mz-secondary-content download>

                                    {{finalBudget2021UploadDate | date: 'medium'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2020-21 MTREF FINAL Budget 29 May 2020
                                <a href="/assets/StrategicDocs/Budget/2020-21/finalBudget/A1 Schedule - mSCOA vs 6.4 - WC011.pdf" mz-secondary-content download>

                                    {{finalBudget2021UploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2020-2021 Approved Tariffs Budget 29 May 2020
                                <a href="/assets/StrategicDocs/finpol/2020-21/final/MATZIKAMA MUNISIPALITEIT FINAL 2020 - 2021 TARRIFS  LISTING.pdf" mz-secondary-content download>

                                    {{finalBudgetReport2021UploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                <a [routerLink]="['/finpol']"> View Final 2020-21 Budget Policies here </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2020-21 Adjustment Budget 29 May 2020
                                <a href="/assets/StrategicDocs/Budget/2020-21/finalBudget/Matzikama Adjustment Budget Report May 2020.pdf" mz-secondary-content download>

                                    {{adjustmentBudgetUploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2020-2021 MTREF Draft Budget Report
                                <a href="/assets/StrategicDocs/Budget/2020-21/Matzikama Municipality 2020-2021 MTREF Draft Budget Report.pdf" mz-secondary-content download>

                                    {{draftBudget2021UploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                2020-21 Annexure A - A1 Schedule - mSCOA vs 6.4 - WC011
                                <a href="/assets/StrategicDocs/Budget/2020-21/Annexure A - A1 Schedule - mSCOA vs 6.4 - WC011.pdf" mz-secondary-content download>

                                    {{draftBudget2021UploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Annexure B - Matzikamam Municipality Draft Tariffs 2020 - 2021
                                <a href="/assets/StrategicDocs/Budget/2020-21/Annexure B - Matzikamam Municipality Draft Tariffs 2020 - 2021.pdf" mz-secondary-content download>

                                    {{draftBudget2021UploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}

                                    <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                <a [routerLink]="['/finpol']"> View Draft 2020-21 Budget Policies here </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Adjustment Budget Report February 2020
                                    <a href="/assets/StrategicDocs/Budget/2019-20/Matzikama Adjustment Budget Report February 2020.pdf" mz-secondary-content download>
                                        {{adjustmentBudgetFeb2020 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                B Schedule - Matzikama Muncipality WC011_28 Feb 2020
                                    <a href="/assets/StrategicDocs/Budget/2019-20/B Schedule - Matzikama Muncipality WC011_28 Feb 2020.pdf" mz-secondary-content download>
                                        {{adjustmentBudgetFeb2020 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
                  <!-- End of Budget Collapsible & Collection -->
            <mz-collapsible-item [active]="true">
                <mz-collapsible-item-header>
                                <i class="material-icons">keyboard_arrow_down</i>
                                    SDBIP
                </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item >
                                Matzikama Municipality 2024-25 February 2025 Amended SDBIP
                                <a href="/assets/StrategicDocs/SDBIP/WC011_Amended SDBIP 2024-25_28  Feb 2025.pdf" mz-secondary-content download>
                                    {{adjustmentSDBIPReportFebruary2025 | date: 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2024-25 Approved SDBIP June 2024
                                <a href="/assets/StrategicDocs/SDBIP/SDBIP 2024_2025 - Signed.pdf" mz-secondary-content download>
                                    {{finalSDBIPJune2024 | date: 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2023-24 May 2024 Amended SDBIP
                                <a href="/assets/StrategicDocs/SDBIP/Matzikama Municipality 2023-24 SDBIP Amended May 2024 final.pdf" mz-secondary-content download>
                                    {{adjustmentSDBIPReportMay2024 | date: 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2023-24 February 2024 Amended SDBIP
                                <a href="/assets/StrategicDocs/SDBIP/Matzikama Municipality 2023-24 SDBIP Amended Feb 2024 final.pdf" mz-secondary-content download>
                                    {{adjustmentSDBIPReportFebruary2024 | date: 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2023-24 Approved SDBIP
                                <a href="/assets/StrategicDocs/SDBIP/Matzikama Municipality 2023-24 Final SDBIP Signed.pdf" mz-secondary-content download>
                                    {{finalSDBIP2023_24UploadDate | date: 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2022-23 May 2023 Amended SDBIP
                                <a href="/assets/StrategicDocs/SDBIP/Matzikama Municipality 2022-23 May 2023 Amended SDBIP.pdf" mz-secondary-content download>
                                    {{adjustmentSDBIPReportMay2023 | date: 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2023-24 Draft SDBIP
                                <a href="/assets/StrategicDocs/SDBIP/Matzikama Municipality 2023-24 Draft Final SDBIP.pdf" mz-secondary-content download>
                                    {{adjustmentSDBIPReportFebruary2023 | date: 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2022-23 Amended SDBIP February 2023
                                <a href="/assets/StrategicDocs/SDBIP/Matzikama Municipality 2022-23 February Amended SDBIP.pdf" mz-secondary-content download>
                                    {{adjustmentSDBIPReportFebruary2023 | date: 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2022-23 Approved SDBIP June 2022
                                <a href="/assets/StrategicDocs/SDBIP/Matzikama Municipality 2022-23 Final SDBIP Signed.pdf" mz-secondary-content download>
                                    {{finalSDBIP2022_23UploadDate | date: 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality Amended 2021-22 Approved SDBIP June 2022
                                <a href="/assets/StrategicDocs/SDBIP/June 2022 Matzikama Municipality Amended 2021-22 SDBIP.pdf" mz-secondary-content download>
                                    {{juneSDBIP2021_22UploadDate | date: 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                          <mz-collection-item >
                            Matzikama Municipality Amended 2021-22 Approved SDBIP February 2022
                            <a href="/assets/StrategicDocs/SDBIP/February 2022 Matzikama Municipality Amended 2021-22 SDBIP_2.pdf" mz-secondary-content download>
                                {{finalSDBIP2022_2UploadDate | date: 'MMM, dd yyyy, HH:mm aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2021-22 Approved SDBIP July 2021
                                <a href="/assets/StrategicDocs/SDBIP/Matzikama Municipality 2021-22 Final SDBIP Signed.pdf" mz-secondary-content download>
                                    {{finalSDBIP2022UploadDate | date: 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2020-21 Approved Amended SDBIP February 2021
                                <a href="/assets/StrategicDocs/SDBIP/February 2021 Matzikama Municipality Amended 2020-21 SDBIP Final.pdf" mz-secondary-content download>
                                    {{amendedSDBIPFebruary2021 | date: 'MMM, dd yyyy, HH:mm:ss aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2020-21 Approved SDBIP
                                <a href="/assets/StrategicDocs/SDBIP/Matzikama Municipality 2020-21 Approved Top Layer SDBIP.pdf" mz-secondary-content download>
                                    {{finalSDBIP2021UploadDate | date: 'MMM, dd yyyy, HH:mm:ss aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item>
                                Matzikama Municipality 2020-21 Draft SDBIP
                                <a href="/assets/sectorPlans/2020-21/Matzikama Municipality 2020-21 Final Draft SDBIP.pdf"mz-secondary-content download>

                                    {{draftSDBIP2021UploadDate | date: 'MMM, dd yyyy, HH:mm:ss aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Matzikama Municipality 2020-21 Draft SDBIP
                                <a href="/assets/sectorPlans/2020-21/Matzikama Municipality 2020-21 Final Draft SDBIP.pdf"mz-secondary-content download>

                                    {{draftSDBIP2021UploadDate | date: 'MMM, dd yyyy, HH:mm:ss aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                February 2020 Matzikama Municipality Amended 2019-20 SDBIP
                                <a href="/assets/StrategicDocs/SDBIP/February 2020 Matzikama Municipality Amended 2019-20 SDBIP.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                            <mz-collection-item >
                                    Matzikama Municipality 2019-20 Final Approved SDBIP
                                    <a href="/assets/StrategicDocs/SDBIP/Matzikama Municipality 2019-20 Final Approved SDBIP.pdf" mz-secondary-content download>
                                        {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                    Matzikama Municipality Amended 2018-19 SDBIP May 2019
                                    <a href="/assets/StrategicDocs/SDBIP/Matzikama Municipality Amended 2018-19 SDBIP May 2019.pdf" mz-secondary-content download>
                                        {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                    Matzikama Municipality Adjustment Budget 2018-19 SDBIP
                                    <a href="/assets/StrategicDocs/SDBIP/Matzikama Municipality Adjustment Budget 2018-19 SDBIP.pdf" mz-secondary-content download>
                                        {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                    Matzikama Municipality SDBIP 2018-19
                                    <a href="/assets/StrategicDocs/SDBIP/Matzikama Municipality SDBIP 2018-19.pdf" mz-secondary-content download>
                                        {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                    Matzikama Municipality Approved SDBIP 2017-18_WC011
                                    <a href="/assets/StrategicDocs/SDBIP/Matzikama Municipality Approved SDBIP 2017-18_WC011.pdf" mz-secondary-content download>
                                        {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                    Matzikama Municipality Approved Top Layer SDBIP
                                    <a href="/assets/StrategicDocs/SDBIP/Matzikama Municipality Approved Top Layer SDBIP.pdf" mz-secondary-content download>
                                        {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                    TOP-LAYER-SDBIP-2015.16
                                    <a href="/assets/StrategicDocs/SDBIP/TOP-LAYER-SDBIP-2015.16.pdf" mz-secondary-content download>
                                        {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                    Matzikama Municipality SDBIP-2014-2015
                                    <a href="/assets/StrategicDocs/SDBIP/SDBIP-2014-2015.pdf" mz-secondary-content download>
                                        {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
            </mz-collapsible-item>
            <!-- End of SDBIP Collapsible & Collection -->
             <mz-collapsible-item [active]="true">
                <mz-collapsible-item-header>
                                <i class="material-icons">keyboard_arrow_down</i>
                                    Annual Report
                </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item >
                                Matzikama Municipality Oversight Report on 2023-24 Annual Report
                                <a href="/assets/StrategicDocs/annual report/Matzikama Municipality Oversight Report on 2023-24 Annual Report.pdf" mz-secondary-content download>
                                    {{oversightReportMarch2025 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2023-24 Council Approved Annual Report
                                <a href="/assets/StrategicDocs/annual report/Matzikama Municipality 2023-24 Council Approved Annual Report.pdf" mz-secondary-content download>
                                    {{approvedCouncilAR2025UploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality Annual Report In Respect Of The Financial Year 2023-2024
                                <a href="/assets/StrategicDocs/annual report/Matzikama Municipality 2023-24 Council Approved Draft Annual Report.pdf" mz-secondary-content download>
                                    {{approvedAR2025UploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality Oversight Report on 2022-23 Annual Report
                                <a href="/assets/StrategicDocs/annual report/Matzikama Municipality Oversight Report on 2022-23 Annual Report.pdf" mz-secondary-content download>
                                    {{oversightReportMarch2024 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2022-23 Council Approved Annual Report
                                <a href="/assets/StrategicDocs/annual report/Matzikama Municipality 2022-23 Council Approved Final Annual Report.pdf" mz-secondary-content download>
                                    {{approvedAR2024UploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                ANNUAL REPORT ADDITIONAL COMPONENTS IN RESPECT OF THE FINANCIAL YEAR 2022-2023
                                <a href="/assets/StrategicDocs/annual report/Combined Draft AR AG Letter Fin Statement GRAP.pdf" mz-secondary-content download>
                                    {{finalAnnualReportAC2024UploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                MATZIKAMA MUNICIPALITY ANNUAL REPORT IN RESPECT OF THE FINANCIAL YEAR 2022-2023
                                <a href="/assets/StrategicDocs/annual report/Matzikama Municipality 2022-23 Approved Draft Annual Report.pdf" mz-secondary-content download>
                                    {{finalAnnualReport2024UploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2021-22 Council Approved Final Annual Report
                                <a href="/assets/StrategicDocs/annual report/Matzikama Municipality 2021-22 Council Approved Final Annual Report.pdf" mz-secondary-content download>
                                    {{finalAnnualReport2022UploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality Oversight Report on 2021-22 Annual Report
                                <a href="/assets/StrategicDocs/annual report/MPAC Oversight Report 2021-22 Signed.pdf" mz-secondary-content download>
                                    {{oversightReportMarch2023 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2021-22 Council Approved Draft Annual Report
                                <a href="/assets/StrategicDocs/annual report/Matzikama Municipality 2021-22 Council Approved Draft Annual Report.pdf" mz-secondary-content download>
                                    {{draftAnnualReport2022UploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                          <mz-collection-item >
                            Matzikama Municipality Oversight Report on 2020-21 Annual Report
                            <a href="/assets/StrategicDocs/annual report/MPAC Oversight Report on the 2021 Annual Report_March 2022.pdf" mz-secondary-content download>
                                {{oversightReportMarch2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                          <mz-collection-item >
                            Matzikama Municipality 2020-21 Council Approved Annual Report
                            <a href="/assets/StrategicDocs/annual report/Matzikama Municipality 2020-21 Council Approved Final Annual Report.pdf" mz-secondary-content download>
                                {{final2020_21AnnualReportMarch2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2020-21 Draft Annual Report
                                <a href="/assets/StrategicDocs/annual report/Matzikama Municipality 2020-21 Draft Annual Report.pdf" mz-secondary-content download>
                                    {{draftAnnualReport2021UploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2019-20 Approved Annual Report
                                <a href="/assets/StrategicDocs/annual report/Matzikama Municipality 2019-20 Council Approved Final Annual Report.pdf" mz-secondary-content download>
                                    {{approvedAnnualReport1920UploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2019-20 Oversight Report
                                <a href="/assets/StrategicDocs/annual report/MPAC Oversight Report 2019-20 Signed.pdf" mz-secondary-content download>
                                    {{oversightReport201920 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2019-20 Draft Annual Report
                                <a href="/assets/StrategicDocs/annual report/Matzikama Municipality 2019-20 Approved Draft Annual Report.pdf" mz-secondary-content download>
                                    {{draftAnnualReport1920UploadDate | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2018-19 Approved Annual Report
                                <a href="/assets/StrategicDocs/annual report/Matzikama Municipality 2018-19 Council Approved Annual Report.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2018-19 Oversight Report
                                <a href="/assets/StrategicDocs/annual report/Matzikama Municipality Oversight Report on 2018-19 Annual Report.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama Municipality 2018-19 Council Approved Draft Annual Report
                                <a href="/assets/StrategicDocs/annual report/Matzikama Municipality 2018-19 Approved Draft Annual Report.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                    Matzikama Municipality 2017-18 Council Approved Final Annual Report
                                    <a href="/assets/StrategicDocs/annual report/Matzikama Municipality 2017-18 Council Approved Final Annual Report.pdf" mz-secondary-content download>
                                        {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                    Matzikama Municipality 2014-15 Council Approved Final Annual Report
                                    <a href="/assets/StrategicDocs/annual report/Matzikama Municipality 2014-15 Council Approved Final Annual Report.pdf" mz-secondary-content download>
                                        {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                    Matzikama-Final-Annual-Report-for-2011_12
                                    <a href="/assets/StrategicDocs/annual report/Matzikama-Final-Annual-Report-for-2011_12.pdf" mz-secondary-content download>
                                        {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                        </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
               <!-- End of Annual Report Collapsible & Collection -->
            <mz-collapsible-item [active]="true">
                <mz-collapsible-item-header>
                            <i class="material-icons">keyboard_arrow_down</i>
                                    Spatial Development Framework (SDF)
                </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <a [routerLink]="['/townplanning']"> View SDF here </a>
         <!--                    <mz-collection-item>
                                Spatial Development Framework
                                <a href="/assets/sectorPlans/2020-21/Matzikama Municipality 2020-21 Final Draft SDBIP.pdf"mz-secondary-content download>

                                    {{draftSDBIP2021UploadDate | date: 'MMM, dd yyyy, HH:mm:ss aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item> -->
                          <!--   <mz-collection-item >
                                    Amendment to the Matzikama SDF 2018 - May 2018
                                    <a href="/assets/StrategicDocs/sdf/Amendment to the Matzikama SDF 2018 - May 2018 - SML.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                            </mz-collection-item> -->
                           <!--  <mz-collection-item >
                                Matzikama Municipality Spatial Status Quo
                                    <a href="/assets/StrategicDocs/annual report/Matzikama Municipality 2014-15 Council Approved Final Annual Report.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                            </mz-collection-item>
                            <mz-collection-item >
                                Matzikama-Final-Annual-Report-for-2011_12
                                    <a href="/assets/StrategicDocs/annual report/Matzikama-Final-Annual-Report-for-2011_12.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                            </mz-collection-item>   -->
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
                   <!-- End of SDF Collapsible & Collection -->
            </mz-collapsible>
        </div>
    </div>
</div>
