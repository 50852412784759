import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-midyear',
  templateUrl: './midyear.component.html',
  styleUrls: ['./midyear.component.css']
})
export class MidyearComponent implements OnInit {

  scmJanMarch2020 : Date = new Date('2020-04-17 22:07');
  scmAprJun2020 : Date = new Date('2020-07-09 16:01');
  scmSept2020 : Date = new Date('2020-10-09 15:41');
  scmQ22021 : Date = new Date('2021-01-12 12:46');
  scmQ22021Mayor : Date = new Date('2021-01-12 12:55');
  s52Q3:Date = new Date('2020-06-03 12:44:04');
  s52Q4:Date = new Date('2020-07-30 16:43:20');
  s52Q4_2:Date = new Date('2020-11-24 16:10');
  s52Q3_20_21:Date = new Date('2021-04-30 14:37');
  s52Q4_20_21:Date = new Date('2021-07-30 11:30');
  s52Q1_22_23:Date = new Date('2022-10-28 08:33');
  s52Q2_22_23:Date = new Date('2023-02-01 15:12');
  s522021Q1:Date = new Date('2020-10-28 15:49');
  s522021Q2:Date = new Date('2021-01-26 09:44');
  s522022Q1:Date = new Date('2021-10-26 12:31');
  s522022Q2:Date = new Date('2022-01-26 08:45');
  s522022Q3:Date = new Date('2022-05-03 22:44');
  s522022Q4:Date = new Date('2022-07-29 09:23');
  websiteLiveDate :Date = new Date('2020-01-16');
  s72Jan2020UploadDate :Date = new Date('2020-03-02 14:58:06');
  s72Jan2021UploadDate :Date = new Date('2021-01-26 09:52');
  s72Jan2022UploadDate :Date = new Date('2022-01-26 08:50');
  s72Jan2023UploadDate :Date = new Date('2023-01-25 11:05');
  s72Jan2024UploadDate :Date = new Date('2024-02-02 08:17');
  scmQ42021:Date = new Date('2021-07-09 11:35');
  scmQ32021:Date = new Date('2021-04-14 14:33');
  scmQ12022:Date = new Date('2021-10-15 11:04');
  scmQ22022:Date = new Date('2022-01-12 10:35');
  scmQ32022:Date = new Date('2022-04-14 11:04');
  scmQ42022:Date = new Date('2022-07-08 08:49');
  scmQ12023:Date = new Date('2022-10-08 09:37');
  scmQ22023:Date = new Date('2023-01-23 10:13');
  scmQ32023:Date = new Date('2023-04-17 14:24');
  scmQ42023:Date = new Date('2023-04-17 14:24');
  scmQ12024:Date = new Date('2023-10-18 12:55');
  scmQ22024:Date = new Date('2024-01-23 09:36');
  scmQ32024:Date = new Date('2023-04-17 14:24');
  scmQ42024:Date = new Date('2024-07-10 16:20');
  scmQ22025:Date = new Date('2025-02-04 10:12');
  scmQ12025:Date = new Date('2024-10-10 15:42');
  scmQ32025:Date = new Date('2025-04-08 14:26');
  s52Q3_22_23:Date = new Date('2023-04-21 14:28');
  s52Q4_22_23:Date = new Date('2023-07-28 10:10');
  s52Q1_23_24:Date = new Date('2023-10-31 15:23');
  s52Q2_23_24:Date = new Date('2024-02-02 08:27');
  s52Q3_23_24:Date = new Date('2024-04-30 18:41');
  s52Q4_23_24:Date = new Date('2024-08-07 15:30');
  s52Q1_24_25:Date = new Date('2024-11-01 11:50');
  s52Q2_24_25:Date = new Date('2025-01-31 10:54');
  s72Jan2025UploadDate:Date = new Date('2025-01-31 10:57');


















  constructor() { }

  ngOnInit() {
  }

}
