<app-townplanning-nav></app-townplanning-nav>
<div class="container">
    <h5 class="center">TownPlanning Applications</h5>
    <div class="row">
        <div class="cols12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                        Townplanning Applications 2024-25
                    </mz-collapsible-item-header>     
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item>
                                Erf 662, Vanrhynsdorp 
                            <a href="/assets/townplanning/applications/2024-25/Erf 622 Vanrhynsdorp.pdf" mz-secondary-content download>
                                {{Erf662Vanrhynsdorp   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                            </mz-collection-item>
                                <mz-collection-item>
                                    Erf 1539, Vredendal
                                <a href="/assets/townplanning/applications/2024-25/Erf 1539 Vredendal.pdf" mz-secondary-content download>
                                    {{Erf1539Vredendal   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                                </mz-collection-item>
                                <mz-collection-item>
                                    Erf 308, Vredendal 
                                <a href="/assets/townplanning/applications/2024-25/Erwe 308_Vredendal.pdf" mz-secondary-content download>
                                    {{Erf308Vredendal   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                                </mz-collection-item>
                                <mz-collection-item>
                                    Erf 44, Lutzville
                                  <a href="/assets/townplanning/applications/2024-25/Erf 44, Lutzville.pdf" mz-secondary-content download>
                                     {{Erf44Lutzville   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erven 316, 319 & 320, Vredendal
                                  <a href="/assets/townplanning/applications/2024-25/29_07_2024_A3_KENNISGEWING _316-320_Vredendal_Approved.pdf" mz-secondary-content download>
                                     {{Erf316319320Vredendal   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 1622, Klawer
                                  <a href="/assets/townplanning/applications/2024-25/Erf 1622 Klawer.pdf" mz-secondary-content download>
                                     {{Erf1622Klawer   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 2515, Vredendal
                                  <a href="/assets/townplanning/applications/2024-25/Erf 2515, Vredendal.pdf" mz-secondary-content download>
                                     {{Erf2515Vredendal   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
    <div class="row">
        <div class="cols12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                        Townplanning Applications 2023-24
                    </mz-collapsible-item-header>     
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item>
                                Erf 108, Lutzville
                                  <a href="/assets/townplanning/applications/2023-24/Erf 108, Lutzville.pdf" mz-secondary-content download>
                                     {{Erf108Lutzville   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 296, Klawer
                                  <a href="/assets/townplanning/applications/2023-24/Erf 296, Klawer.pdf" mz-secondary-content download>
                                     {{Erf296   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 1952, Klawer
                                  <a href="/assets/townplanning/applications/2023-24/Erf 1952, Klawer.pdf" mz-secondary-content download>
                                     {{Erf1952   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 53, Klawer
                                  <a href="/assets/townplanning/applications/2023-24/Erf 53, Klawer.pdf" mz-secondary-content download>
                                     {{Erf53   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 549, Vanrhynsdorp
                                  <a href="/assets/townplanning/applications/2023-24/Erf 549, Vanrhynsdorp.pdf" mz-secondary-content download>
                                     {{Erf549   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Portion 7 of the Farm Duinen No. 258, Vanrhynsdorp
                                  <a href="/assets/townplanning/applications/2023-24/Pls 258.7.pdf" mz-secondary-content download>
                                     {{Pls258_7   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 48, Lutzville
                                  <a href="/assets/townplanning/applications/2023-24/Erf 48, Lutzville.pdf" mz-secondary-content download>
                                     {{Erf48UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Plot 591, Olifantsriver Settlement
                                  <a href="/assets/townplanning/applications/2023-24/Plot 591, ORN.pdf" mz-secondary-content download>
                                     {{Plot591UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erven 11, 12, 13, 14 & 15, Nieuwe Rust
                                  <a href="/assets/townplanning/applications/2023-24/Erwe 11-15_Vredendal.pdf" mz-secondary-content download>
                                     {{Erf1115UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 810 Vredendal
                                  <a href="/assets/townplanning/applications/2023-24/Erf 810 Vredendal.pdf" mz-secondary-content download>
                                     {{Erf810UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Remainder of the Farm Paddock No. 257, Vanrhynsdorp
                                  <a href="/assets/townplanning/applications/2023-24/Pls 257, Vanrhynsdorp.pdf" mz-secondary-content download>
                                     {{Pls257UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Pls 308,±10km north of Klawer to the east of the N7 to Vanrhynsdorp
                                  <a href="/assets/townplanning/applications/2023-24/Pls 308.pdf" mz-secondary-content download>
                                     {{Pls308UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 479,Vredendal
                                  <a href="/assets/townplanning/applications/2023-24/Pls 292.354,Vredendal.pdf" mz-secondary-content download>
                                     {{Erf479VredendalUploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Pls 292.354,Vredendal
                                  <a href="/assets/townplanning/applications/2023-24/Pls 292.354,Vredendal.pdf" mz-secondary-content download>
                                     {{Pls292_354VredendalUploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 682, Lutzville
                                  <a href="/assets/townplanning/applications/2023-24/Erf 682 Lutzville.pdf" mz-secondary-content download>
                                     {{Erf682LutzvilleUploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 691, Louis Rood
                                  <a href="/assets/townplanning/applications/2023-24/Erf 691, Louis Rood.pdf" mz-secondary-content download>
                                     {{Erf691LouisRoodUploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Pls 149.4, Rietpoort
                                  <a href="/assets/townplanning/applications/2023-24/Pls 149.4, Rietpoort.pdf" mz-secondary-content download>
                                     {{Pls149_4UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 1128, Vredendal
                                  <a href="/assets/townplanning/applications/2023-24/Erf 1128, Vredendal.pdf" mz-secondary-content download>
                                     {{Erf1128UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 550, Vredendal
                                  <a href="/assets/townplanning/applications/2023-24/Erf 550, Vredendal.pdf" mz-secondary-content download>
                                     {{Erf550UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 3234 & 4475 Vredendal
                                  <a href="/assets/townplanning/applications/2023-24/Erf 3234_4475 Vredendal.pdf" mz-secondary-content download>
                                     {{Erf32344475UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 933 Vredendal
                                  <a href="/assets/townplanning/applications/2023-24/Erf 933 Vredendal.pdf" mz-secondary-content download>
                                     {{Erf933UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Pls 384.298, Melkboom
                                  <a href="/assets/townplanning/applications/2023-24/Pls 384.298, Melkboom.pdf" mz-secondary-content download>
                                     {{Pls384298UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 334, Louis Rood
                                  <a href="/assets/townplanning/applications/2023-24/Erf 334, Louis Rood.pdf" mz-secondary-content download>
                                     {{Erf334LouisRood   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 230, Louis Rood
                                  <a href="/assets/townplanning/applications/2023-24/Erf 230, Louis Rood.pdf" mz-secondary-content download>
                                     {{Erf230LouisRood   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Gedeelte 426 van die Plaas Vredendal No. 292
                                  <a href="/assets/townplanning/applications/2023-24/Pl 292.426 Mall.pdf" mz-secondary-content download>
                                     {{Pls292426UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Gedeelte 334 van die Plaas Vredendal No. 292
                                  <a href="/assets/townplanning/applications/2023-24/Pls 292.334.pdf" mz-secondary-content download>
                                     {{Erf334292UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erwe 113, 116, 118, 142, 233, 261, 320_327- Bitterfontein
                                  <a href="/assets/townplanning/applications/2023-24/Erwe 113, 116, 118, 142, 233, 261, 320_327- Bitterfontein.pdf" mz-secondary-content download>
                                     {{WcdmBitterUploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Gedeelte 12 van die Plaas Stresa No. 295
                                  <a href="/assets/townplanning/applications/2023-24/Pls 295.12,Vredendal.pdf" mz-secondary-content download>
                                     {{Pls29512UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download right" download>cloud_download</i>
                                  </a>
                            </mz-collection-item>
                            <mz-collection-item>
                            Restant van Gedeelte 237 van die Plaas Vredendal No. 292
                              <a href="/assets/townplanning/applications/2023-24/Pls 292-237,Vredendal.pdf" mz-secondary-content download>
                                 {{Pls292237UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                  <i class=" material-icons download right" download>cloud_download</i>
                              </a>
                          </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
    <div class="row">
      <div class="cols12 m12 l12">
          <mz-collapsible [mode]="'expandable'" [popout]="true">
              <mz-collapsible-item [active]="true">
                  <mz-collapsible-item-header>
                      <i class="material-icons">keyboard_arrow_down</i>
                      Townplanning Applications 2022-23
                  </mz-collapsible-item-header>     
                  <mz-collapsible-item-body>
                      <mz-collection>
                        <mz-collection-item>
                            Gedeelte 177 van die Plaas Vredendal No. 292
                            <a href="/assets/townplanning/applications/2022-23/Pls 292-177, Vredendal.pdf" mz-secondary-content download>
                               {{Erf177UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Erf 77, Lutzville 
                            <a href="/assets/townplanning/applications/2022-23/Erf 77, Lutzville.pdf" mz-secondary-content download>
                               {{Erf77UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Erf 4944, Vredendal 
                            <a href="/assets/townplanning/applications/2022-23/Erf 4944, Vredendal.pdf" mz-secondary-content download>
                               {{Erf4944UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Erf 3976, Vredendal
                            <a href="/assets/townplanning/applications/2022-23/Erf 3976, Vredendal.pdf" mz-secondary-content download>
                               {{Erf3976UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Erwe 4207 & 4210, Vredendal
                            <a href="/assets/townplanning/applications/2022-23/Erwe 4207_4210, Vredendal.pdf" mz-secondary-content download>
                               {{Erf4207UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Erf 696, Louis Rood
                            <a href="/assets/townplanning/applications/2022-23/Erf 696, Louis Rood.pdf" mz-secondary-content download>
                               {{Erf696UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Pls 292 - 344, Vredendal
                            <a href="/assets/townplanning/applications/2022-23/Pls 292 - 344, Vredendal.pdf" mz-secondary-content download>
                               {{Pls292UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Erf 698, VANRHYNSDORP
                            <a href="/assets/townplanning/applications/2022-23/Erf 698, VANRHYNSDORP.pdf" mz-secondary-content download>
                               {{Erf698UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Erf 3292, Vredendal
                            <a href="/assets/townplanning/applications/2022-23/Erf 3292, Vredendal.pdf" mz-secondary-content download>
                               {{Erf3292UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            RESTANT VAN ERF 31, VREDENDAL
                            <a href="/assets/townplanning/applications/2022-23/RESTANT VAN ERF 31, VREDENDAL.pdf" mz-secondary-content download>
                               {{RestantErf31UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Erf 477, Vredendal
                            <a href="/assets/townplanning/applications/2022-23/ERF 477, VREDENDAL.pdf" mz-secondary-content download>
                               {{Erf477UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                        </mz-collection-item>
                        <mz-collection-item>
                            Erf 656, Vredendal
                            <a href="/assets/townplanning/applications/2022-23/ERF 656, VREDENDAL.pdf" mz-secondary-content download>
                               {{Erf656UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                          </mz-collection-item>
                        <mz-collection-item>
                            Gedeelte 55 van die Plaas Hollebakstrandfontein No. 270
                            <a href="/assets/townplanning/applications/2022-23/Farm 270 Ptn 55 Hollebakstrandfontein.pdf" mz-secondary-content download>
                               {{Farm270UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                          </mz-collection-item>
                        <mz-collection-item>
                          Erf 1229, Klawer
                          <a href="/assets/townplanning/applications/2022-23/ERF 1229, KLAWER.pdf" mz-secondary-content download>
                             {{Erf1229KlawerUploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                              <i class=" material-icons download right" download>cloud_download</i>
                          </a>
                        </mz-collection-item>
                        <mz-collection-item>
                          Erf 1577, Vredendal
                          <a href="/assets/townplanning/applications/2022-23/Erf 1577, Vredendal.pdf" mz-secondary-content download>
                             {{Erf1577VredendalUploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                              <i class=" material-icons download right" download>cloud_download</i>
                          </a>
                        </mz-collection-item>
                        <mz-collection-item>
                          Various Applications: Mining prospecting
                          <a href="/assets/townplanning/applications/2022-23/RIO TINTO PROSPECTING.pdf" mz-secondary-content download>
                             {{RioTintoProspectingUploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                              <i class=" material-icons download right" download>cloud_download</i>
                          </a>
                        </mz-collection-item>
                        <mz-collection-item>
                          Perseel 47, Olifantsrivier Nedersetting
                          <a href="/assets/townplanning/applications/2022-23/Plot 47 ORN.pdf" mz-secondary-content download>
                             {{Plot47ORNUploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                              <i class=" material-icons download right" download>cloud_download</i>
                          </a>
                        </mz-collection-item>
                        <mz-collection-item>
                          Erf 770, Vredendal
                          <a href="/assets/townplanning/applications/2022-23/Erf 770, Vredendal.pdf" mz-secondary-content download>
                             {{Erf770VredendalUploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                              <i class=" material-icons download right" download>cloud_download</i>
                          </a>
                        </mz-collection-item>
                        <mz-collection-item>
                          Erf 387, Louis Rood
                          <a href="/assets/townplanning/applications/2022-23/Erf 387, Louis Rood.pdf" mz-secondary-content download>
                             {{Erf387LouisRoodUploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                              <i class=" material-icons download right" download>cloud_download</i>
                          </a>
                        </mz-collection-item>
                        <mz-collection-item>
                          Erf 475, Louis Rood
                          <a href="/assets/townplanning/applications/2022-23/Erf 475, Louis Rood.pdf" mz-secondary-content download>
                             {{Erf475LouisRoodUploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                              <i class=" material-icons download right" download>cloud_download</i>
                          </a>
                        </mz-collection-item>
                        <mz-collection-item>
                          Erf 66, Lutzville
                          <a href="/assets/townplanning/applications/2021-22/ERF 66, LUTZVILLLE.pdf" mz-secondary-content download>
                             {{Erf66UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                              <i class=" material-icons download right" download>cloud_download</i>
                          </a>
                        </mz-collection-item>
                        <mz-collection-item>
                          Erf 160, Vanrhynsdorp
                          <a href="/assets/townplanning/applications/2021-22/Erf 160, Vdorp.pdf" mz-secondary-content download>
                             {{Erf160UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                              <i class=" material-icons download right" download>cloud_download</i>
                          </a>
                        </mz-collection-item>
                        <mz-collection-item>
                          Erf 535, Vredendal
                          <a href="/assets/townplanning/applications/2021-22/ERF 535, VREDENDAL - Newspaper.pdf" mz-secondary-content download>
                             {{Erf535UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                              <i class=" material-icons download right" download>cloud_download</i>
                          </a>
                        </mz-collection-item>
                      </mz-collection>
                  </mz-collapsible-item-body>
              </mz-collapsible-item>
          </mz-collapsible>
      </div>
  </div>
    <div class="row">
        <div class="cols12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                        Townplanning Applications 2021-22
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                          <mz-collection-item>
                            Erf 475, Louis Rood
                            <a href="/assets/townplanning/applications/2022-23/Erf 475, Louis Rood.pdf" mz-secondary-content download>
                               {{Erf475LouisRoodUploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                          </mz-collection-item>
                          <mz-collection-item>
                            Erf 66, Lutzville
                            <a href="/assets/townplanning/applications/2021-22/ERF 66, LUTZVILLLE.pdf" mz-secondary-content download>
                               {{Erf66UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                          </mz-collection-item>
                          <mz-collection-item>
                            Erf 160, Vanrhynsdorp
                            <a href="/assets/townplanning/applications/2021-22/Erf 160, Vdorp.pdf" mz-secondary-content download>
                               {{Erf160UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                          </mz-collection-item>
                          <mz-collection-item>
                            Erf 535, Vredendal
                            <a href="/assets/townplanning/applications/2021-22/ERF 535, VREDENDAL - Newspaper.pdf" mz-secondary-content download>
                               {{Erf535UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                          </mz-collection-item>
                          <mz-collection-item>
                            Erf 52, Vanrhynsdorp
                            <a href="/assets/townplanning/applications/2021-22/Erf 52 Vanrhynsdorp - Kennisgewing.pdf" mz-secondary-content download>
                               {{Erf52UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                          </mz-collection-item>
                          <mz-collection-item>
                            Gedeelte 99 van die Plaas Birdfield No 306
                            <a href="/assets/townplanning/applications/2021-22/Pl 306-99 30 dae kennisgewings.pdf" mz-secondary-content download>
                               {{Pl30699UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                          </mz-collection-item>
                          <mz-collection-item>
                            Restant van Gedeelte 53 van die Plaas Bitterfontein No. 47
                            <a href="/assets/townplanning/applications/2021-22/Re Ptn 53 of 47.pdf" mz-secondary-content download>
                               {{Ptn530f47UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                          </mz-collection-item>
                          <mz-collection-item>
                            Restant van Erf 3040, Vredendal
                            <a href="/assets/townplanning/applications/2021-22/ERF 3040 2022 POPIA.pdf" mz-secondary-content download>
                               {{Erf3040UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                          </mz-collection-item>
                          <mz-collection-item>
                            Erf 702, Louis Rood
                            <a href="/assets/townplanning/applications/2021-22/Erf 702, Louis Rood.pdf" mz-secondary-content download>
                               {{Erf702LRUploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                          </mz-collection-item>
                          <mz-collection-item>
                            Erf 31, Lutzville
                            <a href="/assets/townplanning/applications/2021-22/Erf 31 Lville.pdf" mz-secondary-content download>
                               {{Erf31LvilleUploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                          </mz-collection-item>
                            <mz-collection-item>
                                Erf 1071, Vredendal
                                <a href="/assets/townplanning/applications/2021-22/Erf 1071 Vdal.pdf" mz-secondary-content download>
                                   {{Erf1071VdalUploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                          <mz-collection-item>
                            Erf 230, Louis Rood
                            <a href="/assets/townplanning/applications/2021-22/Erf 230, Louis Rood.pdf" mz-secondary-content download>
                               {{Erf230UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download right" download>cloud_download</i>
                            </a>
                          </mz-collection-item>
                            <mz-collection-item>
                                Erf 789, Ebenhaeser
                                <a href="/assets/townplanning/applications/2021-22/Erf 789 Ebenheaser.pdf" mz-secondary-content download>
                                   {{Erf789UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                HC Steenkamp,WA van Niekerk Trust,Nuwedam Boerdery CC,Rietpoort Gemeenskap
                                <a href="/assets/townplanning/applications/2021-22/Dept Public Works.pdf" mz-secondary-content download>
                                   {{DeptPublicWorksUploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 132 Vredendal
                                <a href="/assets/townplanning/applications/2021-22/Erf 132 Vredendal.pdf" mz-secondary-content download>
                                   {{Erf132UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 333, Louis Rood
                                <a href="/assets/townplanning/applications/2021-22/Erf 333, Louis Rood.pdf" mz-secondary-content download>
                                   {{Erf333UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 536, Olifantsriviernedersetting
                                <a href="/assets/townplanning/applications/2021-22/Erf 536 ORN.pdf" mz-secondary-content download>
                                   {{Erf536UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
    <div class="row">
        <div class="cols12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                        Townplanning Applications 2020-21
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item>
                                Erf 2706, Vredendal
                                <a href="/assets/townplanning/applications/2020-21/Erf 2706, Vredendal.pdf" mz-secondary-content download>
                                   {{Erf2706UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 46, Klawer
                                <a href="/assets/townplanning/applications/2020-21/Erf 46, Klawer.pdf" mz-secondary-content download>
                                   {{Erf46UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 1539, Vredendal
                                <a href="/assets/townplanning/applications/2020-21/Erf 1539, Vredendal.pdf" mz-secondary-content download>
                                   {{Erf1539UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 719, Louis Rood
                                <a href="/assets/townplanning/applications/2020-21/Erf 719, Louis Rood.pdf" mz-secondary-content download>
                                   {{Erf719UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Lot 564, Olifantsriver Settlement
                                <a href="/assets/townplanning/applications/2020-21/Perseel 564, ORN.pdf" mz-secondary-content download>
                                   {{lot564OrnUploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 49, Vanrhynsdorp
                                <a href="/assets/townplanning/applications/2020-21/Erf 49, Vanrhynsdorp.pdf" mz-secondary-content download>
                                   {{Erf49UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erven 4217, 4218 en 4219, Vredendal
                                <a href="/assets/townplanning/applications/Erwe 4217-4219, Vredendal.pdf" mz-secondary-content download>
                                   {{Erf4217UploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                MS Thiwani & N Thiwani, Erf 4748,Vredendal
                                <a href="/assets/townplanning/applications/Erf 4748, Vredendal.pdf" mz-secondary-content download>
                                   {{BubeziUploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                BUBESI INV 208 PTY LTD
                                <a href="/assets/townplanning/applications/BUBESI INV 208 PTY LTD.pdf" mz-secondary-content download>
                                   {{BubeziUploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Izala Farms (Pty) Ltd
                                <a href="/assets/townplanning/applications/Izala Farms (Pty) Ltd.pdf" mz-secondary-content download>
                                   {{IzalaFarmsUploadDate   |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Pls 198-292 Vredendal
                                <a href="/assets/townplanning/applications/Pls 198-292 Vredendal.pdf" mz-secondary-content download>
                                   {{Pls198_292UploadDate  |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                Erf 349 Vredendal
                                <a href="/assets/townplanning/applications/Erf 349 Vredendal.pdf" mz-secondary-content download>
                                   {{erf349UploadDate |date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                        Townplanning Applications 2019-20
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item>
                                Erf 1299 & 1300, VREDENDAL
                                <a href="/assets/townplanning/applications/Erf 1299 Vredendal.pdf" mz-secondary-content download>
                                   {{erf1299UploadDate |date : 'MMM, dd yyyy, HH:mm:ss'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                APPLICATION FOR DEPARTURE:Erf 1320, LUTZVILLE
                                <a href="/assets/townplanning/applications/Erf 1320 Lutzville.pdf" mz-secondary-content download>
                                   {{erf1320UploadDate |date : 'MMM, dd yyyy, HH:mm:ss'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                               Erf 267, VREDENDAL
                                <a href="/assets/townplanning/applications/erf267.pdf" mz-secondary-content download>
                                   {{erf267UploadDate |date : 'MMM, dd yyyy, HH:mm:ss'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                KENNISGEWING: AANSOEK OM AFWYKING & OPRIGTING VAN STRUKTUUR
                                <a href="/assets/townplanning/notices/Mat_Mun_K24.pdf" mz-secondary-content download>
                                   {{k24UploadDate |date : 'MMM, dd yyyy, HH:mm:ss'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                APPLICATION FOR AN AMENDMENT OF A RESTRICTIVE TITLE CONDITION:Erf 1159, Vanrhynsdorp
                                <a href="/assets/townplanning/applications/erf1159.pdf" mz-secondary-content download>
                                   {{erf1159UploadDate |date : 'MMM, dd yyyy, HH:mm:ss'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                APPLICATION FOR DEPARTURE:Mr MS Mranqu
                                <a href="/assets/townplanning/applications/erf2519.pdf" mz-secondary-content download>
                                   {{erf2519UploadDate |date : 'MMM, dd yyyy, HH:mm:ss'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                APPLICATION FOR DEPARTURE, CONSOLIDATION, CONSENT USE & AMENDMENT OF RESTRICTIVE CONDITIONS
                                <a href="/assets/townplanning/applications/bluedot.pdf" mz-secondary-content download>
                                   {{bluedotUploadDate |date : 'MMM, dd yyyy, HH:mm:ss'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                APPLICATION FOR REZONING OF PORTION 2 OF THE FARM VADERLANDSCHE RIETKUIL NO 308
                                <a href="/assets/townplanning/applications/rietkuil.pdf" mz-secondary-content download>
                                   {{rietkuilUploadDate |date : 'MMM, dd yyyy, HH:mm:ss'}}
                                    <i class=" material-icons download right" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
</div>
