import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-valuation',
  templateUrl: './valuation.component.html',
  styleUrls: ['./valuation.component.css']
})
export class ValuationComponent implements OnInit {
  
  valuationExtension:Date = new Date('2020-05-10 15:16');
  suppValJan21:Date = new Date('2021-01-26 11:21');
  suppVal2Sept21:Date = new Date('2021-09-09 11:43');
  suppVal3Feb23:Date = new Date('2023-02-06 08:06');
  suppVal4Nov23:Date = new Date('2023-11-01 21:00');
  genValFeb2025:Date = new Date('2025-02-05 21:30');
  beswaarFeb2025:Date = new Date('2025-02-07 11:05');






  constructor() {

  
   }

  

  ngOnInit() {
  }
  

}
