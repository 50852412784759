import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-finpol',
  templateUrl: './finpol.component.html',
  styleUrls: ['./finpol.component.css']
})
export class FinpolComponent implements OnInit {

  draftBudgetPoliciesUploadDate = new Date('2020-03-27 20:36:40');
  finalBudgetPoliciesUploadDate = new Date('2020-06-03 11:05:10');
  draftBudgetPolicies2022UploadDate = new Date('2021-03-31 09:08');
  finalBudgetPolicies2022UploadDate = new Date('2021-05-31 09:29');
  finalBudgetPolicies2023UploadDate = new Date('2022-06-01 14:16');
  finalBudgetPolicies2024UploadDate = new Date('2023-09-21 15:21');
  finalBudgetPolicies2025UploadDate = new Date('2024-05-21 20:22');
  communicationsPolicyUploadDate = new Date('2025-03-14 11:04');


  constructor() { }

  ngOnInit() {
  }

}
