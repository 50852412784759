<div class="container">
    <h4 class="center">Council Documents</h4>
    <div class="row">
      <div class="col s12 m12 l12">
        <mz-collapsible [mode]="'expandable'" [popout]="true">
          <mz-collapsible-item [active]="true">
            <mz-collapsible-item-header>
              <i class="material-icons">keyboard_arrow_down</i>
              Council Meetings Minutes 2024-25
            </mz-collapsible-item-header>
            <mz-collapsible-item-body>
              <mz-collection>
                <mz-collection-item>
                  NOTULE RAAD 29 OKT 2024
                  <a href="/assets/Council Minutes/2024-25/NOTULE RAAD 29 OKT 2024.pdf" mz-secondary-content download>
                    {{Oct202429 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
                </mz-collection-item>
                <mz-collection-item>
                  NOTULE RAAD 21 October 2024
                  <a href="/assets/Council Minutes/2024-25/NOTULE RAAD 21 OCT 2024 ENG.pdf" mz-secondary-content download>
                    {{Oct202421 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
                </mz-collection-item>
                <mz-collection-item>
                  NOTULE RAAD 10 SEPTEMBER 2024
                  <a href="/assets/Council Minutes/2024-25/NOTULE RAAD 10 SEPT 2024.pdf" mz-secondary-content download>
                    {{Sept202410 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
                </mz-collection-item>
                <mz-collection-item>
                  NOTULE RAAD 27 August 2024
                  <a href="/assets/Council Minutes/2024-25/NOTULE RAAD 27 AUG 2024 AFR.pdf" mz-secondary-content download>
                    {{Aug202427 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
                </mz-collection-item>
                <mz-collection-item>
                  NOTULE RAAD 30 JULIE 2024
                  <a href="/assets/Council Minutes/2024-25/NOTULE RAAD 30 JULIE 2024 AFR.pdf" mz-secondary-content download>
                    {{July202427 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
              </mz-collection>
            </mz-collapsible-item-body>         
          </mz-collapsible-item>
        </mz-collapsible>
      </div>
    </div>
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                        Council Meetings Minutes 2023-24
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                          <mz-collection-item>
                            NOTULE RAAD 27 JUNIE 2024
                            <a href="/assets/Council Minutes/2023-24/NOTULE RAAD 27 JUNIE 2024.pdf" mz-secondary-content download>
                              {{June202427 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                              <i class=" material-icons download rigth" download>cloud_download</i>
                          </a>
                        </mz-collection-item>
                            <mz-collection-item>
                                NOTULE RAAD 21 MEI 2024
                                <a href="/assets/Council Minutes/2023-24/NOTULE RAAD 21 MEI 2024.pdf" mz-secondary-content download>
                                  {{May2024 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                NOTULE RAAD 30 APRIL 2024
                                <a href="/assets/Council Minutes/2023-24/NOTULE RAAD 30 APRIL 2024 AFR.pdf" mz-secondary-content download>
                                  {{April2024 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                NOTULE RAAD 27 MAART 2024
                                <a href="/assets/Council Minutes/2023-24/NOTULE RAAD 27 MAART 2024.pdf" mz-secondary-content download>
                                  {{March2024 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                NOTULE RAAD 26 FEB 2024 AFR
                                <a href="/assets/Council Minutes/2023-24/NOTULE RAAD 26 FEB 2024 AFR.pdf" mz-secondary-content download>
                                  {{Feb2024 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                NOTULE RAAD 30 JAN 2024
                                <a href="/assets/Council Minutes/2023-24/NOTULE RAAD 30 JAN 2024 ENG.pdf" mz-secondary-content download>
                                  {{Jan2024 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                NOTULE RAAD 01 DEC 2023 AFR
                                <a href="/assets/Council Minutes/2023-24/NOTULE RAAD 1 DES 2023.pdf" mz-secondary-content download>
                                  {{Dec2023 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                NOTULE RAAD 31 OKT 2023 AFR
                                <a href="/assets/Council Minutes/2023-24/NOTULE RAAD 31 OKT 2023 AFR.pdf" mz-secondary-content download>
                                  {{Oct2023 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                NOTULE RAAD 29 AUG 2023 AFR
                                <a href="/assets/Council Minutes/2023-24/NOTULE RAAD 29 AUG 2023.pdf" mz-secondary-content download>
                                  {{August2023 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                NOTULE RAAD 25 JULIE 2023 AFR
                                <a href="/assets/Council Minutes/2023-24/NOTULE RAAD 25 JULIE 2023 AFR.pdf" mz-secondary-content download>
                                  {{july252324 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                NOTULE RAAD 26 JULIE 2023 AFR
                                <a href="/assets/Council Minutes/2023-24/NOTULE RAAD 26 JULIE 2023 AFR.pdf" mz-secondary-content download>
                                  {{july262324 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                            </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>

    </div>
    <div class="row">
      <div class="col s12 m12 l12">
        <mz-collapsible [mode]="'expandable'" [popout]="true">
          <mz-collapsible-item [active]="true">
            <mz-collapsible-item-header>
              <i class="material-icons">keyboard_arrow_down</i>
                Council Meetings Minutes 2022-23
            </mz-collapsible-item-header>
            <mz-collapsible-item-body>
              <mz-collection>
                <mz-collection-item>
                    Upload Date: <span class="right">{{ CouncilMinutes2223 | date: 'MMM, dd yyyy, HH:mm:aaa'}}  </span>
                </mz-collection-item>
                <mz-collection-item>
                    Meeting 29 Junie 2023 AFR
                    <a href="/assets/Council Minutes/2022-23/NOTULE RAAD 29 JUNIE  2023 AFR.pdf" mz-secondary-content download>
                      <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                </mz-collection-item>
                <mz-collection-item>
                    Meeting 31 May 2023 AFR
                    <a href="/assets/Council Minutes/2022-23/NOTULE RAAD 31 MEI 2023 AFR.pdf" mz-secondary-content download>
                      <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                </mz-collection-item>
                <mz-collection-item>
                    Meeting 25 May 2023 AFR
                    <a href="/assets/Council Minutes/2022-23/NOTULE RAAD 25 MEI 2023 AFR.pdf" mz-secondary-content download>
                      <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                </mz-collection-item>
                <mz-collection-item>
                    Meeting 05 May 2023 AFR
                    <a href="/assets/Council Minutes/2022-23/NOTULE RAAD 5 MEI  2023 AFR.pdf" mz-secondary-content download>
                      <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                </mz-collection-item>
                <mz-collection-item>
                    Meeting 21 April 2023 AFR
                    <a href="/assets/Council Minutes/2022-23/NOTULE RAAD 21 APRIL 2023 AFR.pdf" mz-secondary-content download>
                      <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                </mz-collection-item>
                <mz-collection-item>
                    Meeting 29 March 2023 AFR
                    <a href="/assets/Council Minutes/2022-23/NOTULE RAAD 29 MAART 2023 ENG.pdf" mz-secondary-content download>
                      <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                </mz-collection-item>
                <mz-collection-item>
                    Meeting 27 February 2023 AFR
                    <a href="/assets/Council Minutes/2022-23/NOTULE RAAD 27 FEB 2023 AFR.pdf" mz-secondary-content download>
                      <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                </mz-collection-item>
                <mz-collection-item>
                    Meeting 31 January 2023 AFR
                    <a href="/assets/Council Minutes/2022-23/NOTULE RAAD 31 JAN 2023 AFR.pdf" mz-secondary-content download>
                      <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                </mz-collection-item>
                <mz-collection-item>
                    Meeting 06 December 2022 AFR
                    <a href="/assets/Council Minutes/2022-23/NOTULE RAAD 6 DES 2022 AFR.pdf" mz-secondary-content download>
                      <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                </mz-collection-item>
                <mz-collection-item>
                    Meeting 29 August 2022 ENG
                    <a href="/assets/Council Minutes/2022-23/NOTULE RAAD 29 AUG 2022 AFR.pdf" mz-secondary-content download>
                    {{August2022 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                        <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                </mz-collection-item>
                <mz-collection-item>
                  Meeting 26 July 2022 ENG
                  <a href="/assets/Council Minutes/2022-23/NOTULE RAAD 26 JULY 2022 ENG.pdf" mz-secondary-content download>
                    {{July2022 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
                </mz-collection-item>
              </mz-collection>
            </mz-collapsible-item-body>
          </mz-collapsible-item>
        </mz-collapsible>
      </div>
    </div>
    <div class="row">
      <div class="col s12 m12 l12">
        <mz-collapsible [mode]="'expandable'" [popout]="true">
          <mz-collapsible-item [active]="true">
            <mz-collapsible-item-header>
              <i class="material-icons">keyboard_arrow_down</i>
                Council Meetings Minutes 2021-22
            </mz-collapsible-item-header>
            <mz-collapsible-item-body>
              <mz-collection>
                <mz-collection-item>
                  Meeting 30 June 2022 ENG
                  <a href="/assets/Council Minutes/2021-22/NOTULE RAAD 30 JUNE 2022 ENG.pdf" mz-secondary-content download>
                    {{June2022 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
                </mz-collection-item>
                <mz-collection-item>
                  Meeting 31 MEI 2022 ENG
                  <a href="/assets/Council Minutes/2021-22/NOTULE RAAD 31 MEI 2022 ENG.pdf" mz-secondary-content download>
                    {{May2022 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
                </mz-collection-item>
                <mz-collection-item>
                  Meeting 28 APRIL 2022 AFR
                  <a href="/assets/Council Minutes/2021-22/NOTULE RAAD 28 APRIL 2022 AFR.pdf" mz-secondary-content download>
                    {{April2022 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
                </mz-collection-item>
                <mz-collection-item>
                  Meeting 29 MARCH 2022 ENG
                  <a href="/assets/Council Minutes/2021-22/NOTULE RAAD 29 MAART 2022 ENG.pdf" mz-secondary-content download>
                    {{March2022 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
                </mz-collection-item>
                <mz-collection-item>
                  Meeting 28 FEB 2022 AFR
                  <a href="/assets/Council Minutes/2021-22/NOTULE RAAD 28 FEB 2022 AFR.pdf" mz-secondary-content download>
                    {{February2022 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
                </mz-collection-item>
                <mz-collection-item>
                  Meeting 25 JAN 2022 AFR
                  <a href="/assets/Council Minutes/2021-22/NOTULE RAAD 25 JAN 2022 AFR.pdf" mz-secondary-content download>
                    {{January2022 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
                </mz-collection-item>
                <mz-collection-item>
                  Meeting 7 DES 2021 AFR
                  <a href="/assets/Council Minutes/2021-22/NOTULE RAAD 7 DES 2021 AFR.pdf" mz-secondary-content download>
                    {{December2022 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
                </mz-collection-item>
                <mz-collection-item>
                  Meeting 18 NOV 2021 AFR
                  <a href="/assets/Council Minutes/2021-22/NOTULE RAAD 18 NOV 2021 AFR.pdf" mz-secondary-content download>
                    {{December2022 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
                </mz-collection-item>
                <mz-collection-item>
                  Meeting 15 NOV 2021 AFR
                  <a href="/assets/Council Minutes/2021-22/NOTULE RAAD 15 NOV 2021 AFR.pdf" mz-secondary-content download>
                    {{December2022 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
                </mz-collection-item>
                <mz-collection-item>
                  Meeting 18 OKT 2021 AFR
                  <a href="/assets/Council Minutes/2021-22/NOTULE RAAD 18 OKT 2021 AFR.pdf" mz-secondary-content download>
                    {{December2022 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
                </mz-collection-item>
                <mz-collection-item>
                    Meeting 18 OKT 2021 ENG
                    <a href="/assets/Council Minutes/2021-22/NOTULE RAAD 18 OCT 2021 ENG.pdf" mz-secondary-content download>
                      {{December2022 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                      <i class=" material-icons download rigth" download>cloud_download</i>
                  </a>
                  </mz-collection-item>
                <mz-collection-item>
                  Meeting 26 OKT 2021 AFR
                  <a href="/assets/Council Minutes/2021-22/NOTULE RAAD 26 OKT 2021 AFR.pdf" mz-secondary-content download>
                    {{December2022 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
                </mz-collection-item>
                <mz-collection-item>
                    Meeting 26 OKT 2021 ENG
                    <a href="/assets/Council Minutes/2021-22/NOTULE RAAD 26 OCT 2021 ENG.pdf" mz-secondary-content download>
                      {{December2022 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                      <i class=" material-icons download rigth" download>cloud_download</i>
                  </a>
                  </mz-collection-item>
              </mz-collection>
            </mz-collapsible-item-body>
          </mz-collapsible-item>
        </mz-collapsible>
      </div>
    </div>
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible
                    [mode]="'expandable'"
                    [popout]="true">
                    <mz-collapsible-item [active]="true">
                        <mz-collapsible-item-header>
                                     <i class="material-icons">keyboard_arrow_down</i>
                                        Council Meeting 28 July 2020

                        </mz-collapsible-item-header>
                            <mz-collapsible-item-body>
                                <mz-collection>
                                    <mz-collection-item class="uploadDate">
                                        Upload Date: <span class="right">{{ CouncilMeeting28072020 | date: 'MMM, dd yyyy, HH:mm:aaa'}}  </span>
                                    </mz-collection-item>
                                    <mz-collection-item >
                                            Agenda Council Meeting 28 July 2020(English)
                                            <a href="/assets/Council Meeting/20200727/AGENDA RAAD 28 JULY 2020 ENG.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                    </mz-collection-item>
                                     <mz-collection-item >
                                        Agenda Council Meeting 28 July 2020(Afrikaans)
                                            <a href="/assets/Council Meeting/20200727/AGENDA RAAD 28 JULIE 2020 AFR.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                    </mz-collection-item>
                                </mz-collection>
                            </mz-collapsible-item-body>
                    </mz-collapsible-item>
                    <mz-collapsible-item [active]="true">
                        <mz-collapsible-item-header>
                                     <i class="material-icons">keyboard_arrow_down</i>
                                        Mayoral Committee Meeting : 25 May 2020
                        </mz-collapsible-item-header>
                        <mz-collapsible-item-body>
                            <mz-collection>
                                <mz-collection-item class="uploadDate">
                                   Upload Date: <span class="right">{{ MayoralComm25May2020 | date: 'MMM, dd yyyy, HH:mm:aaa'}}  </span>
                                </mz-collection-item>
                                <mz-collection-item >
                                        AGENDA 25 Mei 2020 AFRIKAANS
                                    <a href="/assets/Council Meeting/20200525/BK AGENDA 25 MEI 2020.pdf" mz-secondary-content download>
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                                </mz-collection-item>
                                <mz-collection-item >
                                         AGENDA 25 May 2020 ENGLISH
                                    <a href="/assets/Council Meeting/20200525/MAYCO AGENDA 25 MAY 2020.pdf" mz-secondary-content download>
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                                </mz-collection-item>
                                <mz-collection-item >
                                        NOTULE 13 Februarie 2020 AFRIKAANS
                                    <a href="/assets/Council Meeting/20200525/BK NOTULE 13 FEB 2020.pdf" mz-secondary-content download>
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                                </mz-collection-item>
                                <mz-collection-item >
                                        MINUTES 13 February 2020 ENGLISH
                                    <a href="/assets/Council Meeting/20200525/MAYCO MINUTES 13 FEB 2020.pdf" mz-secondary-content download>
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                                </mz-collection-item>
                                <mz-collection-item >
                                        MAYCO MEETING ANNEXURES
                                    <a href="/assets/Council Meeting/20200525/BK BYLAE 25 MEI 2020 - MAYCO ANNEXURE 25 MAY 2020.pdf" mz-secondary-content download>
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                                </mz-collection-item>
                            </mz-collection>
                        </mz-collapsible-item-body>
                    </mz-collapsible-item>
                    <mz-collapsible-item [active]="true">
                        <mz-collapsible-item-header>
                                     <i class="material-icons">keyboard_arrow_down</i>
                                       Council Meeting : 28 February 2020

                        </mz-collapsible-item-header>
                        <mz-collapsible-item-body>
                            <mz-collection>
                                <mz-collection-item >
                                        AGENDA RAAD 28 FEBRUARIE 2020 AFRIKAANS
                                    <a href="/assets/Council Meeting/20200228/AGENDA RAAD 28 FEB 2020 AFR.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                </mz-collection-item>
                                <mz-collection-item >
                                        AGENDA RAAD 28 FEBRUARIE 2020 ENGELS
                                    <a href="/assets/Council Meeting/20200228/AGENDA RAAD 28 FEB 2020 ENG.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                </mz-collection-item>
                                <mz-collection-item >
                                        BYLAE A BL 1 TOT 50
                                    <a href="/assets/Council Meeting/20200228/BYLAE A BL 1 TOT 50.pdf"mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                </mz-collection-item>
                                <mz-collection-item >
                                        BYLAE A BL 51 TOT 101
                                    <a href="/assets/Council Meeting/20200228/BYLAE A BL 51 TOT 101.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                </mz-collection-item>
                                <mz-collection-item >
                                         BYLAE A BL 102 TOT 124
                                    <a href="/assets/Council Meeting/20200228/BYLAE A BL 102 TOT 124.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                </mz-collection-item>
                                <mz-collection-item >
                                        BYLAE B BL 125 TOT 126
                                    <a href="/assets/Council Meeting/20200228/BYLAE B BL 125 TOT 126.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                </mz-collection-item>
                                <mz-collection-item >
                                        BYLAE C EN D BL 127 TOT 129
                                    <a href="/assets/Council Meeting/20200228/BYLAE C EN D BL 127 TOT 129.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                </mz-collection-item>
                                <mz-collection-item >
                                        NOTULE RAAD 4 FEBRUARIE 2020 AFRIKAANS
                                    <a href="/assets/Council Meeting/20200228/NOTULE RAAD 4 FEB 2020 AFR.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                </mz-collection-item>
                                <mz-collection-item >
                                        NOTULE RAAD 4 FEB 2020 ENGELS
                                    <a href="/assets/Council Meeting/20200228/NOTULE RAAD 4 FEB 2020 ENG.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                </mz-collection-item>
                                </mz-collection>
                        </mz-collapsible-item-body>
                    </mz-collapsible-item>
                    <mz-collapsible-item [active]="true">
                        <mz-collapsible-item-header>
                            <i class="material-icons">keyboard_arrow_down</i>
                            2020-21 Council Minutes
                        </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item >
                                Council Minutes October 2020 - June 2021
                                <a href="/assets/Council Minutes/2021/2020-21.zip" mz-secondary-content download>
                                    {{  minutes202021 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item class="uploadDate">
                                Upload Date: <span class="right">{{ CouncilMinutes2021 | date: 'MMM, dd yyyy, HH:mm:aaa'}}  </span>
                            </mz-collection-item>
                            <mz-collection-item >
                                NOTULE RAAD 28 JUL 2020 AFR
                                <a href="/assets/Council Minutes/2021/NOTULE RAAD 28 JUL 2020 AFR.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                            </mz-collection-item>
                            <mz-collection-item >
                                NOTULE RAAD 28 JUL 2020 ENG
                                <a href="/assets/Council Minutes/2021/NOTULE RAAD 28 JUL 2020 ENG.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                            </mz-collection-item>
                            <mz-collection-item >
                                NOTULE RAAD 13 AUG 2020 AFR
                                <a href="/assets/Council Minutes/2021/NOTULE RAAD 13 AUG 2020 AFR.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                            </mz-collection-item>
                            <mz-collection-item >
                                NOTULE RAAD 13 AUG 2020 ENG
                                <a href="/assets/Council Minutes/2021/NOTULE RAAD 13 AUG 2020 ENG.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                            </mz-collection-item>
                            <mz-collection-item >
                                NOTULE RAAD 28 AUG 2020 AFR
                                <a href="/assets/Council Minutes/2021/NOTULE RAAD 28 AUG 2020 AFR.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                            </mz-collection-item>
                            <mz-collection-item >
                                NOTULE RAAD 28 AUG 2020 ENG
                                <a href="/assets/Council Minutes/2021/NOTULE RAAD 28 AUG 2020 ENG.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                            </mz-collection-item>
                            <mz-collection-item >
                                NOTULE RAAD 2 OKT 2020 AFR
                                <a href="/assets/Council Minutes/2021/NOTULE RAAD 2 OKT 2020 AFR.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                            </mz-collection-item>
                            <mz-collection-item >
                                NOTULE RAAD 2 OKT 2020 ENG
                                <a href="/assets/Council Minutes/2021/NOTULE RAAD 2 OKT 2020 ENG.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                            </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                    </mz-collapsible-item>
                    <mz-collapsible-item [active]="true">
                        <mz-collapsible-item-header>
                                     <i class="material-icons">keyboard_arrow_down</i>
                                        2019-20 Council Minutes

                        </mz-collapsible-item-header>
                        <mz-collapsible-item-body>
                                <mz-collection>
                                    <mz-collection-item class="uploadDate">
                                        Upload Date: <span class="right">{{ CouncilMinutes1920 | date: 'MMM, dd yyyy, HH:mm:aaa'}}  </span>
                                    </mz-collection-item>
                                    <mz-collection-item >
                                            Council meeting Minutes 29 June 2020(English)
                                            <a href="/assets/Council Minutes/1920/NOTULE RAAD 29 JUNE 2020 ENG.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                    </mz-collection-item>
                                     <mz-collection-item >
                                            Council meeting Minutes 29 June 2020(Afrikaans)
                                            <a href="/assets/Council Minutes/1920/NOTULE RAAD 29 JUNIE 2020 AFR.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                    </mz-collection-item>
                                    <mz-collection-item >
                                            Council meeting Minutes 19 June 2020(English)
                                            <a href="/assets/Council Minutes/1920/NOTULE RAAD 19 JUNE 2020 ENG.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                    </mz-collection-item>
                                    <mz-collection-item >
                                            Council meeting Minutes 19 June 2020(Afrikaans)
                                             <a href="/assets/Council Minutes/1920/NOTULE RAAD 19 JUN 2020 AFR.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                    </mz-collection-item>
                                    <mz-collection-item >
                                        Council meeting Minutes 28 May 2020(English)
                                             <a href="/assets/Council Minutes/1920/NOTULE RAAD 29 MAY 2020 ENG.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                    </mz-collection-item>
                                    <mz-collection-item >
                                        Council meeting Minutes 28 May 2020(Afrikaans)
                                            <a href="/assets/Council Minutes/1920/NOTULE RAAD 29 MEI 2020 AFR.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                </mz-collection-item>
                                <mz-collection-item >
                                    Council meeting Minutes 27 Oct 2020
                                        <a href="/assets/Council Minutes/1920/NOTULE RAAD 27 OKT 2020 AFR.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                </mz-collection-item>
                                <mz-collection-item >
                                    Council meeting Minutes 15 Dec 2020
                                        <a href="/assets/Council Minutes/1920/NOTULE RAAD 15 DES 2020 AFR.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                </mz-collection-item>
                            </mz-collection>
                            </mz-collapsible-item-body>
                    </mz-collapsible-item> <!--End of 2019-20 minutes collapsible -->
                    <mz-collapsible-item [active]="true">
                        <mz-collapsible-item-header>
                                         <i class="material-icons">keyboard_arrow_down</i>
                                            2018-19 Council Minutes
                        </mz-collapsible-item-header>
                           <mz-collapsible-item-body>
                                <mz-collection>
                                    <mz-collection-item >
                                                NOTULE RAAD 28 MEI 2019
                                                <a href="/assets/Council Minutes/NOTULE RAAD 28 MEI 2019 AFR.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                    </mz-collection-item>
                                    <mz-collection-item >
                                                 NOTULE RAAD 30 APR 2019
                                                <a href="/assets/Council Minutes/NOTULE RAAD 30 APR 2019 AFR.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                    </mz-collection-item>
                                    <mz-collection-item >
                                                NOTULE RAAD 26 MRT 2019
                                                <a href="/assets/Council Minutes/NOTULE RAAD 26 MRT 2019 AFR.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                    </mz-collection-item>
                                    <mz-collection-item >
                                                NOTULE RAAD 26 FEB 2019
                                                <a href="/assets/Council Minutes/NOTULE RAAD 26 FEB 2019 AFR.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                    </mz-collection-item>
                                    <mz-collection-item >
                                            NOTULE RAAD 04 FEB 2019
                                            <a href="/assets/Council Minutes/NOTULE RAAD 4 FEB 2019 AFR.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                    </mz-collection-item>
                                    <mz-collection-item >
                                                NOTULE RAAD 22 JAN 2019
                                                <a href="/assets/Council Minutes/NOTULE RAAD 22 JAN 2019 AFR.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                    </mz-collection-item>
                                    <mz-collection-item >
                                                NOTULE RAAD 4 DES 2018
                                                <a href="/assets/Council Minutes/NOTULE RAAD 4 DES 2018 AFR.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                    </mz-collection-item>
                                    <mz-collection-item >
                                                 NOTULE RAAD 30 OKT 2018
                                                <a href="/assets/Council Minutes/NOTULE RAAD 30 OKT 2018 AFR.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                    </mz-collection-item>
                                    <mz-collection-item >
                                                NOTULE RAAD 6 AUG 2018
                                                <a href="/assets/Council Minutes/NOTULE RAAD 6 AUG 2018 AFR.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                    </mz-collection-item>
                                    <mz-collection-item >
                                                 NOTULE RAAD 25 JULIE 2018
                                                <a href="/assets/Council Minutes/NOTULE RAAD 25 JULIE 2018 AFR.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                                    </mz-collection-item>
                                </mz-collection>
                            </mz-collapsible-item-body>
                        </mz-collapsible-item>  <!--End of 2018-19 minutes collapsible -->
            </mz-collapsible>
        </div>
    </div>
</div>
