import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.css']
})
export class FinanceComponent implements OnInit {

  s71May2020 : Date = new Date ('2020-06-12 12:55');
  s71April2020 : Date = new Date ('2020-05-12 16:11');
  s71March2020 : Date = new Date ('2020-04-14 14:36');
  s71Feb2020 : Date = new Date ('2020-03-12 14:20');
  s72Jan2020UploadDate :Date = new Date('2020-03-02 14:58:06');
  websiteLiveDate :Date = new Date('2020-01-16');
  s71June2020: Date = new Date('2020-07-14 17:12');
  s71July2020: Date = new Date('2020-08-14 16:21');
  s71August2020: Date = new Date('2020-09-14 14:11');
  s71September2020: Date = new Date('2020-10-14 20:47');
  s71Oct2020: Date = new Date('2020-11-13 09:51');
  s71Nov2020: Date = new Date('2020-12-11 11:20');
  s71Dec2020: Date = new Date('2021-01-15 12:02');
  s71Jan2021: Date = new Date('2021-02-10 11:06');
  s71Feb2021: Date = new Date('2021-03-15 13:11');
  s71March2021: Date = new Date('2021-04-19 13:54');
  s71April2021: Date = new Date('2021-05-14 14:48');
  s71May2021: Date = new Date('2021-05-15 15:11');
  s71June2021: Date = new Date('2021-07-15 13:02');
  s71July2021: Date = new Date('2021-08-14 15:41');
  s71August2021: Date = new Date('2021-09-14 16:49');
  s71September2021: Date = new Date('2021-10-14 09:35');
  s71October2021: Date = new Date('2021-11-12 15:40');
  s71November2021: Date = new Date('2021-12-13 16:23');
  s71December2021: Date = new Date('2022-01-13 14:10');
  s71January2022: Date = new Date('2022-02-10 12:20');
  s71February2022: Date = new Date('2022-03-11 12:12');
  s71March2022: Date = new Date('2022-04-14 08:14');
  s71April2022: Date = new Date('2022-05-16 16:20');
  s71May2022: Date = new Date('2022-06-14 16:14');
  s71June2022: Date = new Date('2022-07-14 22:01');
  s71JuLY2022: Date = new Date('2022-08-15 14:23');
  s71August2022: Date = new Date('2022-09-14 08:02');
  s71Sept2022: Date = new Date('2022-10-14 09:03');
  s71October2022: Date = new Date('2022-11-14 14:11');
  s71November2022: Date = new Date('2022-12-12 13:20');
  s71December2022: Date = new Date('2023-01-16 09:21');
  s71January2023: Date = new Date('2023-02-09 08:47');
  s71February2023: Date = new Date('2023-02-10 09:05');
  s71March2023: Date = new Date('2023-04-14 08:44');
  s71April2023: Date = new Date('2023-05-10 10:40');
  s71May2023: Date = new Date('2023-06-13 12:01');
  s71June2023: Date = new Date('2023-07-14 14:56');
  s71July2023: Date = new Date('2023-08-17 16:22');
  s71August2023: Date = new Date('2023-09-14 14:09');
  s71Sept2023: Date = new Date('2023-10-13 16:25');
  s71October2023: Date = new Date('2023-10-14 11:42');
  s71November2023: Date = new Date('2023-11-14 15:10');
  s71December2023: Date = new Date('2024-01-15 12:45');
  s71January2024: Date = new Date('2024-02-14 16:20');
  s71February2024: Date = new Date('2024-03-14 16:10');
  s71March2024: Date = new Date('2024-04-15 15:31');
  s71April2024: Date = new Date('2024-05-14 15:46');
  s71May2024: Date = new Date('2024-06-14 08:55');
  s71June2024: Date = new Date('2024-07-12 15:11');
  s71July2024: Date = new Date('2024-08-15 14:47');
  s71August2024: Date = new Date('2024-09-13 14:43');
  s71Sept2024: Date = new Date('2024-10-11 14:52');
  s71October2024: Date = new Date('2024-11-14 17:30');
  s71November2024: Date = new Date('2024-12-13 08:51');
  s71December2024: Date = new Date('2025-01-15 15:42');
  s71January2025: Date = new Date('2025-01-15 15:42');
  s71February2025: Date = new Date('2025-03-14 12:08');




  constructor() { }

  ngOnInit() {
  }

}
