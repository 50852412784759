import { Injectable } from '@angular/core';
import { PublicMeetingsModule } from './public-participation.module';

@Injectable({
  providedIn: 'root'
})
export class PublicParticipationService {


  getAllPublicMeetings():PublicMeetingsModule[]{
    return this.publicMeetings;
  }
  publicMeetings:PublicMeetingsModule[] = [
    new PublicMeetingsModule(
      "Ward 2",
      "Monday: 7 April 2025",
      "Ebenhaezer & Papendorp",
      "Ebenhaezer Community Hall",
      "17:00 - 20:00"
    ),
    new PublicMeetingsModule(
      "Ward 6",
      "Tuesday: 8 April 2025",
      "Klawer",
      "Klawer-Noord Community Hall",
      "17:00 - 20:00"
    ),
    new PublicMeetingsModule(
      "Ward 7",
      "Wednesday: 9 April 2025",
      "Vanrhynsdorp",
      "Thusong Centre",
      "19:00 - 21:00"
    ),
    new PublicMeetingsModule(
      "Ward 4",
      "Thursday: 10 April 2025",
      "Vredendal-North",
      "Vredendal- North Community Hall",
      "17:00 - 20:00"
    ),
    new PublicMeetingsModule(
      "Ward 3",
      "Monday: 14 April 2025",
      "Vredendal-North",
      "Vredendal- North Community Hall",
      "17:00 - 20:00"
    ),
    new PublicMeetingsModule(
      "Ward 5",
      "Wednesday: 15 April 2025",
      "Vredendal-South",
      "Vredendal- South Community Hall",
      "17:00 - 20:00"
    ),
    new PublicMeetingsModule(
      "Ward 8",
      "Tuesday: 16 April 2025",
      "Koekenaap",
      "Koekenaap Community Hall",
      "17:00 - 20:00"
    ),
    new PublicMeetingsModule(
      "Ward 8",
      "Tuesday: 16 April 2025",
      "Kliprand",
      "Kliprand Community Hall",
      "11:00 - 13:00"
    ),
    new PublicMeetingsModule(
      "Ward 8",
      "Wednesday: 23 April 2025",
      "Stofkraal",
      "Stofkraal Community Hall",
      "11:00 - 13:00"
    ),
    new PublicMeetingsModule(
      "Ward 8",
      "Wednesday: 23 April 2025",
      "Molsvlei",
      "Molsvlei Community Hall",
      "14:00 - 16:00"
    ),
    new PublicMeetingsModule(
      "Ward 8",
      "Thursday: 24 April 2025",
      "Rietpoort",
      "Rietpoort Community Hall",
      "11:00 - 13:00"
    ),
    new PublicMeetingsModule(
      "Ward 8",
      "Thursday: 24 April 2025",
      "Putsekloof",
      "Putsekloof Community Hall",
      "14:00 - 16:00"
    ),
    new PublicMeetingsModule(
      "Ward 8",
      "Tuesday: 29 April 2025",
      "Bitterfontein",
      "Bitterfontein Community Hall",
      "11:00 - 13:00"
    ),
    new PublicMeetingsModule(
      "Ward 8",
      "Tuesday: 29 April 2025",
      "Nuwerus",
      "Nuwerus Community Hall",
      "14:00 - 16:00"
    ),
    new PublicMeetingsModule(
      "Ward 1",
      "Tuesday: 06 May 2025",
      "Lutzville/ Uitkyk",
      "Lutzville/ Uitkyk Thusong Centre",
      "17:00 - 20:00"
    ),
    new PublicMeetingsModule(
      "Ward 1",
      "Tuesday: 07 May 2025",
      "Lutzville-Wes",
      "Lutzville-Wes Community Hall",
      "17:00 - 20:00"
    ),
    new PublicMeetingsModule(
      "Ward 2",
      "Wednesday: 08 May 2025",
      "Doringbaai",
      "Miriam Owies Community Hall",
      "17:00 - 20:00"
    ),
  ]

  constructor() { }


}
