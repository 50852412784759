<div class="container">
    <!-- <div class="row">
        <h4 class="center">NOTICE: EXTENSION OF T14:2024-25</h4>
        <div class="col s12 m12 l12" align="justify">
           Notice is hereby given that the closing date of tender T14:2024-25 will be extended from 
           <strong>07 February 2025</strong> to
           <strong>14 February 2025.</strong> 
           <br>
            <a href="/assets/municipal-notices/2024-25/T14-24-25 Closing extention.pdf">Download Notice</a> <br>
            MR ER ALFRED <br>
            CHIEF FINANCIAL OFFICER <br>
            30 January 2025
        </div>
    </div> -->
    <div class="row">
        <h4 class="center-align">Open Tenders</h4>

        <!-- <div class="row">
          <p  class="center">
            No tenders currently available.
          </p>
        </div> -->
        <div class="col-s6" *ngFor="let tender of openTenders">
            <mz-collapsible
                [mode]="'expandable'"

                [popout]="true" *ngIf="tender.tenderStatus === 'Open'">
                    <mz-collapsible-item [active]="true">
                        <mz-collapsible-item-header>
                            <i class="material-icons" >keyboard_arrow_down</i>
                            {{tender.tenderTitle}}
                        </mz-collapsible-item-header>
                            <mz-collapsible-item-body>
                                <table class="responsive-table">
                                    <thead>
                                        <tr>
                                            <th data-field="id">Description</th>
                                            <th data-field="name">Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                                 <tr>
                                                    <td><b>Title:</b></td>
                                                    <td>{{tender.tenderTitle}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Reference Number:</b></td>
                                                    <td>{{tender.tenderReferenceNumber}}</td>
                                                </tr>
                                                 <tr>
                                                    <td><b>Upload Date:</b></td>
                                                    <td>{{tender.tenderUploadedDate | date:'longDate'}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Upload Time:</b></td>
                                                    <td>{{tender.tenderUploadedTime}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Closing Date:</b></td>
                                                    <td>{{tender.tenderClosingDate | date:'longDate'}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Closing Time:</b></td>
                                                    <td>{{tender.tenderClosingTime}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Compulsory Meeting Details:</b></td>
                                                    <td>{{tender.tenderCompulsoryMeeting}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Tender Awarded To:</b></td>
                                                    <td>{{tender.tenderAward}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>BEE Status:</b></td>
                                                    <td>{{tender.tenderBEEStatus}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Tender Value:</b></td>
                                                    <td>{{tender.tenderValue}}</td>
                                                </tr>

                                                <tr>
                                                    <td><b>Tender Status:</b></td>
                                                    <td>{{tender.tenderStatus}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Advertisement Download:</b></td>
                                                    <td><a href="{{tender.tenderLink}}" download><u>{{tender.tenderReferenceNumber}}</u></a></td>
                                                </tr>
                                    </tbody>
                                </table>
                            </mz-collapsible-item-body>
                    </mz-collapsible-item>
            </mz-collapsible>
        </div>
    
        
    </div>

</div>
