import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.css']
})
export class PerformanceComponent implements OnInit {

  websiteLiveDate :Date = new Date('2020-01-16');
  Agreements202021 :Date = new Date('2020-08-05');
  AgreementsCFO :Date = new Date('2020-10-05');
  Agreements202122 :Date = new Date('2021-08-05');
  Agreements202223 :Date = new Date('2022-08-12');
  Agreements202324 :Date = new Date('2023-08-11');
  Agreements202425:Date = new Date('2024-07-09');





  constructor() { }

  ngOnInit() {
  }

}
