import { Component, OnInit } from '@angular/core';
import * as dayjs from 'dayjs'



@Component({
  selector: 'app-stratdocs',
  templateUrl: './stratdocs.component.html',
  styleUrls: ['./stratdocs.component.css']
})
export class StratdocsComponent implements OnInit {

  draftIdp2021UploadDate = new Date('2020-03-27 19:02:38');
  draftBudget2021UploadDate = new Date('2020-03-27 19:10:22');
  finalBudget2021UploadDate = new Date('2020-06-03 10:03:48');
  finalBudgetReport2021UploadDate= new Date('2020-06-03 10:06:52');
  draftSDBIP2021UploadDate = new Date ('2020-03-27 21:25:31');
  finalIdp2021UploadDate = new Date('2020-06-03 09:53:36');
  adjustmentBudgetUploadDate =new Date('2020-06-03 09:53:36');
  websiteLiveDate :Date = new Date('2020-01-16');
  ratesLevyUploadDate :Date = new Date('2020-06-11 16:58:04');
  finalSDBIP2021UploadDate = new Date ('2020-06-26 15:48:14');
  adjustmentBudgetFeb2020:Date = new Date('2020-02-08 12:47:02');
  adjustmentBudgetAugust2020:Date = new Date('2020-08-14 12:17');
  timeSchedule2020: Date = new Date('2020-09-01 12:34');
  adjustmentBudgetDecember2020: Date = new Date('2020-12-15 12:20');
  adjustmentBudgetDecemberReport2020: Date = new Date('2020-12-15 12:24');
  adjustmentBudgetFebruary2021: Date = new Date('2020-02-26 10:21');
  amendedSDBIPFebruary2021: Date = new Date('2020-02-26 10:36');
  adjustmentBudgetMarch2021: Date = new Date('2020-03-24 16:31');
  draftIdp2022UploadDate: Date = new Date('2021-03-30 15:53');
  draftBudget2022UploadDate: Date = new Date('2021-03-31 08:47');
  draftA12022UploadDate: Date = new Date('2021-03-31 08:47');
  draftTariffs2022UploadDate: Date = new Date('2021-03-31 09:04');
  finalTariffs2022UploadDate: Date = new Date('2021-05-31 09:15');
  finalA12022UploadDate: Date = new Date('2021-05-31 09:18');
  finalBudget2022UploadDate: Date = new Date('2021-05-31 09:21');
  finalIdp2022UploadDate: Date = new Date('2021-05-31 12:32');
  draftAnnualReport1920UploadDate: Date = new Date('2021-06-30 14:05');
  draftAnnualReport2021UploadDate: Date = new Date('2022-01-26 08:59');
  draftAnnualReport2022UploadDate: Date = new Date('2023-01-31 15:12');
  finalSDBIP2022UploadDate = new Date ('2021-07-08 20:44');
  adjustmentBudgetJune2021: Date = new Date('2021-07-15 16:31');
  oversightReport201920: Date = new Date('2021-09-01 09:21');
  approvedAnnualReport1920UploadDate:Date = new Date('2021-09-01 09:32')
  timeSchedule2122UploadDate:Date = new Date('2021-09-01 10:02')
  finalSDBIP2022_2UploadDate = new Date ('2022-03-02 11:13');
  adjustmentBudgetFebruary2022: Date = new Date('2022-03-02 11:15');
  draftBudgetMarch2022: Date = new Date('2022-03-30 10:48');
  draftIDPMarch2022: Date = new Date('2022-03-31 10:17');
  final2020_21AnnualReportMarch2022: Date = new Date('2022-03-31 11:08');
  oversightReportMarch2022: Date = new Date('2022-04-01 12:57');
  finalBudgetMay2022: Date = new Date('2022-06-01 13:57');
  finalIDPMay2022: Date = new Date('2022-06-01 15:53');
  finalIDPMaySummary2022: Date = new Date('2022-06-10 15:05');
  finalIDPMaySummaryAd2022: Date = new Date('2022-06-10 15:09');
  finalSDBIP2022_23UploadDate = new Date ('2022-07-01 10:27');
  juneSDBIP2021_22UploadDate = new Date ('2022-07-01 10:31');
  adjustmentBudgetJune2022: Date = new Date('2022-07-01 10:40');
  adjustmentBudgetReportJune2022: Date = new Date('2022-07-01 10:43');
  adjustmentBudgetReportOctober2022: Date = new Date('2022-10-27 13:19');
  adjustmentBudgetOctober2022: Date = new Date('2022-10-27 13:22');
  adjustmentBudgetReportDecember2022: Date = new Date('2022-12-07 08:45');
  adjustmentBudgetDecember2022: Date = new Date('2022-12-07 08:47');
  adjustmentBudgetFebruary2023: Date = new Date('2023-02-27 15:38');
  adjustmentBudgetReportFebruary2023: Date = new Date('2023-02-27 15:40');
  adjustmentSDBIPReportFebruary2023: Date = new Date('2023-02-27 15:46');
  draftIDPMarch2023: Date = new Date('2023-03-29 13:30');
  draftBudgetMarch2023: Date = new Date('2023-03-29 13:34');
  finalAnnualReport2022UploadDate: Date = new Date('2023-03-29 15:56');
  oversightReportMarch2023: Date = new Date('2023-03-31 15:16');
  draftSDBIPMarch2023: Date = new Date('2023-03-28 13:42');
  adjustmentBudgetReportMay2023: Date = new Date('2023-06-01 16:50');
  finalBudgetMay2023: Date = new Date('2023-06-01 16:48');
  finalIDPMay2023: Date = new Date('2023-06-01 16:38');
  adjustmentSDBIPReportMay2023:Date = new Date('2023-06-01 16:35');
  adjustmentBudgetCouncilResJune2023:Date = new Date('2023-06-30 11:20');
  adjustmentBudgetJune2023:Date = new Date('2023-06-30 11:23');
  adjustmentBudgetReportJune2023:Date = new Date('2023-06-30 11:27');
  finalSDBIP2023_24UploadDate = new Date ('2023-06-30 15:11');
  idp2324=new Date("2023-09-07 10:01");
  idp2324deadlines=new Date("2023-09-07 10:05");
  adjustmentBudgetCouncilResOct2023:Date = new Date('2023-11-01 16:43');
  adjustmentBudgetOct2023:Date = new Date('2023-11-01 16:45');
  adjustmentBudgetReportOct2023:Date = new Date('2023-11-01 16:47');
  adjustmentBudgetFeb2024:Date = new Date('2024-02-26 13:10');
  adjustmentBudgetReportFeb2024:Date = new Date('2024-02-26 13:12');
  adjustmentSDBIPReportFebruary2024: Date = new Date('2024-02-26 13:15');
  finalAnnualReport2024UploadDate: Date = new Date('2024-02-01 12:24');
  finalAnnualReportAC2024UploadDate: Date = new Date('2024-02-26 14:35');
  idpMarch2425: Date = new Date('2024-03-27 16:43');
  idp2425pp: Date = new Date('2024-03-27 16:41');
  idp2425sp: Date = new Date('2024-03-27 16:39');
  draftMarch202425: Date = new Date('2024-03-27 16:46');
  oversightReportMarch2024: Date = new Date('2024-04-03 14:29');
  approvedAR2024UploadDate: Date = new Date('2024-03-28 08:42');
  finalIDPMay24:Date = new Date('2024-05-21 19:51');
  finalIDPSummaryMay24:Date = new Date('2024-05-21 19:49');
  finalIDPSectorPlansMay24:Date = new Date('2024-05-21 19:46');
  adjustmentSDBIPReportMay2024:Date = new Date('2024-05-21 19:57');
  adjustmentBudgetReportMay2024:Date = new Date('2024-05-21 20:11');
  adjustmentBudgetMay2024:Date = new Date('2024-05-21 20:09');
  finalBudgetMay2024:Date = new Date('2024-05-21 20:15');
  adjustmentBudgetReportJune2024:Date = new Date('2024-06-27 15:36');
  adjustmentBudgetJune2024:Date = new Date('2024-06-27 15:33');
  finalSDBIPJune2024:Date = new Date('2024-06-17 11:35');
  approvedAR2025UploadDate:Date = new Date('2025-02-04 15:33');
  adjustmentBudgetFeb2025:Date = new Date('2025-02-28 15:13');
  adjustmentBudgetReportFeb2025:Date = new Date('2025-02-28 15:16');
  adjustmentSDBIPReportFebruary2025:Date = new Date('2025-02-28 15:19');
  draftMarch202526:Date = new Date('2025-03-31 16:18');
  idpMarch2526:Date = new Date('2025-03-31 16:27');
  oversightReportMarch2025:Date = new Date('2025-03-27 12:49');
  approvedCouncilAR2025UploadDate:Date = new Date('2025-03-27 12:46');












































  constructor() {


   }

  ngOnInit() {

     this.draftIdp2021UploadDate;


  }

/* draftIdpUploadDate(){
  const idpUploadDate = new Date();
  return idpUploadDate.toDateString;
} */

}
