
   <div class="container hide-on-med-and-down">
        <div class="row">
            <div class="col s3 mayor">
               <app-lead-side-bar></app-lead-side-bar>
            </div>
            <div class="col s9">
               <h4 class="center">Office of the Mayor</h4>
               <hr>
               <div class="col s12">
                    <div class="col s12 m6 l6">
                        <img src="/assets/Council/Matz Mun_022 w.jpg"  alt="Matzikama Mayor" class="responsive-img">
                         <span class="flow-text center">Executive Mayor Mr. J. Van Der Hoven</span>
                    </div>
                    <div class="col s12 m6 l6">
                        <span class="black-text">
                            <p class="leadtext right"align="justify">
                            The Structures Act defines an Executive Mayor as an executive mayor elected in terms of section 55 of the Act.
  Only municipalities of the type that have a mayoral executive system may have an executive mayor.
  A Mayoral executive system is a system of municipal government which allows for the Guideline Document
  on the Roles and Responsibilities of Councillors and Officials 35 exercise of executive authority through an
  executive mayor in whom the executive leadership of the municipality is vested and who is assisted by a mayoral committee.
  The powers and functions of the Executive Mayor are set out in section 56 of the Structures Act.
  The Executive Mayor is the political head of the Municipality and exercises both statutory and ceremonial functions.
  These functions are illustrated below. <br>
  <b>Functions and powers of executive mayors</b><br>
  An executive mayor is entitled to receive reports from committees of the municipal council and to forward these reports
  together with a recommendation to the council when the matter cannot be disposed of by the executive mayor in terms of the
  executive mayor's delegated powers.<br>
  <b>(1) The executive mayor must-</b><br>
      (a) identify the needs of the municipality; <br>

      (b) review and evaluate those needs in order of priority; <br>

      (c) recommend to the municipal council strategies, programmes and services to address priority needs through the integrated
      development plan, and the estimates of revenue and expenditure, taking into account any applicable national and provincial
      development plans; and <br>

      (d) recommend or determine the best way, including partnership and other approaches, to deliver those strategies, programmes
      and services to the maximum benefit of the community. <br>

  <b>(2) The executive mayor in performing the duties of office, must-</b> <br>

      (a) identify and develop criteria in terms of which progress in the implementation of the strategies, programmes and services
       referred to in subsection (2) (c) can be evaluated, including key performance indicators which are specific to the
       municipality and common to local government in general; <br>
      (b) evaluate progress against the key performance indicators; <br>
      (c) review the performance of the municipality in order to improve- <br>
          (i) the economy, efficiency and effectiveness of the municipality; <br>
          (ii) the efficiency of credit control and revenue and debt collection services; and<br>
          (iii) the implementation of the municipality's by-laws;<br>
      (d) monitor the management of the municipality's administration in accordance with the directions of the municipal council;<br>
      (e) oversee the provision of services to communities in the municipality in a sustainable manner; <br>
      (f) perform such duties and exercise such powers as the council may delegate to the executive mayor in terms of section 59
      of the Local Government: Municipal Systems Act, 2000 (Act 32 of 2000); <br>
      (g) annually report on the involvement of communities and community organisations in the affairs of the municipality; and <br>
    (h) ensure that regard is given to public views and report on the effect of consultation on the decisions of the council.<br>
                        </span>
                    </div>

                </div>
            </div>
        </div>

   </div>
   <!-- This is the mobile UI version -->
   <div class="container hide-on-large-only">
    <div class="row">
        <h4 class="center">Office of the Mayor</h4>
    </div>
    <div class="row">
        <div class="col s12 m12 l4">
            <img src="/assets/Council/IMG_1589.jpg"  alt="Matzikama Mayor" class="responsive-img">
            <span class="flow-text center">Executive Mayor Mr. J. Van Der Hoven</span>
        </div>
        <div class="col s12 m12 l4">
            <span class="black-text">
                <p class="leadtext right"align="justify">
                The Structures Act defines an Executive Mayor as an executive mayor elected in terms of section 55 of the Act.
Only municipalities of the type that have a mayoral executive system may have an executive mayor.
A Mayoral executive system is a system of municipal government which allows for the Guideline Document
on the Roles and Responsibilities of Councillors and Officials 35 exercise of executive authority through an
executive mayor in whom the executive leadership of the municipality is vested and who is assisted by a mayoral committee.
The powers and functions of the Executive Mayor are set out in section 56 of the Structures Act.
The Executive Mayor is the political head of the Municipality and exercises both statutory and ceremonial functions.
These functions are illustrated below. <br>
<b>Functions and powers of executive mayors</b><br><span id="dots"></span>
An executive mayor is <span id="more"></span>entitled to receive reports from committees of the municipal council and to forward these reports
together with a recommendation to the council when the matter cannot be disposed of by the executive mayor in terms of the
executive mayor's delegated powers.<br>
<b>(1) The executive mayor must-</b><br>
(a) identify the needs of the municipality; <br>

(b) review and evaluate those needs in order of priority; <br>

(c) recommend to the municipal council strategies, programmes and services to address priority needs through the integrated
development plan, and the estimates of revenue and expenditure, taking into account any applicable national and provincial
development plans; and <br>

(d) recommend or determine the best way, including partnership and other approaches, to deliver those strategies, programmes
and services to the maximum benefit of the community. <br>

<b>(2) The executive mayor in performing the duties of office, must-</b> <br>

(a) identify and develop criteria in terms of which progress in the implementation of the strategies, programmes and services
referred to in subsection (2) (c) can be evaluated, including key performance indicators which are specific to the
municipality and common to local government in general; <br>
(b) evaluate progress against the key performance indicators; <br>
(c) review the performance of the municipality in order to improve- <br>
(i) the economy, efficiency and effectiveness of the municipality; <br>
(ii) the efficiency of credit control and revenue and debt collection services; and<br>
(iii) the implementation of the municipality's by-laws;<br>
(d) monitor the management of the municipality's administration in accordance with the directions of the municipal council;<br>
(e) oversee the provision of services to communities in the municipality in a sustainable manner; <br>
(f) perform such duties and exercise such powers as the council may delegate to the executive mayor in terms of section 59
of the Local Government: Municipal Systems Act, 2000 (Act 32 of 2000); <br>
(g) annually report on the involvement of communities and community organisations in the affairs of the municipality; and <br>
(h) ensure that regard is given to public views and report on the effect of consultation on the decisions of the council.<br>
            </span>
            <!-- <div class="waves-effect waves-light btn">Read more</div> -->
        </div>
    </div>
</div>
<app-footer></app-footer>


