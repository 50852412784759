import { Injectable } from '@angular/core';
import { TendersModule } from './tenders.module';
@Injectable({
  providedIn: 'root'
})
export class TenderService {
   allTenders:TendersModule[] = [
    new TendersModule(
      `FQ183-2024-2025 COURIER SERVICE FOR WATER SAMPLES`,
      "FQ183-2024-2025",
      "2025-04-10",
      "08:53",
      "2025-04-17",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ183-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Open`,
    ),
    new TendersModule(
      `FQ182-2024-2025 SUPPLY AND INSTALL OF MEMBRANES AT BITTERFONTEIN WATER TREATMENT WORKS`,
      "FQ182-2024-2025",
      "2025-04-10",
      "08:50",
      "2025-04-17",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ182-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Open`,
    ),
    new TendersModule(
      `FQ181-2024-2025 SUPPLY AND INSTALL OF MEMBRANE IN KLIPRAND WATER TREATMENT WORKS `,
      "FQ181-2024-2025",
      "2025-04-10",
      "08:46",
      "2025-04-17",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ181-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Open`,
    ),
    new TendersModule(
      `FQ180-2024-2025 SUPPLY AND DELIVERY OF FILTER BAGS`,
      "FQ180-2024-2025",
      "2025-04-10",
      "08:44",
      "2025-04-17",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ180-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Open`,
    ),
    new TendersModule(
      `FQ179-2024-2025 HIRING OF SUPER SUCKER TRUCK`,
      "FQ179-2024-2025",
      "2025-04-10",
      "08:38",
      "2025-04-17",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ179-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Open`,
    ),
    new TendersModule(
      `FQ178-2024-2025 SERVICE PROVIDER FOR REPAIRS OF CLARIFIER WHEELS VREDENDAL SOUTH`,
      "FQ178-2024-2025",
      "2025-04-10",
      "08:35",
      "2025-04-18",
      "No later than 12:00",
      `Compulsory Site Meeting	16 April 2025 at 10:00 at the Technical Offices, 1 Sirkelweg, Vredendal`,
      ``,
      "/assets/Tenders/2024-25/FQ178-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Open`,
    ),
    new TendersModule(
      `K37-2024-2025 NOTICE: LEASING OF MUNICIPAL BUILDING ON ERF 382, BITTERFONTEIN`,
      "K37-2024-2025",
      "2025-04-09",
      "14:35",
      "2025-05-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/K37-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Open`,
    ),
    new TendersModule(
      `T21-2024-2025 SUPPLY AND DELIVERY OF ROAD BUILDING MATERIAL FOR A ONE YEAR PERIOD`,
      "T21-2024-2025",
      "2025-04-04",
      "08:42",
      "2025-05-05",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/T21-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Open`,
    ),
    new TendersModule(
      `T20-2024-2025 SUPPLY AND DELIVERY OF LAPTOPS`,
      "T20-2024-2025",
      "2025-04-04",
      "08:40",
      "2025-04-22",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/T20-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Open`,
    ),
    new TendersModule(
      `FQ177-2024-2025 SERVICE PROVIDER TO OBTAIN A REPLACEMENT TITLE DEEDS`,
      "FQ177-2024-2025",
      "2025-04-02",
      "11:39",
      "2025-04-09",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ177-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ176-2024-2025 SUPPLY AND DELIVERY OF ANTISCALANTS`,
      "FQ176-2024-2025",
      "2025-03-28",
      "11:37",
      "2025-04-07",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ176-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ175-2024-2025 REPAIRS OF LEAKAGE AT LUTZVILLE RESERVOIR`,
      "FQ175-2024-2025",
      "2025-03-28",
      "11:34",
      "2025-04-09",
      "No later than 12:00",
      `04 April 2025 at 10:00 at the Technical Offices, 1 Sirkelweg, Vredendal`,
      ``,
      "/assets/Tenders/2024-25/FQ175-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ173-2024-2025 SUPPLY AND DELIVERY OF VALVES AND WATERMETERS`,
      "FQ173-2024-2025",
      "2025-03-27",
      "15:21",
      "2025-04-09",
      "No later than 12:00",
      `04 April 2025 at 10:00 at the Technical Offices, 1 Sirkelweg, Vredendal`,
      ``,
      "/assets/Tenders/2024-25/FQ173_2-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ166-2024-2025 SERVICE AND REPAIR OR REPLACE OF FIRE EXTINGUSHERS`,
      "FQ166-2024-2025",
      "2025-03-26",
      "15:25",
      "2025-04-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ166-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ174-2024-2025 SUPPLY AND DELIVERY OF CARTRIDGES`,
      "FQ174-2024-2025",
      "2025-03-26",
      "15:23",
      "2025-04-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ174-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ172-2024-2025 LEASING OF SUPER SUCKER TRUCK (WET RATE) FOR FIVE DAYS`,
      "FQ172-2024-2025",
      "2025-03-25",
      "10:34",
      "2025-04-02",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ172-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ171-2024-2025 SUPPLY, DELIVERY AND INSTALLATION OF TRAFFIC VEHICLE LIGHTS AND SIRENS`,
      "FQ171-2024-2025",
      "2025-03-25",
      "10:31",
      "2025-04-02",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ171-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ170-2024-2025 SUPPLY AND DELIVERY OF SECTION 56 SUMMON BOOKS AND J534 SUMMON BOOKS `,
      "FQ170-2024-2025",
      "2025-03-25",
      "10:28",
      "2025-04-02",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ170-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ169-2024-2025 ONLINE ACCESS TO THE NATIONAL ROAD TRAFFIC ACT (NRTA) 93/96 AND REGULATIONS`,
      "FQ169-2024-2025",
      "2025-03-25",
      "10:26",
      "2025-04-02",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ169-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `T19-2024-2025 INTERNAL AUDIT SERVICES FOR A 3 YEAR PERIOD`,
      "T19-2024-2025",
      "2025-03-20",
      "11:36",
      "2025-04-22",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/T19-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Open`,
    ),
    new TendersModule(
      `T18-2024-2025 SUPPLY AND DELIVERY OF PROTECTIVE SHOES FOR A DURATION OF ONE YEAR TO EXTENT FOR ADDITIONAL TWO YEARS`,
      "T18-2024-2025",
      "2025-03-20",
      "11:34",
      "2025-04-22",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/T18-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Open`,
    ),
    new TendersModule(
      `FQ168-2024-2025 SUPPLY AND DELIVERY OF TWO COMMERCIAL TRAILERS`,
      "FQ168-2024-2025",
      "2025-03-20",
      "11:31",
      "2025-03-31",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ168-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ167-2024-2025 SUPPLY AND DELIVERY OF BLACK REFUSE PLASTIC BAGS`,
      "FQ167-2024-2025",
      "2025-03-20",
      "11:28",
      "2025-03-31",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ167-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ165-2024-2025 APPOINTMENT OF A CONSULTANT FOR HANDLING OF APPOINTMENT PROCESS OF DIRECTOR OF TECHNICAL SERVICES AND DIRECTOR OF CORPORATE SERVICES`,
      "FQ165-2024-2025",
      "2025-03-14",
      "14:58",
      "2025-03-24",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ165-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ164-2024-2025 LIVE STREAMING SERVICES `,
      "FQ164-2024-2025",
      "2025-03-13",
      "10:21",
      "2025-03-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ164-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ163-2024-2025 SERVICE PROVIDER FOR THE INSTALLATION OF COMMERCIAL AND DOMESTIC WATER METERS AS AN WHEN NEEDED `,
      "FQ163-2024-2025",
      "2025-03-12",
      "09:15",
      "2025-03-19",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ163-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ162-2024-2025 SUPPLY AND DELIVERY OF COMPUTER HARDWARE`,
      "FQ162-2024-2025",
      "2025-03-07",
      "10:04",
      "2025-03-14",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ162-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
      new TendersModule(
      `FQ161-2024-2025 SUPPLY AND DELIVERY OF RIGGING EQUIPMENT, ELECTRICAL TESTERS & LADDERS`,
      "FQ161-2024-2025",
      "2025-03-07",
      "09:58",
      "2025-03-14",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ161-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ160-2024-2025 APPOINTMENT OF A SERVICE PROVIDER TO FACILITATE THE ANNUAL  ELECTRICITY TARIFF INCREASE`,
      "FQ160-2024-2025",
      "2025-03-04",
      "11:08",
      "2025-03-11",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ160-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ159-2024-2025 APPOINTMENT OF A TRAVEL AGENCY FOR MATZIKAMA MUNICIPALITY`,
      "FQ159-2024-2025",
      "2025-03-04",
      "11:05",
      "2025-03-11",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ159-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ158-2024-2025 REPAIRS AND MAINTENANCE OF OFFICE CHAIRS`,
      "FQ158-2024-2025",
      "2025-03-04",
      "11:03",
      "2025-03-11",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ158-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ157-2024-2025 SUPPLY AND DELIVERY OF TRIPLICATE BOOKS`,
      "FQ157-2024-2025",
      "2025-02-24",
      "10:04",
      "2025-03-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ157-2024-2025.pdf",
      `Deon Boonzaaier/Innovative  Printing`,
      `3`,
      `R 195.00 per book`,
      `Closed`,
    ),
    new TendersModule(
      `FQ156-2024-2025 REPAIRS AND MAINTENACE OF OFFICE CHAIRS`,
      "FQ156-2024-2025",
      "2025-02-24",
      "10:01",
      "2025-03-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ156-2024-2025.pdf",
      `NOT AWARDED. RE-ADVERTISED`,
      `NOT AWARDED. RE-ADVERTISED`,
      `NOT AWARDED. RE-ADVERTISED`,
      `Closed`,
    ),
    new TendersModule(
      `FQ155-2024-2025 APPOINTMENT OF A TRAVEL AGENCY FOR MATZIKAMA MUNICIPALITY`,
      "FQ155-2024-2025",
      "2025-02-24",
      "09:58",
      "2025-03-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ155-2024-2025.pdf",
      `NOT AWARDED. RE-ADVERTISED`,
      `NOT AWARDED. RE-ADVERTISED`,
      `NOT AWARDED. RE-ADVERTISED`,
      `Closed`,
    ),
    new TendersModule(
      `FQ154-2024-2025 SUPPLY AND DELIVERY OF MULTIPLE TEMPERATURE RUBBER CUSHION GUM AND ROPE RUBBER`,
      "FQ154-2024-2025",
      "2025-02-19",
      "11:07",
      "2025-02-26",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ154-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ153-2024-2025 SUPPLY AND DELIVERY OF INVERTERS AND LITHIUM BATTERIES`,
      "FQ153-2024-2025",
      "2025-02-19",
      "11:05",
      "2025-02-26",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ153-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ152-2024-2025 FURNITURE REMOVALS: FORM MATJIESKLOOF TO VANRHYNSDORP `,
      "FQ152-2024-2025",
      "2025-02-18",
      "10:28",
      "2025-02-25",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ152-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ151-2024-2025 SUPPLY AND DELIVERY OF LOW VOLTAGE MATERIALS FOR MAINTENANCE AT STRANDFONTEIN CAMP SITES `,
      "FQ151-2024-2025",
      "2025-02-18",
      "10:21",
      "2025-02-25",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ151-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ150-2024-2025 APPOINTMENT OF A QUALIFIED PROFESSIONAL LAND SURVEYOR TO SURVEY MUNICIPAL MINIMUM BASIC SERVICES
        INFRASTRUCTURE AND NON-RESIDENDTIAL ERVEN `,
      "FQ150-2024-2025",
      "2025-02-18",
      "10:17",
      "2025-02-25",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ150-2024-2025.pdf",
      `Louis de Bruin`,
      `4`,
      `R 88 884.65`,
      `Closed`,
    ),
    new TendersModule(
      `FQ149-2024-2025 SUPPLY AND DELIVERY OF BUILDING MATERIAL FOR MAINTENANCE IN KLAWER`,
      "FQ149-2024-2025",
      "2025-02-13",
      "11:28",
      "2025-02-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ149-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ148-2024-2025 SUPPLY AND DELIVERY OF TRIPLICATE BOOKS`,
      "FQ148-2024-2025",
      "2025-02-13",
      "11:25",
      "2025-02-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ148-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ147-2024-2025 SUPPLY AND DELIVERY OF STEEL CABINETS`,
      "FQ147-2024-2025",
      "2025-02-13",
      "11:23",
      "2025-02-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ147-2024-2025.pdf",
      `Bidvest Waltons`,
      `1`,
      `R 40 250.00`,
      `Closed`,
    ),
    new TendersModule(
      `FQ146-2024-2025 SUPPLY AND DELIVERY OF GEYSERS`,
      "FQ146-2024-2025",
      "2025-02-13",
      "11:20",
      "2025-02-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ146-2024-2025.pdf",
      `Buclabo`,
      `1`,
      `Per item`,
      `Closed`,
    ),
    new TendersModule(
      `FQ145-2024-2025 SPECIALISED DIVING SERVICES FOR CLEANING, INSPECTION, MAINTENANCE AND REPAIRS OF WATER FACILITIES
      (RESERVOIRS, CHAMBERS, DAMS, ETC.) AS AND WHEN REQUIRED`,
      "FQ145-2024-2025",
      "2025-02-11",
      "09:29",
      "2025-02-18",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ145-2024-2025.pdf",
      `RDB Buuilding and Construction`,
      `1`,
      `Per Hour Rate`,
      `Closed`,
    ),
    new TendersModule(
      `FQ144-2024-2025 SUPPLY AND DELIVERY OF ANTISCALANT`,
      "FQ144-2024-2025",
      "2025-02-11",
      "09:26",
      "2025-02-18",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ144-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ143-2024-2025 SUPPLY AND DELIVERY OF TOOLS AND EQUIPMENT`,
      "FQ143-2024-2025",
      "2025-02-11",
      "09:20",
      "2025-02-18",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ143-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ142-2024-2025 SUPPLY AND DELIVERY OF BUILDING MATERIAL FOR REPAIRS IN STRANDFONTEIN `,
      "FQ142-2024-2025",
      "2025-02-11",
      "09:17",
      "2025-02-18",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ142-2024-2025.pdf",
      `Take Note Trading`,
      `1`,
      `R 24 675.55`,
      `Closed`,
    ),
    new TendersModule(
      `FQ141-2024-2025 TRANSPORT SERVICES: WARD COMMITTEE MEETING `,
      "FQ141-2024-2025",
      "2025-02-06",
      "11:46",
      "2025-02-13",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ141-2024-2025.pdf",
      `Hough Trasport and General Works`,
      `1`,
      `R 8650`,
      `Closed`,
    ),
    new TendersModule(
      `FQ140-2024-2025 SUPPLY AND DELIVERY OF STATIONERY: CDW `,
      "FQ140-2024-2025",
      "2025-02-06",
      "11:44",
      "2025-02-13",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ140-2024-2025.pdf",
      `Waltons`,
      `1`,
      `R 10 025.95`,
      `Closed`,
    ),
    new TendersModule(
      `T17-2024-2025 PROVISION OF COMMERCIAL BANKING SERVICES FOR A 5 YEAR PERIOD`,
      "T17-2024-2025",
      "2025-01-31",
      "10:24",
      "2025-03-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/T17-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `T16-2024-2025 ACCOUNTING SUPPORT SERVICES FOR ASSET MANAGEMENT FOR A 3 YEAR PERIOD `,
      "T16-2024-2025",
      "2025-01-31",
      "10:21",
      "2025-03-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/T16-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `T15-2024-2025 ACCOUNTING SUPPORT SERVICES FOR A 3 YEAR PERIOD `,
      "T15-2024-2025",
      "2025-01-31",
      "10:19",
      "2025-03-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/T15-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ139-2024-2025 SUPPLY AND DELIVERY OF LAPTOPS `,
      "FQ139-2024-2025",
      "2025-01-29",
      "10:56",
      "2025-02-05",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ139-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ138-2024-2025 SUPPLY AND DELIVERY OF MATERIALS FOR REPAIRS OF HIGH MAST LIGHTING `,
      "FQ138-2024-2025",
      "2025-01-29",
      "10:52",
      "2025-02-05",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ138-2024-2025.pdf",
      `Kader Technologies`,
      `1`,
      `R 5058.03 per complete LED`,
      `Closed`,
    ),
    new TendersModule(
      `FQ137-2024-2025 SUPPLY AND DELIVERY OF BRANDING EQUIPMENT`,
      "FQ137-2024-2025",
      "2025-01-24",
      "10:44",
      "2025-01-31",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ137-2024-2025.pdf",
      `Gabriel and Michael Marketing`,
      `1`,
      `R  15 172.41`,
      `Closed`,
    ),
    new TendersModule(
      `FQ136-2024-2025 SUPPLY AND DELIVERY OF ANTISCALANT`,
      "FQ136-2024-2025",
      "2025-01-24",
      "10:41",
      "2025-01-31",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ136-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ135-2024-2025 SUPPLY AND DELIVERY OF CEMENT`,
      "FQ135-2024-2025",
      "2025-01-24",
      "10:39",
      "2025-01-31",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ135-2024-2025.pdf",
      `Memotek Trading`,
      `1`,
      `Award per item`,
      `Closed`,
    ),
    new TendersModule(
      `T14-2024-2025 VREDENDAL WATER SYSTEM – TELEMETRY AND RELATED INFRASTRUCTURE`,
      "T14-2024-2025",
      "2025-01-22",
      "10:44",
      "2025-02-14",
      "No later than 12:00",
      `30 JANUARY 2025   at 11:00 at the Municipal offices at Sirkelweg, Vredendal`,
      ``,
      "/assets/Tenders/2024-25/T14-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `T13-2024-2025 SUPPLY AND DELIVERY OF HEAVY MOTOR VEHICLES AND LIGHT DELIVERY FLEET VEHICLES ON RENTAL`,
      "T13-2024-2025",
      "2025-01-22",
      "10:40",
      "2025-02-24",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/T13-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ134-2024-2025 SUPPLY AND DELIVERY OF DPD NO 1 TABLETS FOR WATER TESTING`,
      "FQ134-2024-2025",
      "2025-01-19",
      "10:17",
      "2025-01-24",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ134-2024-2025.pdf",
      `Vredendal Besproeiing`,
      `0`,
      `R 14 950.00`,
      `Closed`,
    ),
    new TendersModule(
      `FQ133-2024-2025 SUPPLY AND DELIVERY OF A LAY FLAT IRRIGATION HOSE 50MM X 100M`,
      "FQ133-2024-2025",
      "2025-01-17",
      "10:17",
      "2025-01-24",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ133-2024-2025.pdf",
      `Memotek Trading`,
      `1`,
      `R 7 147.25`,
      `Closed`,
    ),
    new TendersModule(
      `FQ132-2024-2025 SUPPLY, DELIVERY, INSTALLATION AND COMMISSIONING OF NEW PEDESTRIAN
        TRAFFIC LIGHT CONTROLLER AND LED LIGHT FITTINGS`,
      "FQ132-2024-2025",
      "2025-01-17",
      "10:14",
      "2025-01-24",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ132-2024-2025.pdf",
      `TMT Services amd Supplies`,
      `1`,
      `R 96 614.40`,
      `Closed`,
    ),
    new TendersModule(
      `FQ131-2024-2025 SUPPLY AND DELIVERY OF ELECTRICAL MATERIALS FOR PUMPSTATION`,
      "FQ131-2024-2025",
      "2025-01-17",
      "10:12",
      "2025-01-24",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ131-2024-2025.pdf",
      `Kader Technologies`,
      `1`,
      `Award per item`,
      `Closed`,
    ),
    new TendersModule(
      `FQ130-2024-2025 SUPPLY AND DELIVERY OF SECTION 56 SUMMONS BOOKS AND SECTION 341 NOTICE BOOKS TRIFOLD PRINTED`,
      "FQ130-2024-2025",
      "2024-12-10",
      "11:40",
      "2024-12-18",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ130-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ129-2024-2025 SUPPLY AND DELIVERY OF BUILDING MATERIAL FOR MAINTENANCE AT STRANDFONTEIN RESORT`,
      "FQ129-2024-2025",
      "2024-12-10",
      "10:37",
      "2024-12-18",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ129-2024-2025.pdf",
      `2`,
      `Buclabo`,
      `R 28 891.50 `,
      `Closed`,
    ),
    new TendersModule(
      `FQ128-2024-2025 SUPPLY, DELIVERY AND REPLACEMENT OF INLET AND OUTLET PIPES, CHECK VALVE AND BUTTERFLY VALVE VANRHYNSDORP PUMPSTATION`,
      "FQ128-2024-2025",
      "2024-12-10",
      "10:34",
      "2024-12-18",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ128-2024-2025.pdf",
      `1`,
      `Take Note Trading`,
      `R 36 751.70`,
      `Closed`,
    ),
    new TendersModule(
      `FQ127-2024-2025 SUPPLY AND DELIVERY OF TRIPLICATE AND DUPLICATE BOOKS`,
      "FQ127-2024-2025",
      "2024-12-05",
      "10:56",
      "2024-12-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ127-2024-2025.pdf",
      `4`,
      `Piketberg Drukkers`,
      `Price per item`,
      `Closed`,
    ),
    new TendersModule(
      `FQ126-2024-2025 HIRE OF CRANE TRUCK - AS AND WHEN NEEDED`,
      "FQ126-2024-2025",
      "2024-12-05",
      "10:43",
      "2024-12-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ126-2024-2025.pdf",
      `0`,
      `Bettco`,
      `R 33 350.00`,
      `Closed`,
    ),
    new TendersModule(
      `FQ125-2024-2025 LABOUR COST: STRANDFONTEIN HOMES`,
      "FQ125-2024-2025",
      "2024-11-29",
      "09:05",
      "2024-12-10",
      "No later than 12:00",
      `5 December 2024 at 11:00 at the Standfontein Community Hall (at the entrance of Standfontein)`,
      ``,
      "/assets/Tenders/2024-25/FQ125-2024-2025.pdf",
      `1`,
      `Cohootz Trading`,
      `R273 642.50`,
      `Closed`,
    ),
    new TendersModule(
      `FQ124-2024-2025 SUPPLY AND DELIVERY OF BUILDING MATERIALS IN STRANDFONTEIN`,
      "FQ124-2024-2025",
      "2024-11-29",
      "09:03",
      "2024-12-05",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ124-2024-2025.pdf",
      `2`,
      `Buclabo`,
      `R 210 746.90`,
      `Closed`,
    ),
    new TendersModule(
      `FQ123-2024-2025 SUPPLY AND DELIVERY OF LAPTOPS`,
      "FQ123-2024-2025",
      "2024-11-29",
      "08:58",
      "2024-12-05",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ123-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ122-2024-2025 APPOINT OF AN LEGAL FIRM TO OBTAIN EVICTION ORDERS`,
      "FQ122-2024-2025",
      "2024-11-28",
      "11:50",
      "2024-12-04",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ122-2024-2025.pdf",
      `2`,
      `Heyns and Partners`,
      `Rate per unit`,
      `Closed`,
    ),
    new TendersModule(
      `FQ121-2024-2025 SUPPLY AND DELIVERY OF FENCING FOR KLAWER SPORTGROUNDS`,
      "FQ121-2024-2025",
      "2024-11-28",
      "10:04",
      "2024-12-04",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ121-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ120-2024-2025 APPOINT OF AN QUALIFIED REGISTERED PROFESSIONAL LAND SURVEYOR TO RELOCATE THE BEACONS ON ERF 245, VREDENDAL AND ISSUE A BEACON CERTIFICATE`,
      "FQ120-2024-2025",
      "2024-11-28",
      "10:01",
      "2024-12-04",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ120-2024-2025.pdf",
      `4`,
      `Louis de Bruin`,
      `R11 500`,
      `Closed`,
    ),
    new TendersModule(
      `FQ119-2024-2025 SUPPLY AND DELIVERY OF VEHICLE BATTERIES`,
      "FQ119-2024-2025",
      "2024-11-25",
      "09:57",
      "2024-12-02",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ119-2024-2025.pdf",
      `1`,
      `Memotek Trading`,
      `Price per item`,
      `Closed`,
    ),
    new TendersModule(
      `FQ118-2024-2025 SUPPLY AND DELIVERY OF BEARING GREASE AND OIL`,
      "FQ118-2024-2025",
      "2024-11-25",
      "09:55",
      "2024-12-02",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ118-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ117-2024-2025 THE SUPPLY AND DELIVERY OF HANDHELD RADIOS AS WELL AS DMR NETWORK COVERAGE FOR THE MATZIKAMA MUNICIPALITY`,
      "FQ117-2024-2025",
      "2024-11-25",
      "09:52",
      "2024-12-02",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ117-2024-2025.pdf",
      `Beslek Outomatisering`,
      `4`,
      `R 129 383.63`,
      `Closed`,
    ),
    new TendersModule(
      `FQ116-2024-2025 SUPPLY AND DELIVERY OF CHAIN BLOCKS`,
      "FQ116-2024-2025",
      "2024-11-25",
      "09:47",
      "2024-12-02",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ116-2024-2025.pdf",
      `Gabriel and Michael Marketing`,
      `1`,
      `R 17 894`,
      `Closed`,
    ),
    new TendersModule(
      `FQ115-2024-2025 SUPPLY AND DELIVERY OF BEARINGS, SEALS AND CONSUMABLES FOR THE REPAIR OF A 132KW MOTOR AND PUMP`,
      "FQ115-2024-2025",
      "2024-11-25",
      "09:42",
      "2024-12-02",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ115-2024-2025.pdf",
      `Vredendal Besproeiing`,
      `0`,
      `R 13 818.40`,
      `Closed`,
    ),
    new TendersModule(
      `FQ114-2024-2025 SUPPLY AND DELIVERY OF CONCRETE STONE FOR SAND FILTERS`,
      "FQ114-2024-2025",
      "2024-11-19",
      "10:21",
      "2024-11-26",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ114-2024-2025_2.pdf",
      `1`,
      `RDB Building and Construction`,
      `R34 155.00`,
      `Closed`,
    ),
    new TendersModule(
      `FQ113-2024-2025 SUPPLY AND DELIVERY OF AN KSB 0800-65-250 CENTRIFUGAL PUMP`,
      "FQ113-2024-2025",
      "2024-11-19",
      "10:17",
      "2024-11-26",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ113-2024-2025.pdf",
      `Vredendal Besproeiing`,
      `0`,
      `R 23 412.85`,
      `Closed`,
    ),
    new TendersModule(
      `FQ112-2024-2025 SUPPLY AND DELIVERY OF CRICKET HATS`,
      "FQ112-2024-2025",
      "2024-11-19",
      "10:05",
      "2024-11-26",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ112-2024-2025.pdf",
      `Safepro`,
      `1`,
      `R 43 642.50`,
      `Closed`,
    ),
    new TendersModule(
      `FQ111-2024-2025 SUPPLY AND ADMINISTRATION OF HEPITITUS B VACCINATIONS`,
      "FQ111-2024-2025",
      "2024-11-19",
      "10:02",
      "2024-11-26",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ111-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ110-2024-2025 APPOINTMENT OF A SUITABLY QUALIFIED AND ACCREDITED SERVICE PROVIDER FOR THE REMOVAL AND DISPOSAL OF ASBESTOS ROOF AT STRANDFONTEIN`,
      "FQ110-2024-2025",
      "2024-11-19",
      "09:35",
      "2024-11-26",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ110-2024-2025_2.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ109-2024-2025 SUPPLY AND DELIVERY OF BUILDING MATERIALS FOR MAINTENACE AT EBENHAEZER CEMETERY`,
      "FQ109-2024-2025",
      "2024-11-19",
      "09:32",
      "2024-11-26",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ109-2024-2025.pdf",
      `1`,
      `Take Note Trading`,
      `R 44 374.71`,
      `Closed`,
    ),
    new TendersModule(
      `FQ108-2024-2025 SUPPLY AND DELIVERY OF FENCING FOR KLAWER SPORTGROUNDS `,
      "FQ108-2024-2025",
      "2024-11-19",
      "09:30",
      "2024-11-26",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ108-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ107-2024-2025 SUPPLY AND DELIVERY OF CATERING SERVICES `,
      "FQ107-2024-2025",
      "2024-11-14",
      "08:56",
      "2024-11-21",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ107-2024-2025.pdf",
      `Buclabo`,
      `2`,
      `R3 314.69`,
      `Closed`,
    ),
    new TendersModule(
      `FQ106-2024-2025 LABOUR COST: FOR THE UPGRADING OF THE FISHERMEN FACILITY IN DORING BAY`,
      "FQ106-2024-2025",
      "2024-11-14",
      "08:53",
      "2024-11-28",
      "No later than 12:00",
      `22 November 2024 at 11:00 at the Fishermen Facility in Doringbay at Hawe Weg Doringbay.`,
      ``,
      "/assets/Tenders/2024-25/FQ106-2024-2025.pdf",
      `1`,
      `Cohootz Trading`,
      `R 289 685.00`,
      `Closed`,
    ),
    new TendersModule(
      `FQ105-2024-2025 APPOINTMENT OF ADVERTISING AGENCIES FOR THE PLACEMENT OF ADVERTISEMENTS IN THE PRINTED MEDIA.`,
      "FQ105-2024-2025",
      "2024-11-14",
      "08:51",
      "2024-11-21",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ105-2024-2025.pdf",
      `Ayanda Mbanga`,
      `1`,
      `price per size placing`,
      `Closed`,
    ),
    new TendersModule(
      `T12-2024-2025 HIRING OF A SUPER SUCKER VACUUM TRUCK FOR A THREE (3) YEAR PERIOD ON AN AS AND WHEN NEEDED BASIS`,
      "T12-2024-2025",
      "2024-11-13",
      "14:28",
      "2024-12-13",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/T12-2024-2025_2.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ104-2024-2025 RELOCATION/ TRANSPORTATION SERVICES REQUIRED TO MOVE HOUSEHOLD FURNITURE FORM PACALTSDORP TO VREDENDAL`,
      "FQ104-2024-2025",
      "2024-11-13",
      "11:42",
      "2024-11-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ104-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ103-2024-2025 SERVICE, REPAIR AND REFILL OF FIRE EQUIPMENT`,
      "FQ103-2024-2025",
      "2024-11-13",
      "11:39",
      "2024-11-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ103-2024-2025.pdf",
      `ESS Fire Safety Solutions`,
      `1`,
      `R 28773.00 for planned maintenance excludes service of equipment`,
      `Closed`,
    ),
      new TendersModule(
      `FQ102-2024-2025 SUPPLY AND DELIVERY OF  ALKALINE BATTERIES`,
      "FQ102-2024-2025",
      "2024-11-13",
      "11:36",
      "2024-11-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ102-2024-2025.pdf",
      `Memotek trading`,
      `1`,
      `R 12 675.30`,
      `Closed`,
    ),
    new TendersModule(
      `T11-2024-2025 VREDENDAL ROADS MAINTENANCE CIVIL WORKS- PHASE 1`,
      "T11-2024-2025",
      "2024-11-08",
      "10:24",
      "2024-12-12",
      "No later than 12:00",
      `21 November 2024 at 11:00 at the Municipal offices at Sirkelweg, Vredendal`,
      ``,
      "/assets/Tenders/2024-25/T11-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `T10-2024-2025 SUPPLY AND DELIVERY OF TWO (2) WALK BEHIND ROLLERS`,
      "T10-2024-2025",
      "2024-11-08",
      "10:21",
      "2024-11-25",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/T10-2024-2025_3.pdf",
      `ELB Equiment`,
      `1`,
      `R475 870`,
      `Closed`,
    ),
    new TendersModule(
      `FQ101-2024-2025 SUPPLY AND DELIVERY OF 100MM SUCTION HOSES TO REPLACE ON SEWAGE TRUCK`,
      "FQ101-2024-2025",
      "2024-11-07",
      "10:52",
      "2024-11-14",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ101-2024-2025.pdf",
      `Afri Mak`,
      `1`,
      `R 43 497.60`,
      `Closed`,
    ),
    new TendersModule(
      `FQ100-2024-2025 SUPPLY AND DELIVERY OF TOOLS AND EQUIPMENT FOR BUILDING MAINTENANCE`,
      "FQ100-2024-2025",
      "2024-11-07",
      "10:47",
      "2024-11-14",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ100-2024-2025.pdf",
      `Memotek trading`,
      `1`,
      `R 172  642.72`,
      `Closed`,
    ),
    new TendersModule(
      `FQ99-2024-2025 TRAINING:  CONVEYING OF DANGEROUS GOODS (US:123259)  `,
      "FQ99-2024-2025",
      "2024-11-05",
      "09:56",
      "2024-11-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ99-2024-2025.pdf",
      `SA Fire and Medical Academy`,
      `1`,
      `R27 600`,
      `Closed`,
    ),
    new TendersModule(
      `FQ98-2024-2025 TRAINING:  OPERATE OF CRANE TRUCK     (US:116254)
                     OPERATE OF CHERRY PICKER (US: 243272)
  `,
      "FQ98-2024-2025",
      "2024-11-05",
      "09:53",
      "2024-11-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ98-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ97-2024-2025 SUPPLY AND DELIVERY OF STATIONERY `,
      "FQ97-2024-2025",
      "2024-11-05",
      "09:51",
      "2024-11-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ97-2024-2025.pdf",
      `Bidvest Waltons`,
      `1`,
      `award per item`,
      `Closed`,
    ),
    new TendersModule(
      `FQ96-2024-2025 TRANSPORT SERVICES: WARD COMMITTEE TRAINING IN BITTERFONTEIN ON 30 NOVEMBER 2024  `,
      "FQ96-2024-2025",
      "2024-11-05",
      "09:49",
      "2024-11-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ96-2024-2025.pdf",
      `Hough  Transport and General Work`,
      `1`,
      `R8 500`,
      `Closed`,
    ),
    new TendersModule(
      `FQ95-2024-2025 SUPPLY AND DELIVERY OF CATERING SERVICES `,
      "FQ95-2024-2025",
      "2024-11-05",
      "09:46",
      "2024-11-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ95-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ94-2024-2025 ONLINE ACCESS TO THE NATIONAL ROAD TRAFFIC ACT (NRTA) 93/96 AND REGULATIONS`,
      "FQ94-2024-2025",
      "2024-10-31",
      "09:15",
      "2024-11-07",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ94-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ93-2024-2025 SUPPLY AND DELIVERY OF SECTION 56 SUMMONS BOOKS AND SECTION 341 NOTICE BOOKS TRIFOLD PRINTED`,
      "FQ93-2024-2025",
      "2024-10-31",
      "09:13",
      "2024-11-07",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ93-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ92-2024-2025 TRAINING:  OPERATE AN EXCAVATOR (SAQA ID 262744); TRAINING:  OPERATE A BACHOE/LOADER (SAQA ID 262727);
        TRAINING:  OPERATE A TRACED DOZER (SAQA ID 262729);
        TRAINING:  OPERATE A GRADER (SAQA ID 262735)`,
      "FQ92-2024-2025",
      "2024-10-31",
      "09:06",
      "2024-11-07",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ92-2024-2025.pdf",
      `FBT CONSULTANTS`,
      `4`,
      `R93 526.63`,
      `Closed`,
    ),
    new TendersModule(
      `FQ91-2024-2025 SUPPLY AND DELIVERY OF TOOLS AND EQUIPMENT FOR BUILDING MAINTENANCE`,
      "FQ91-2024-2025",
      "2024-10-29",
      "09:21",
      "2024-11-05",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ91-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ90-2024-2025 SUPPLY AND DELIVERY OF TWO (2) RIDE ON LAWN MOWERS`,
      "FQ90-2024-2025",
      "2024-10-29",
      "09:18",
      "2024-11-05",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ90-2024-2025.pdf",
      `Afgri Equipment`,
      `3`,
      `R299 000`,
      `Closed`,
    ),
    new TendersModule(
      `FQ89-2024-2025 SUPPLY AND DELIVERY OF BUILDING MATERIALS FOR GRAVES`,
      "FQ89-2024-2025",
      "2024-10-29",
      "09:16",
      "2024-11-05",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ89-2024-2025.pdf",
      `Buclabo`,
      `2`,
      `R169 652.26`,
      `Closed`,
    ),
    new TendersModule(
      `FQ88-2024-2025 LABOUR COST FOR BUILDING OF GRAVE PLOTS`,
      "FQ88-2024-2025",
      "2024-10-29",
      "09:14",
      "2024-11-05",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ88_2-2024-2025.pdf",
      `Cape Mountain Enterprises`,
      `1`,
      `R159 000.00`,
      `Closed`,
    ),
    new TendersModule(
      `FQ87-2024-2025 SUPPLY AND DELIVERY OF A BUSHCUTTER AND HELMET`,
      "FQ87-2024-2025",
      "2024-10-29",
      "09:11",
      "2024-11-05",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ87-2024-2025.pdf",
      `Gabriel and Michael`,
      `1`,
      `R33 716.25`,
      `Closed`,
    ),
    new TendersModule(
      `FQ86-2024-2025 ANTIVIRUS/PROTECTION LICENSING FOR MUNICIPALITY ICT SYSTEM`,
      "FQ86-2024-2025",
      "2024-10-25",
      "09:47",
      "2024-11-01",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ86-2024-2025.pdf",
      `First Technology Western Cape`,
      `1`,
      `R 256 795.00`,
      `Closed`,
    ),
    new TendersModule(
      `FQ85-2024-2025 SUPPLY AND DELIVERY OF PAINT AND MATERIAL FOR MAINTENANCE IN VANRHYNSDORP`,
      "FQ85-2024-2025",
      "2024-10-25",
      "09:45",
      "2024-11-01",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ85-2024-2025.pdf",
      `Take Note Trading`,
      `1`,
      `R13 652.80`,
      `Closed`,
    ),
    new TendersModule(
      `FQ84-2024-2025 SUPPLY AND DELIVERY OF TRAFFIC AND LAW ENFORCEMENT SAFETY EQUIPMENT`,
      "FQ84-2024-2025",
      "2024-10-21",
      "15:36",
      "2024-10-29",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ84-2024-2025.pdf",
      `Memotek Trading CC`,
      `1`,
      `R12 355.60`,
      `Closed`,
    ),
    new TendersModule(
      `FQ83-2024-2025 SUPPLY AND DELIVERY OF TONER CARTIDGES FOR TRAFFIC DEPARTMENT`,
      "FQ83-2024-2025",
      "2024-10-21",
      "15:33",
      "2024-10-29",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ83-2024-2025.pdf",
      `EMP Trading Company (Pty) Ltd`,
      `1`,
      `R11 615.00`,
      `Closed`,
    ),
    new TendersModule(
      `FQ82-2024-2025 COURIER SERVICES FOR MATZIKAMA MUNICIPALITY`,
      "FQ82-2024-2025",
      "2024-10-21",
      "15:31",
      "2024-10-29",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ82-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ81-2024-2025 APPOINTMENT OF A SERVICE PROVIDER FOR THE REPLACEMENT OF CABLES AND PUMPS AT BOREHOLES`,
      "FQ81-2024-2025",
      "2024-10-21",
      "15:29",
      "2024-10-29",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ81-2024-2025.pdf",
      `Vredendal Besproeiing`,
      `0`,
      `R81 156.60`,
      `Closed`,
    ),
    new TendersModule(
      `FQ80-2024-2025 TRAINING:  OPERATE A GRADER (SAQA ID 262735)`,
      "FQ80-2024-2025",
      "2024-10-15",
      "11:08",
      "2024-10-22",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ80-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ79-2024-2025 TRAINING:  OPERATE A TRACED DOZER (SAQA ID 262729)`,
      "FQ79-2024-2025",
      "2024-10-15",
      "11:05",
      "2024-10-22",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ79-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ78-2024-2025 TRAINING:  OPERATE A BACHOE/LOADER (SAQA ID 262727)`,
      "FQ78-2024-2025",
      "2024-10-15",
      "11:05",
      "2024-10-22",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ78-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ77-2024-2025 TRAINING:  OPERATE AN EXCAVATOR (SAQA ID 262744)`,
      "FQ77-2024-2025",
      "2024-10-15",
      "11:03",
      "2024-10-22",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ77-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ76-2024-2025 TRAINING:  OPERATE OF CHERRY PICKER (US:243272)`,
      "FQ76-2024-2025",
      "2024-10-15",
      "11:01",
      "2024-10-22",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ76-2024-2025.pdf",
      `Magas Training and development`,
      `1`,
      `R 50 600.00`,
      `Closed`,
    ),
    new TendersModule(
      `FQ75-2024-2025 TRAINING:  OPERATE OF CRANE TRUCK (US:116254) `,
      "FQ75-2024-2025",
      "2024-10-15",
      "10:59",
      "2024-10-22",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ75-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ74-2024-2025 SUPPLY AND DELIVERY OF SECTION 56 SUMMONS BOOKS AND SECTION 341 NOTICE BOOKS TRIFOLD PRINTED `,
      "FQ74-2024-2025",
      "2024-10-15",
      "10:56",
      "2024-10-22",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ74-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ73-2024-2025 SUPPLY AND DELIVERY OF ROAD MARKING PAINT AND MATERIALS `,
      "FQ73-2024-2025",
      "2024-10-11",
      "11:46",
      "2024-10-18",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ73-2024-2025.pdf",
      `Take Note Trading`,
      `1`,
      `R 99 596.90`,
      `Closed`,
    ),
    new TendersModule(
      `FQ72-2024-2025 TRANSPORT SERVICES `,
      "FQ72-2024-2025",
      "2024-10-11",
      "11:43",
      "2024-10-18",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ72-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ71-2024-2025 RELOCATION/ TRANSPORTATION SERVICES REQUIRED TO MOVE HOUSEHOLD FUNITURE FORM PACALTSDORP TO VREDENDAL`,
      "FQ71-2024-2025",
      "2024-10-01",
      "11:33",
      "2024-10-08",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ71-2024-2025.pdf",
      `Hough Transport and General Woks`,
      `1`,
      `R 8 500`,
      `Closed`,
    ),
    new TendersModule(
      `FQ70-2024-2025 APPOINTMENT OF A CONSULTANT TO HANDLE AND EVALUATE DIRECTOR POSITION`,
      "FQ70-2024-2025",
      "2024-10-01",
      "11:31",
      "2024-10-07",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ70-2024-2025.pdf",
      `Roy Steel Assosiates`,
      `4`,
      `R46 000.00`,
      `Closed`,
    ),
    new TendersModule(
      `K21-2024-2025 LEASING OF MUNICIPAL BUILDING ON ERF 382, BITTERFONTEIN`,
      "K21-2024-2025",
      "2024-09-27",
      "11:58",
      "2024-10-28",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/K21-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ69-2024-2025 SUPPLY AND DELIVERY OF SECTION 56 SUMMONS BOOKS AND SECTION 341 NOTICE BOOKS TRIFOLD PRINTED`,
      "FQ69-2024-2025",
      "2024-09-27",
      "11:57",
      "2024-10-07",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ69-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ68-2024-2025 SUPPLY AND DELIVERY OF 19KG LP GAS TO STRANDFONTEIN`,
      "FQ68-2024-2025",
      "2024-09-27",
      "11:55",
      "2024-10-07",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ68-2024-2025.pdf",
      `Memotek Trading`,
      `1`,
      `R1584.13 per 19kg Gas, R2560.36 delivery`,
      `Closed`,
    ),
    new TendersModule(
      `FQ67-2024-2025 HIRING OF A 6” DIESEL TRAILER PUMPS ON AN AS AND WHEN NEEDED BASIS FOR ONE YEAR`,
      "FQ67-2024-2025",
      "2024-09-27",
      "11:53",
      "2024-10-07",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ67-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ66-2024-2025 TRAINING:  OPERATE A GRADER (SAQA ID 262735)`,
      "FQ66-2024-2025",
      "2024-09-27",
      "11:50",
      "2024-10-07",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ66-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ65-2024-2025 TRAINING:  OPERATE A TRACED DOZER (SAQA ID 262729)`,
      "FQ65-2024-2025",
      "2024-09-27",
      "11:45",
      "2024-10-07",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ65-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ64-2024-2025 TRAINING:  OPERATE A BACHOE/LOADER (SAQA ID 262727)`,
      "FQ64-2024-2025",
      "2024-09-27",
      "11:43",
      "2024-10-07",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ64-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ63-2024-2025 TRAINING:  OPERATE AN EXCAVATOR (SAQA ID 262744)`,
      "FQ63-2024-2025",
      "2024-09-27",
      "11:40",
      "2024-10-07",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ63-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ62-2024-2025 TRAINING:  OPERATE OF CHERRY PICKER (US:243272)`,
      "FQ62-2024-2025",
      "2024-09-27",
      "11:38",
      "2024-10-07",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ62-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ61-2024-2025 TRAINING:  OPERATE OF CRANE TRUCK (US:116254)`,
      "FQ61-2024-2025",
      "2024-09-27",
      "11:35",
      "2024-10-07",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ61-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ60-2024-2025 SUPPLY AND DELIVERY OF A MULTIFIT 355 PVC X 300 VALVE`,
      "FQ60-2024-2025",
      "2024-09-27",
      "11:33",
      "2024-10-07",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ60-2024-2025.pdf",
      `Vredendal Besproeiing`,
      `0`,
      `R 17 124.65`,
      `Closed`,
    ),
    new TendersModule(
      `T09-2024-2025 SUPPLY & DELIVERY OF WATER METERS`,
      "T09-2024-2025",
      "2024-09-20",
      "11:21",
      "2024-10-21",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/T09-2024-2025.pdf",
      `KFC Engineerng, PPD Engineering, Take Note Trading`,
      `1 each`,
      `award per item`,
      `Closed`,
    ),
    new TendersModule(
      `T08-2024-2025 SUPPLY AND DELIVERY OF FIILTER SAND FOR MATZIKAMA MUNICIPAL AREA ENDING 30 JUNE 2027`,
      "T08-2024-2025",
      "2024-09-20",
      "11:17",
      "2024-10-21",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/T08-2024-2025.pdf",
      `0`,
      `Cape Silica`,
      `Price per item`,
      `Closed`,
    ),
    new TendersModule(
      `FQ59-2024-2025 SUPPLY AND DELIVERY OF SUBMERSIBLE MOTOR/PUMP CABLES`,
      "FQ59-2024-2025",
      "2024-09-19",
      "10:50",
      "2024-09-27",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ59-2024-2025.pdf",
      `Vredendal Besproeiing`,
      `0`,
      `per meter awarded`,
      `Closed`,
    ),
    new TendersModule(
      `FQ58-2024-2025 SUPPLY AND DELIVERY OF BULK WATER METER`,
      "FQ58-2024-2025",
      "2024-09-17",
      "09:59",
      "2024-09-26",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ58-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ57-2024-2025 SUPPLY, DELIVERY  AND INSTALL OF RESERVOIR VALVE AT LUTZVILLE RESERVOIR`,
      "FQ57-2024-2025",
      "2024-09-17",
      "09:56",
      "2024-10-01",
      "No later than 12:00",
      `Compulsory Site meeting	25 September 2024 at 11:00 at the Infrastructure Services Department
       Boardroom at 1 Sirkelweg Vredendal`,
      ``,
      "/assets/Tenders/2024-25/FQ57-2024-2025.pdf",
      `Vredendal Besproeiing`,
      `0`,
      `R 63 020`,
      `Closed`,
    ),
    new TendersModule(
      `FQ56-2024-2025 TRAINING:  CONVEYING OF DANGEROUS GOODS (US:123259)`,
      "FQ56-2024-2025",
      "2024-09-17",
      "09:54",
      "2024-09-26",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ56-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ55-2024-2025 SUPPLY AND DELIVERY OF STATIONERY`,
      "FQ55-2024-2025",
      "2024-09-12",
      "10:41",
      "2024-09-18",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ55-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ54-2024-2025 SUPPLY AND DELIVERY OF A VERTICAL PORTABLE EXPANSION PRESSURE VESSEL`,
      "FQ54-2024-2025",
      "2024-09-12",
      "10:39",
      "2024-09-18",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ54-2024-2025.pdf",
      `Vredendal Besproeiing`,
      `0`,
      `R 4588.50`,
      `Closed`,
    ),
    new TendersModule(
      `FQ53-2024-2025 TRAINING:  OPERATE AN EXCAVATOR (SAQA ID 262744)`,
      "FQ53-2024-2025",
      "2024-09-12",
      "10:37",
      "2024-09-18",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ53-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ52-2024-2025 TRAINING:  OPERATE A BACHOE/LOADER (SAQA ID 262727)`,
      "FQ52-2024-2025",
      "2024-09-12",
      "10:35",
      "2024-09-18",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ52-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ51-2024-2025 TRAINING:  OPERATE A TRACED DOZER (SAQA ID 262729)`,
      "FQ51-2024-2025",
      "2024-09-12",
      "10:25",
      "2024-09-18",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ51-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ50-2024-2025 TRAINING:  OPERATE A GRADER (SAQA ID 262735)`,
      "FQ50-2024-2025",
      "2024-09-12",
      "10:23",
      "2024-09-18",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ50-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ49-2024-2025 SUPPLY AND DELIVERY OF TONER CARTRIDGES FOR MATZIKAMA MUNICIPALITY`,
      "FQ49-2024-2025",
      "2024-09-10",
      "11:46",
      "2024-09-17",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ49-2024-2025.pdf",
      `Waltons`,
      `1`,
      `Award per item`,
      `Closed`,
    ),
    new TendersModule(
      `FQ48-2024-2025 SUPPLY AND DELIVERY OF 19KG LP GAS TO STRANDFONTEIN`,
      "FQ48-2024-2025",
      "2024-09-10",
      "11:43",
      "2024-09-17",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ48-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ47-2024-2025 HIRING OF A 6” DIESEL TRAILER PUMPS ON AN AS AND WHEN NEEDED BASIS FOR ONE YEAR`,
      "FQ47-2024-2025",
      "2024-09-10",
      "11:40",
      "2024-09-17",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ47-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ46-2024-2025 TRAINING:SUPERVISORY MANAGEMENT (US:252037)`,
      "FQ46-2024-2025",
      "2024-09-05",
      "15:23",
      "2024-09-13",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ46-2024-2025.pdf",
      `Lionsden Africa Business Solutions`,
      `1`,
      `R42 500`,
      `Closed`,
    ),
    new TendersModule(
      `FQ45-2024-2025 TRAINING:CONVEYING OF DANGEROUS GOODS (US:123259`,
      "FQ45-2024-2025",
      "2024-09-05",
      "15:20",
      "2024-09-13",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ45-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ44-2024-2025 SUPPLY AND DELIVERY OF PVC PIPES`,
      "FQ44-2024-2025",
      "2024-09-05",
      "15:18",
      "2024-09-13",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ44-2024-2025.pdf",
      `Vredendal Besproeiing`,
      `0`,
      `R2 227`,
      `Closed`,
    ),
    new TendersModule(
      `FQ43-2024-2025 SUPPLY AND DELIVERY OF PH BUFFER SOLUTIONS`,
      "FQ43-2024-2025",
      "2024-09-03",
      "11:55",
      "2024-09-10",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ43-2024-2025.pdf",
      `Memotek Trading`,
      `1`,
      `R9 455.76`,
      `Closed`,
    ),
    new TendersModule(
      `FQ42-2024-2025 SUPPLY AND DELIVERY OF SAFETY PROTECTIVE GLOVES`,
      "FQ42-2024-2025",
      "2024-09-03",
      "11:52",
      "2024-09-10",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ42-2024-2025.pdf",
      `Flo Specialized Product Solutions & Gabriel and Michael Marketing`,
      `1 & 1 respectively`,
      `award per item`,
      `Closed`,
    ),
    new TendersModule(
      `FQ41-2024-2025 SUPPLY AND DELIVERY OF TRAFFIC / LAW ENFORCEMENT UNIFORMS`,
      "FQ41-2024-2025",
      "2024-09-03",
      "11:50",
      "2024-09-10",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ41-2024-2025.pdf",
      `Sparks and Ellis`,
      `1`,
      `Award per item`,
      `Closed`,
    ),
    new TendersModule(
      `FQ40-2024-2025 SUPPLY AND DELIVERY OF TOOLS AND EQUIPMENT FOR MAINTENACE FOR MATZIKAMA MUNICIPALITY`,
      "FQ40-2024-2025",
      "2024-09-03",
      "11:48",
      "2024-09-10",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ40-2024-2025.pdf",
      `Take Note Trading`,
      `1`,
      `R79  508.70`,
      `Closed`,
    ),
    new TendersModule(
      `T07-2024-2025 SUPPLY & DELIVERY OF BITUMEN PRODUCTS FOR THE MATZIKAMA AREA 1 YEAR PERIOD WITH A POSSIBILITY OF A FURTHER 2 YEAR EXTENTION`,
      "T07-2024-2025",
      "2024-08-30",
      "14:39",
      "2024-09-30",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/T07-2024-2025.pdf",
      `AWV Projects`,
      `1`,
      `award per item`,
      `Closed`,
    ),
    new TendersModule(
      `T06-2024-2025 SERVICE PROVIDER FOR ISSUING OF CERTIFICATES OF COMPLIANCE FOR ELECTRICAL INSTALLATIONS`,
      "T06-2024-2025",
      "2024-08-30",
      "14:36",
      "2024-09-30",
      "No later than 12:00",
      `13 September 2024 at 11:00 at the Matzikama Council Chambers`,
      ``,
      "/assets/Tenders/2024-25/T06-2024-2025.pdf",
      `4`,
      `AC&DC Electrical`,
      `R 91 689.50`,
      `Closed`,
    ),
    new TendersModule(
      `FQ39-2024-2025 SUPPLY AND DELIVERY OF A 90 KG PLATE COMPACTOR`,
      "FQ39-2024-2025",
      "2024-08-27",
      "10:48",
      "2024-09-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ39-2024-2025.pdf",
      `Ian Dickie`,
      `4`,
      `R 32 693.35`,
      `Closed`,
    ),
    new TendersModule(
      `FQ38-2024-2025 SUPPLY AND DELIVERY OF A HEAVY DUTY SIDE TILT MIXER`,
      "FQ38-2024-2025",
      "2024-08-27",
      "10:46",
      "2024-09-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ38-2024-2025.pdf",
      `Ian Dickie`,
      `4`,
      `R 45 718.02`,
      `Closed`,
    ),
    new TendersModule(
      `FQ37-2024-2025 ANNUAL CALIBRATION OF TWO ALCOHOL BREATHALYSERS (DRAGER)`,
      "FQ37-2024-2025",
      "2024-08-27",
      "10:43",
      "2024-09-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ37-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ35-2024-2025 SUPPLY AND DELIVERY OF  GATE VALVES`,
      "FQ35-2024-2025",
      "2024-08-27",
      "10:38",
      "2024-09-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ35-2024-2025.pdf",
      `Take Note Trading`,
      `1`,
      `R 39 445.00`,
      `Closed`,
    ),
    new TendersModule(
      `FQ34-2024-2025 ASSESSMENT AND REPAIRS OF FAULTY AERATORS OF THE VREDENDAL SOUTH AND NORTH WASTE WATER TREATMENT WORKS`,
      "FQ34-2024-2025",
      "2024-08-27",
      "10:36",
      "2024-09-10",
      "No later than 12:00",
      `03 September 2024 at 11:00 at the Infrastructure Services Department Boardroom at 1 Sirkelweg Vredendal`,
      ``,
      "/assets/Tenders/2024-25/FQ34-2024-2025.pdf",
      `WHD Engineering PTY LTD`,
      `1`,
      `R 223 246.30`,
      `Closed`,
    ),
    new TendersModule(
      `T05-2024-2025 APPOINTMENT OF A SERVICE PROVIDER FOR AN AUTOMATED PERFORMANCE MANAGEMENT SYSTEM FOR THE PERIOD OF 3 YEARS`,
      "T05-2024-2025",
      "2024-08-23",
      "14:40",
      "2024-09-25",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/T05-2024-2025.pdf",
      `0`,
      `Ignite Advisory Services`,
      `Award per monthly rate`,
      `Closed`,
    ),
    new TendersModule(
      `T04-2024-2025 SUPPLY AND DELIVERY OF VARIOUS ELECTRICAL MATERIALS FOR A THREE YEAR PERIOD`,
      "T04-2024-2025",
      "2024-08-23",
      "14:37",
      "2024-09-25",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/T04-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ33-2024-2025 SUPPLY AND FIT GRADER BLADES –  CAT 140G GRADER`,
      "FQ33-2024-2025",
      "2024-08-20",
      "11:45",
      "2024-08-27",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ33-2024-2025.pdf",
      `Kabareng`,
      `1`,
      `R16 120.00`,
      `Closed`,
    ),
    new TendersModule(
      `FQ32-2024-2025 SUPPLY AND DELIVERY OF PLUMBING AND WATERLINE MATERIAL FOR REPAIR AND MAINTENANCE`,
      "FQ32-2024-2025",
      "2024-08-20",
      "11:44",
      "2024-08-27",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ32-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ31-2024-2025 HIRING OF A LOWBED ON AN AS AND WHEN NEEDED BASIS IN THE MATZIKAMA AREA`,
      "FQ31-2024-2025",
      "2024-08-20",
      "11:42",
      "2024-08-27",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ31-2024-2025.pdf",
      `Joetsie Mining`,
      `1`,
      `Rate based`,
      `Closed`,
    ),
    new TendersModule(
      `FQ30-2024-2025 SUPPLY AND DELIVERY OF ROAD TRAFFIC SIGNS`,
      "FQ30-2024-2025",
      "2024-08-20",
      "11:40",
      "2024-08-27",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ30-2024-2025.pdf",
      `Nu Way Enterprises`,
      `1`,
      `R 79 508.70`,
      `Closed`,
    ),
    new TendersModule(
      `T03-2024-2025 SUPPLY AND DELIVERY OF HEAVY MOTOR VEHICLES AND LIGHT DELIVERY FLEET VEHICLES ON RENTAL FOR A THREE YEAR PERIOD.`,
      "T03-2024-2025",
      "2024-08-16",
      "11:46",
      "2024-09-16",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/T03-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ28-2024-2025 SUPPLY AND DELIVERY OF ELECTRIC GEYSERS`,
      "FQ28-2024-2025",
      "2024-08-15",
      "11:44",
      "2024-08-22",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ28-2024-2025.pdf",
      `Lets' Trade`,
      `0`,
      `R 39 806.93`,
      `Closed`,
    ),
    new TendersModule(
      `FQ27-2024-2025 ANNUAL CALIBRATION OF TWO ALCOHOL BREATHALYSERS (DRAGER)`,
      "FQ27-2024-2025",
      "2024-08-15",
      "11:42",
      "2024-08-22",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ27-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ26-2024-2025 ONLINE ACCESS TO THE NATIONAL ROAD TRAFFIC ACT (NRTA) 93/96 AND REGULATIONS`,
      "FQ26-2024-2025",
      "2024-08-15",
      "11:39",
      "2024-08-22",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ26-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ25-2024-2025 APPOINTMENT OF A SERVICE PROVIDER FOR THE COMPILATION OF THE 2023-/2024 ANNUAL PERFORMANCE REPORT`,
      "FQ25-2024-2025",
      "2024-08-15",
      "11:37",
      "2024-08-22",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ25-2024-2025.pdf",
      `Ignite`,
      `0`,
      `R 89 700.00`,
      `Closed`,
    ),
    new TendersModule(
      `FQ24-2024-2025 HIRING OF A WALK BEHIND ROLLER FOR ROAD MAINTENACE `,
      "FQ24-2024-2025",
      "2024-08-15",
      "11:34",
      "2024-08-22",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ24-2024-2025.pdf",
      `Talis Hire Vredendal`,
      `3`,
      `R935.00 per day`,
      `Closed`,
    ),
    new TendersModule(
      `FQ23-2024-2025 SUPPLY AND DELIVERY OF BUILDING MATERIAL FOR DELIVERY IN VANRHYNSDORP`,
      "FQ23-2024-2025",
      "2024-08-15",
      "11:32",
      "2024-08-22",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ23-2024-2025.pdf",
      `Lets' Trade`,
      `0`,
      `R12 406.32`,
      `Closed`,
    ),
    new TendersModule(
      `FQ29-2024-2025 SUPPLY AND DELIVERY OF BARRIER KERBS`,
      "FQ29-2024-2025",
      "2024-08-14",
      "11:49",
      "2024-08-21",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ29-2024-2025.pdf",
      `Joetsie Mining`,
      `1`,
      `15 881.73`,
      `Closed`,
    ),
    new TendersModule(
      `FQ22-2024-2025 APPOINTMENT OF A TRAVEL AGENCY FOR MATZIKAMA MUNICIPALITY`,
      "FQ22-2024-2025",
      "2024-08-13",
      "10:59",
      "2024-08-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ22-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ21-2024-2025 TRANSPORT SERVICES`,
      "FQ21-2024-2025",
      "2024-08-13",
      "10:36",
      "2024-08-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ21-2024-2025.pdf",
      `Hough Transport`,
      `1`,
      `R6 600.00`,
      `Closed`,
    ),
    new TendersModule(
      `FQ20-2024-2025 SUPPLY AND DELIVERY OF LOW VOLTAGE CABLE AND LOW VOLTAGE MATERIALS`,
      "FQ20-2024-2025",
      "2024-08-13",
      "10:21",
      "2024-08-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ20-2024-2025.pdf",
      `Actom Electrical Products, Kader Technologies, Take Note Trading, Vredendal Besproeiing `,
      `1,1,1,0 respectively`,
      `Per item awarded`,
      `Closed`,
    ),
    new TendersModule(
      `T02-2024-2025 REPAIR OF SUBMERSIBLE SEWERAGE PUMPS FOR MATZIKAMA MUNICIPALITY FOR ONE (1) YEAR
        WITH POSSIBLE TWO (2) YEAR EXTENSION`,
      "T02-2024-2025",
      "2024-08-08",
      "09:17",
      "2024-09-09",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/T02-2024-2025.pdf",
      `Vredendal Besproeiing`,
      `0`,
      `Price per tarrif`,
      `Closed`,
    ),
    new TendersModule(
      `FQ19-2024-2025 HIRING OF A LOWBED ON AN AS AND WHEN NEEDED BASIS IN THE MATZIKAMA AREA`,
      "FQ19-2024-2025",
      "2024-08-07",
      "10:41",
      "2024-08-15",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ19-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ18-2024-2025 SUPPLY AND DELIVERY OF 100MM SUCTION HOSES TO REPLACE ON SEWAGE TRUCK`,
      "FQ18-2024-2025",
      "2024-08-07",
      "10:38",
      "2024-08-15",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ18-2024-2025.pdf",
      `Memotek Trading`,
      `1`,
      `R22 537.24`,
      `Closed`,
    ),
    new TendersModule(
      `FQ17-2024-2025 SUPPLY AND DELIVERY OF OIL COOLED 11KV AND 22KV POLE & GROUND MOUNTED DISTRIBUTION TRANSFORMERS`,
      "FQ17-2024-2025",
      "2024-08-06",
      "11:38",
      "2024-08-14",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ17-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ16-2024-2025 SUPPLY AND DELIVERY OF CHAIN SAWS AND HAND SAWS FOR TREE CUTTING`,
      "FQ16-2024-2025",
      "2024-08-06",
      "11:35",
      "2024-08-14",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ16-2024-2025.pdf",
      `Gabriel and Michael Markerting`,
      `1`,
      `Per item awarded`,
      `Closed`,
    ),
    new TendersModule(
      `FQ15-2024-2025 SUPPLY AND DELIVERY OF VEHICLE PARTS TO REPAIR SEWERAGE TRUCK`,
      "FQ15-2024-2025",
      "2024-08-02",
      "11:18",
      "2024-08-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ15-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ14-2024-2025 SUPPLY AND DELIVERY OF BARRIER KERBS`,
      "FQ14-2024-2025",
      "2024-08-02",
      "11:15",
      "2024-08-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ14-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ13-2024-2025 SUPPLY AND DELIVERY OF ROAD TRAFFIC SIGNS`,
      "FQ13-2024-2025",
      "2024-08-02",
      "11:13",
      "2024-08-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ13-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ12-2024-2025 ANNUAL CALIBRATION OF TWO ALCOHOL BREATHALYSERS (DRAGER)`,
      "FQ12-2024-2025",
      "2024-07-31",
      "11:54",
      "2024-08-07",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ12-2024-2025.pdf",
      `N/A`,
      `N/A`,
      `N/A`,
      `Award-In-Progress`,
    ),
    new TendersModule(
      `FQ11-2024-2025 SUPPLY AND DELIVERY OF BUILDING MATERIALS FOR REPAIRS AT STRANDFONTEIN`,
      "FQ11-2024-2025",
      "2024-07-30",
      "10:44",
      "2024-08-06",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ11-2024-2025.pdf",
      `Let's Trade`,
      "0",
      "R199 428.50",
      "Closed"
    ),
    new TendersModule(
      `FQ10-2024-2025 SUPPLY AND DELIVERY OF CLEANING SUPPLIES`,
      "FQ10-2024-2025",
      "2024-07-30",
      "10:41",
      "2024-08-06",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ10-2024-2025.pdf",
      `Waltons`,
      "1",
      "Per item awarded",
      "Closed"
    ),
    new TendersModule(
      `FQ09-2024-2025 SUPPLY AND DELIVERY OF BITUMEN PRODUCTS FOR ROAD MAINTENANCE`,
      "FQ09-2024-2025",
      "2024-07-23",
      "11:56",
      "2024-07-30",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ09-2024-2025.pdf",
      `Mannel Consulting`,
      "1",
      "R287 730.00",
      "Closed"
    ),
    new TendersModule(
      `FQ08-2024-2025 SUPPLY AND DELIVERY OF STAINLESS STEEL MATERIALS TO REPAIR SEWER BUSKETS`,
      "FQ08-2024-2025",
      "2024-07-23",
      "11:54",
      "2024-07-30",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ08-2024-2025.pdf",
      `Vredendal Besproeiing`,
      "0",
      "R100 662.95",
      "Closed"
    ),
    new TendersModule(
      `FQ07-2024-2025 APPOINTMENT OF SERVICE PROVIDER FOR ANNUAL CALIBRATION OF
       VEHICLE STATION TESTING EQUIPMENT`,
      "FQ07-2024-2025",
      "2024-07-18",
      "11:26",
      "2024-07-25",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ07-2024-2025.pdf",
      `N/A`,
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ06-2024-2025 SUPPLY AND DELIVERY OF NON-RETURN VALVE FOR WATERLINE`,
      "FQ06-2024-2025",
      "2024-07-18",
      "11:23",
      "2024-07-25",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ06-2024-2025.pdf",
      `Spilhaus Vredendal`,
      "0",
      "R 4 305.14",
      "Closed"
    ),
    new TendersModule(
      `FQ05-2024-2025 SUPPLY AND DELIVERY OF GAS REGULATORS AND INJECTORS`,
      "FQ05-2024-2025",
      "2024-07-18",
      "11:21",
      "2024-07-25",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ05-2024-2025.pdf",
      `Peninsula Water Treatment`,
      "1",
      "R114 540.00",
      "Closed"
    ),
    new TendersModule(
      `FQ04-2024-2025 SUPPLY AND DELIVERY OF OFFICE COPY PAPER`,
      "FQ04-2024-2025",
      "2024-07-18",
      "11:19",
      "2024-07-25",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ04-2024-2025.pdf",
      `Waltons`,
      "1",
      "Per item awarded",
      "Closed"
    ),
    new TendersModule(
      `RFP01-2024-2025 REQUEST FOR PROPOSALS (RFP) FOR THE APPOINTMENT OF SERVICE PROVIDERS
       TO MANAGE MUNICIPAL FACILITIES IN STRANDFONTEIN CHALETS AND CAMPING SITES AND VREDENDAL
       CARAVAN PARK AND SWIMMING POOLS`,
      "RFP01-2024-2025",
      "2024-07-05",
      "15:01",
      "2024-08-12",
      "No later than 12:00",
      `22 July 2024 at 09h00 at the Matzikama Municipality's Council Chambers, 37 Church Street, Vredendal, 8160.`,
      ``,
      "/assets/Tenders/2024-25/RFP01-2024-2025.pdf",
      `N/A`,
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ03-2024-2025 APPOINTMENT OF SERVICE PROVIDER FOR ANNUAL CALIBRATION OF VECHILE STATION TESTING EQUIPMENT`,
      "FQ03-2024-2025",
      "2024-07-05",
      "11:44",
      "2024-07-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ03-2024-2025_1.pdf",
      `N/A`,
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ02-2024-2025 SUPPLY AND DELIVERY OF NON-RETURN VALVE FOR WATERLINE`,
      "FQ02-2024-2025",
      "2024-07-05",
      "11:41",
      "2024-07-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ02-2024-2025_1.pdf",
      `N/A`,
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ01-2024-2025 APPOINTMENT OF SERVICE PROVIDER FOR ONLINE RESERVATION BOOKING SYSTEM`,
      "FQ01-2024-2025",
      "2024-07-05",
      "11:39",
      "2024-07-19",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/FQ01-2024-2025_1.pdf",
      `N/A`,
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `T01-2024-2025 PROPERTY VALUER FOR MATZIKAMA MUNICIPALITY`,
      "T01-2024-2025",
      "2024-07-05",
      "10:58",
      "2024-08-05",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/T01-2024-2025.pdf",
      `CDV Waardeerders`,
      "1",
      "Rate based",
      "Closed"
    ),
    new TendersModule(
      `FQ124-2023-2024 APPOINTMENT OF SERVICE PROVIDER FOR THE HANDLING OF DISCIPLINARY MATTERS`,
      "FQ124-2023-2024",
      "2024-06-21",
      "11:08",
      "2024-07-04",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ124-2023-2024.pdf",
      `AJ de Klerk`,
      "4",
      "Rate Based",
      "Closed"
    ),
    new TendersModule(
      `FQ123-2023-2024 SUPPLY AND ADMINISTRATION OF HEPITITUS B VACCINATIONS`,
      "FQ123-2023-2024",
      "2024-06-21",
      "11:53",
      "2024-06-28",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ123-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ122-2023-2024 APPOINTMENT OF SERVICE PROVIDER FOR ONLINE RESERVATION BOOKING SYSTEM`,
      "FQ122-2023-2024",
      "2024-06-21",
      "11:51",
      "2024-06-28",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ122-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ121-2023-2024 APPOINTMENT OF SERVICE PROVIDER FOR THE HANDLING OF DISCIPLINARY MATTERS`,
      "FQ121-2023-2024",
      "2024-06-19",
      "11:43",
      "2024-06-26",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ121-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ120-2023-2024 SUPPLY AND DELIVERY OF MOUNTED GAS CHLORINATORS`,
      "FQ120-2023-2024",
      "2024-06-19",
      "11:41",
      "2024-06-26",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ120-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ117-2023-2024 SUPPLY AND INSTALLATION OF ELECTRIC MOTORIZED GATES`,
      "FQ117-2023-2024",
      "2024-06-19",
      "11:36",
      "2024-06-26",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ117-2023-2024.pdf",
      "MPS Security",
      "4",
      "R14 901.00",
      "Closed"
    ),
    new TendersModule(
      `FQ119-2023-2024 APPOINTMENT OF AN ACCREDITED SERVICE PROVIDER TO PERFORM REGULATORY LOAD TESTING ON VARIOUS LIFTING MACHINERY & EQUIPMENT`,
      "FQ119-2023-2024",
      "2024-06-14",
      "11:09",
      "2024-06-24",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ119-2023-2024.pdf",
      "Superior Cranes and Services",
      "1",
      "Rate per item tested",
      "Closed"
    ),
    new TendersModule(
      `FQ118-2023-2024 SUPPLY AND DELIVERY OF MATERIALS FOR MAINTENACE AND REPAIRS AT STRANDFONTEIN CAMPING SITE`,
      "FQ118-2023-2024",
      "2024-06-14",
      "11:08",
      "2024-06-24",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ118-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ116-2023-2024 SUPPLY, DELIVERY AND INSTALLATION OF CONTROL ROOM EQUIPMENT`,
      "FQ116-2023-2024",
      "2024-06-11",
      "15:34",
      "2024-06-19",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ116-2023-2024.pdf",
      "MPS Security",
      "4",
      "R 57 534.00",
      "Closed"
    ),
    new TendersModule(
      `FQ115-2023-2024 SUPPLY AND DELIVERY OF ELECTRICAL MATERIAL FOR EUREKA LIBRARY`,
      "FQ115-2023-2024",
      "2024-06-11",
      "15:33",
      "2024-06-19",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ115-2023-2024.pdf",
      "Vredendal Besproeiing",
      "0",
      "R 13 189.29",
      "Closed"
    ),
    new TendersModule(
      `FQ114-2023-2024 SUPPLY, DELIVERY AND INSTALLATION OF SURVEILLANCE CAMERAS FOR VARIOUS LOCATIONS IN THE MATZIKAMA AREA`,
      "FQ114-2023-2024",
      "2024-06-07",
      "10:20",
      "2024-06-14",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ114-2023-2024.pdf",
      "CJJ Business Solutions",
      "0",
      "R 294 712 .00",
      "Closed"
    ),
    new TendersModule(
      `FQ113-2023-2024 APPOINTMENT OF A SERVICE PROVIDER FOR THE HANDLING OF DISCIPLINARY MATTERS`,
      "FQ113-2023-2024",
      "2024-06-07",
      "10:16",
      "2024-06-14",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ113-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `T41-2023-2024 SUPPLY, DELIVERY, INSTALLATION AND COMMISSIONING OF 22Kv AUTO-RECLOSER`,
      "T41-2023-2024",
      "2024-06-06",
      "11:29",
      "2024-06-21",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T41-2023-2024.pdf",
      "Lesedi Reticulation",
      "1",
      "R452 985.00",
      "Closed"
    ),
    new TendersModule(
      `FQ112-2023-2024 SUPPLY AND DELIVERY OF MATERIAL FOR WATER LINE REPAIRS`,
      "FQ112-2023-2024",
      "2024-06-03",
      "10:18",
      "2024-06-10",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ112-2023-2024.pdf",
      "Memotek Trading",
      "1",
      "R 106 219.22",
      "Closed"
    ),
    new TendersModule(
      `FQ111-2023-2024 SUPPLY AND DELIVERY OF SAFETY GLOVES`,
      "FQ111-2023-2024",
      "2024-06-03",
      "10:15",
      "2024-06-10",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ111-2023-2024.pdf",
      "Vredendal Besproeiing",
      "0",
      "R 4 508.00",
      "Closed"
    ),
    new TendersModule(
      `T40-2023-2024 APPOINTMENT OF LAND SURVEYOR: SURVEYING OF REMAINDER PORTION 13 OF FARM 270, HOLLEBAKSTRANDFONTEIN`,
      "T40-2023-2024",
      "2024-05-31",
      "11:42",
      "2024-07-01",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T40-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ110-2023-2024 HIRING OF MOBILE 3INCH TRASH PUMPS ON AN AS AND WHEN NEEDED BASIS TILL JUNE 2025`,
      "FQ110-2023-2024",
      "2024-05-23",
      "11:46",
      "2024-05-31",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ110-2023-2024.pdf",
      "Talisman",
      "3",
      "R 400 per day",
      "Closed"
    ),
    new TendersModule(
      `FQ109-2023-2024 PUMPING OF RAW SEWER WATER AT KAYSHA SEWER PUMPSTATION IN VREDENDAL NORTH AND CLEANING OF OVERFLOWED SURROUNDING AREA`,
      "FQ109-2023-2024",
      "2024-05-22",
      "11:54",
      "2024-06-03",
      "No later than 12:00",
      `28 May 2024 at 10:00 at the Matzikama Technical Service Department in the boardroom`,
      ``,
      "/assets/Tenders/2023-24/FQ109-2023-2024.pdf",
      "RDB Building and Construction",
      "1",
      "R 149 500.00",
      "Closed"
    ),
    new TendersModule(
      `FQ108-2023-2024 SUPPLY AND DELIVERY OF BARCODE SCANNER AND LABLE PRINTER`,
      "FQ108-2023-2024",
      "2024-05-22",
      "11:52",
      "2024-05-30",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ108-2023-2024.pdf",
      "CHM Vuwani",
      "1",
      "R 36 046.75",
      "Closed"
    ),
    new TendersModule(
      `FQ107-2023-2024 SUPPLY AND ADMINISTRATION OF HEPITITUS B VACCINATIONS`,
      "FQ107-2023-2024",
      "2024-05-22",
      "09:49",
      "2024-05-30",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ107-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ106-2023-2024 SUPPLY AND DELIVERY OF BUILDING MATERIAL FOR RENOVATIONS AT THE DORINGBAY “VLEKPLEK”`,
      "FQ106-2023-2024",
      "2024-05-17",
      "11:15",
      "2024-05-24",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ106-2023-2024.pdf",
      "Let's Trade",
      "0",
      "R 281 040.52",
      "Closed"
    ),
    new TendersModule(
      `FQ105-2023-2024 HIRING OF A ROLLER FOR ROAD MAINTENANCE`,
      "FQ105-2023-2024",
      "2024-05-17",
      "11:12",
      "2024-05-24",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ105-2023-2024 Cancelled.pdf",
      "FQ Cancelled",
      "N/A",
      "N/A",
      "Not Awarded"
    ),
    new TendersModule(
      `FQ104-2023-2024 SUPPLY AND DELIVERY OF FILTERBAGS`,
      "FQ104-2023-2024",
      "2024-05-13",
      "10:06",
      "2024-05-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ104-2023-2024.pdf",
      "RDB Building and Construction",
      "1",
      "R 30 360",
      "Closed"
    ),
    new TendersModule(
      `FQ103-2023-2024 SUPPLY AND DELIVERY OF 1000L DIESEL BOWSER TRAILER`,
      "FQ103-2023-2024",
      "2024-05-13",
      "10:02",
      "2024-05-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ103-2023-2024.pdf",
      "Kader Atechnologie",
      "1",
      "R 85 488.70",
      "Closed"
    ),
    new TendersModule(
      `FQ102-2023-2024 SUPPLY AND DELIVERY OF DRAIN ROD SETS`,
      "FQ102-2023-2024",
      "2024-05-13",
      "09:59",
      "2024-05-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ102-2023-2024.pdf",
      "RDB Building and Construction",
      "1",
      "R 12 075.00",
      "Closed"
    ),
    new TendersModule(
      `FQ101-2023-2024 APPOINTMENT OF SERVICE PROVIDER FOR ONLINE RESERVATION BOOKING SYSTEM`,
      "FQ101-2023-2024",
      "2024-05-13",
      "09:59",
      "2024-05-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ101-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ100-2023-2024 SUPPLY AND DELIVERY OF BITUMEN PRODUCTS`,
      "FQ100-2023-2024",
      "2024-05-13",
      "09:59",
      "2024-05-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ100-2023-2024.pdf",
      "Mannel Consulting",
      "1",
      "R 185 552.50",
      "Closed"
    ),
    new TendersModule(
      `FQ99-2023-2024 SUPPLY AND DELIVERY OF ANTISCALANTS`,
      "FQ99-2023-2024",
      "2024-05-13",
      "09:57",
      "2024-05-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ99-2023-2024.pdf",
      "Memotek Trading",
      "1",
      "R 23 630.20",
      "Closed"
    ),
    new TendersModule(
      `FQ98-2023-2024 SUPPLY AND DELIVERY OF FIRS AID KITS`,
      "FQ98-2023-2024",
      "2024-05-13",
      "09:55",
      "2024-05-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ98-2023-2024.pdf",
      "Andrew Boswell",
      "1",
      "R 8500.48",
      "Closed"
    ),
    new TendersModule(
      `FQ97-2023-2024 SUPPLY AND DELIVERY OF TRAFFIC SAFETY EQUIPMENT`,
      "FQ97-2023-2024",
      "2024-05-13",
      "09:51",
      "2024-05-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ97-2023-2024.pdf",
      "Memotek Trading",
      "1",
      "R 30 649.11",
      "Closed"
    ),
    new TendersModule(
      `FQ96-2023-2024 APPOINTMENT OF A CONSULTANT TO HANDLE AND EVALUATE THE DIRECTOR TECHNICAL SERVICE POSITION`,
      "FQ96-2023-2024",
      "2024-05-09",
      "11:15",
      "2024-05-17",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ96-2023-2024.pdf",
      "Roy Steel and Associates",
      "4",
      "17 250.00",
      "Closed"
    ),
    new TendersModule(
      `FQ95-2023-2024 SUPPLY AND DELIVERY OF TOILET PAPER`,
      "FQ95-2023-2024",
      "2024-05-09",
      "15:35",
      "2024-05-17",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ95-2023-2024.pdf",
      "Waltons",
      "1",
      "R 14 032.12",
      "Closed"
    ),
    new TendersModule(
      `FQ94-2023-2024 SUPPLY AND DELIVERY OF SOFTSHELL JACKETS`,
      "FQ94-2023-2024",
      "2024-05-08",
      "11:15",
      "2024-05-15",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ94-2023-2024.pdf",
      "Select PPE",
      "1",
      "R 24 995.39",
      "Closed"
    ),
    new TendersModule(
      `FQ93-2023-2024 PUMPING OF RAW SEWER WATER AT KAYSHA SEWER PUMPSTATION IN VREDENDAL NORTH AND CLEANING OF OVERFLOWED SURROUNDING AREA`,
      "FQ93-2023-2024",
      "2024-05-08",
      "11:12",
      "2024-05-15",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ93-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `T39-2023-2024 SUPPLY AND DELIVERY OF CHEMICALS ENDING 30 JUNE 2025`,
      "T39-2023-2024",
      "2024-05-03",
      "09:53",
      "2024-06-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T39-2023-2024.pdf",
      "Chemcape,Chemtoll,2ACS Services",
      "1,2,1 respectively",
      `Sodium hydroxide solution (concentration 47%) R 718.75 per poly can (25 liters).
       Sodium hydroxide solution (concentration 12%) R 575.00 per ply can (25 liters).
       Calsium hypochlorite 68% concentration (chips) R 2 472.50 per drum (25 kg),
       Sodium Hypochlorite (concentration 15%) R 707.25 per poly can (25 liters)
       Soda ash (sodium carbonate) dense R 552.00 (per 25kg bag),
       Calsium hypochlorite (granules) R 1 983.75 (per 25kg drum)
       Calsium hypochlorite 66% concentration R 2 132.39 (per 25kg)`,
      "Closed"
    ),
    new TendersModule(
      `T38-2023-2024 SUPPLY AND DELIVERY OF CHLORINE GAS ENDING 30 JUNE 2027`,
      "T38-2023-2024",
      "2024-05-03",
      "09:50",
      "2024-06-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T38-2023-2024.pdf",
      "Chlorcape",
      "2",
      `First year the amount per 24x70 kg cylinder will be R 65 591.40 per month.Second year
       the amount per 24x70 kg cylinder will be R 73 164.84 per month.Third year the amount per
       24x70 kg cylinder will be R 81 916.80 per month.`,
      "Closed"
    ),
    new TendersModule(
      `T37-2023-2024 SUPPLY AND DELIVERY OF FIILTER SAND ENDING 30 JUNE 2027`,
      "T37-2023-2024",
      "2024-05-03",
      "09:48",
      "2024-06-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T37-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `T36-2023-2024 ANALYTICAL ANALYSIS OF WATER AND SEWER WATER SAMPLES ENDING 30 JUNE 2027`,
      "T36-2023-2024",
      "2024-05-03",
      "09:45",
      "2024-06-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T36-2023-2024.pdf",
      "A.L. Abbott and Associates",
      "2",
      `The total cost for the water analysis per year is R 1 058.00 (VAT inclusive) and R 586.00 (VAT inclusive)
      for sewerage analysis (excluding re-testing of individual parameters).The amount for the sludge for the three
      year period is R 2000.00 (VAT inclusive) per test for the three year period duration`,
      "Closed"
    ),
    new TendersModule(
      `T35-2023-2024 VEHICLE TRACKING SYSTEM FOR A PERIOD OF 36 MONTHS`,
      "T35-2023-2024",
      "2024-05-03",
      "09:42",
      "2024-06-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T35-2023-2024.pdf",
      "RealM Track",
      "2",
      "R 17 232.75 per month",
      "Closed"
    ),
    new TendersModule(
      `T34-2023-2024 PANEL OF LEGAL PRACTICIONERS FOR A THREE (3) YEAR PERIOD`,
      "T34-2023-2024",
      "2024-05-03",
      "09:39",
      "2024-06-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T34-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `T33-2023-2024 SUPPLY AND DELIVERY OF SUBMERSIBLE WASTEWATER PUMPS FOR A ONE YEAR PERIOD`,
      "T33-2023-2024",
      "2024-05-03",
      "09:36",
      "2024-06-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T33-2023-2024.pdf",
      "Hidro-Tech Systems",
      "1",
      "Unit Price",
      "Closed"
    ),
    new TendersModule(
      `T32-2023-2024 SUPPLY, DELIVERY, INSTALLATION AND COMMISSIONING OF 22Kv AUTO-RECLOSER`,
      "T32-2023-2024",
      "2024-05-03",
      "09:34",
      "2024-05-17",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T32-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `T31-2023-2024 CASH-IN-TRANSIT SERVICES FOR A PERIOD ENDING 30 JUNE 2027`,
      "T31-2023-2024",
      "2024-05-03",
      "09:31",
      "2024-06-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T31-2023-2024.pdf",
      "Fidelity Services Group",
      "1",
      "R 56 929.60 per month",
      "Closed"
    ),
    new TendersModule(
      `FQ92-2023-2024 SUPPLY AND DELIVERY OF MATERIALS FOR WATER NETWORK REPAIRS`,
      "FQ92-2023-2024",
      "2024-04-30",
      "11:39",
      "2024-05-08",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ92-2023-2024.pdf",
      "Vredendal Besproeiing",
      "0",
      "R 15 989.28",
      "Closed"
    ),
    new TendersModule(
      `FQ91-2023-2024 SUPPLY AND DELIVERY OF ROAD TRAFFIC SIGN`,
      "FQ91-2023-2024",
      "2024-04-30",
      "11:37",
      "2024-05-08",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ91-2023-2024.pdf",
      "Datri 73cc T/A Vredendal signs",
      "4",
      "R 12 564.44",
      "Closed"
    ),
    new TendersModule(
      `K42-2024 SALE OF MUNICIPAL PROPERTY`,
      "Download Award List",
      "2024-04-26",
      "11:45",
      "2024-05-24",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/K42 Award.pdf",
      "Download attached list",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ90-2023-2024 SUPPLY, DELIVERY AND INSTALLATION OF SURVEILLANCE CAMERAS FOR VARIOUS LOCATIONS IN THE MATZIKAMA AREA`,
      "FQ90-2023-2024",
      "2024-04-26",
      "11:43",
      "2024-05-03",
      "No later than 12:00",
      `3 May 2024 at 11:15 in the Matzikama Municipality’s Council Chambers`,
      ``,
      "/assets/Tenders/2023-24/FQ90-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ89-2023-2024 SUPPLY, DELIVERY AND INSTALLATION OF CONTROL ROOM EQUIPMENT`,
      "FQ89-2023-2024",
      "2024-04-26",
      "11:41",
      "2024-05-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ89-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ88-2023-2024 SUPPLY AND DELIVERY OF BUILDING MATERIAL FOR RENOVATIONS AT THE EBENHAESER LIBRARY`,
      "FQ88-2023-2024",
      "2024-04-25",
      "11:31",
      "2024-05-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ88-2023-2024.pdf",
      "Let's Trade",
      "0",
      "R 150 831.35",
      "Closed"
    ),
    new TendersModule(
      `FQ87-2023-2024 SUPPLY AND INSTALLATION OF AN AERATOR: MOTOR AND GEARBOX`,
      "FQ87-2023-2024",
      "2024-04-25",
      "11:27",
      "2024-05-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ87-2023-2024.pdf",
      "WHD Engineering",
      "1",
      "R 281 544.84",
      "Closed"
    ),
    new TendersModule(
      `FQ86-2023-2024 SUPPLY AND DELIVERY OF DRAIN ROD SETS`,
      "FQ86-2023-2024",
      "2024-04-25",
      "11:27",
      "2024-05-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ86-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ85-2023-2024 ONLINE ACCESS TO THE NATIONAL ROAD TRAFFIC ACT (NRTA) 93/96 AND REGULATIONS`,
      "FQ85-2023-2024",
      "2024-04-25",
      "11:25",
      "2024-05-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ85-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ84-2023-2024 APPOINTMENT OF A SERVICE PROVIDER FOR THE EXECUTION OF PAUPER BURIAL FOR MATZIKAMA MUNICIAPLITY`,
      "FQ84-2023-2024",
      "2024-04-24",
      "11:15",
      "2024-05-02",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ84-2023-2024.pdf",
      "Avbob Funeral Services Limited",
      "1",
      "Award per line item",
      "Closed"
    ),
    new TendersModule(
      `FQ83-2023-2024 SUPPLY AND DELIVERY OF BARRIER KERBS`,
      "FQ83-2023-2024",
      "2024-04-19",
      "11:36",
      "2024-04-26",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ83-2023-2024.pdf",
      "Joetsie Mining",
      "1",
      "R 7704.54",
      "Closed"
    ),
    new TendersModule(
      `FQ82-2023-2024 SUPPLY AND DELIVERY OF BLACK PLASTIC BAGS`,
      "FQ82-2023-2024",
      "2024-04-19",
      "10:47",
      "2024-04-26",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ82-2023-2024.pdf",
      "AWV Project Management",
      "0",
      "R 72 238.40",
      "Closed"
    ),
    new TendersModule(
      `FQ81-2023-2024 SUPPLY AND DELIVERY OF OFFICE FURNITURE`,
      "FQ81-2023-2024",
      "2024-04-19",
      "10:42",
      "2024-04-26",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ81-2023-2024.pdf",
      "Top's Meubels",
      "0",
      "R 69 349.90",
      "Closed"
    ),
    new TendersModule(
      `FQ80-2023-2024 SUPPLY AND DELIVERY OF TYRES`,
      "FQ80-2023-2024",
      "2024-04-19",
      "10:42",
      "2024-04-26",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ80-2023-2024.pdf",
      "Hi-Q Vredendal",
      "4",
      "R 125 902.00",
      "Closed"
    ),
    new TendersModule(
      `FQ79-2023-2024 SUPPLY AND ADMINISTRATION OF HEPITITUS B VACCINATIONS`,
      "FQ79-2023-2024",
      "2024-04-19",
      "10:42",
      "2024-04-26",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ79-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ78-2023-2024 SUPPLY AND DELIVERY OF pH METERS`,
      "FQ78-2023-2024",
      "2024-04-19",
      "10:41",
      "2024-04-26",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ78-2023-2024.pdf",
      "Memotek Trading",
      "1",
      "R 49 074.41",
      "Closed"
    ),
    new TendersModule(
      `FQ77-2023-2024 RENOVATIONS AT EUREKA LIBRARY IN VREDENDAL NORTH`,
      "FQ77-2023-2024",
      "2024-04-19",
      "10:38",
      "2024-05-06",
      "No later than 12:00",
      `29 April 2024 at 11:00 at the Eureka Library Vredendal North`,
      ``,
      "/assets/Tenders/2023-24/FQ77-2023-2024.pdf",
      "Sew Plumbing",
      "1",
      "R 290 000.00",
      "Closed"
    ),
    new TendersModule(
      `FQ76-2023-2024 SUPPLY AND DELIVERY OF VARIOUS SOFT STARTERS (11KW, 24KW,132KW)`,
      "FQ76-2023-2024",
      "2024-04-19",
      "10:35",
      "2024-04-26",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ76-2023-2024.pdf",
      "Kader Technologies",
      "1",
      "R 45 716.96",
      "Closed"
    ),
    new TendersModule(
      `FQ75-2023-2024 SUPPLY AND DELIVERY OF LAPTOPS`,
      "FQ75-2023-2024",
      "2024-04-16",
      "10:44",
      "2024-04-24",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ75-2023-2024.pdf",
      "CHM Vuwani",
      "1",
      "Award per item",
      "Closed"
    ),
    new TendersModule(
      `FQ74-2023-2024 SUPPLY AND DELIVERY OF MOUNTED GAS CHLORINATORS`,
      "FQ74-2023-2024",
      "2024-04-16",
      "10:42",
      "2024-04-24",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ74-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ73-2023-2024 SUPPLY AND DELIVERY OF FIRST AID BOXES AND AIDS`,
      "FQ73-2023-2024",
      "2024-04-10",
      "11:41",
      "2024-04-17",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ73-2023-2024.pdf",
      "Bidvest Waltons",
      "1",
      "R 22 245.01",
      "Closed"
    ),
    new TendersModule(
      `T30-2023-2024 SUPPLY AND DELIVERY OF MOBILE DIESEL GENERATOR UNITS`,
      "T30-2023-2024",
      "2024-04-05",
      "12:29",
      "2024-04-19",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T30-2023-2024.pdf",
      "AK Electrical Consultants",
      "1",
      "Rate based tender",
      "Closed"
    ),
    new TendersModule(
      `T29-2023-2024 SUPPLY AND DELIVERY OF NEW TYRES, TUBES, SPRING REPAIRS AND SILENCERS TO MATZIKAMA MUNICIPALITY FOR A PERIOD OF THREE YEARS`,
      "T29-2023-2024",
      "2024-04-05",
      "12:26",
      "2024-05-07",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T29-2023-2024.pdf",
      "Exhaust Doctor",
      "0",
      "Unit Price",
      "Closed"
    ),
    new TendersModule(
      `T28-2023-2024 APPOINTMENT OF A PANEL OF SERVICE PROVIDERS FOR MECHANICAL REPAIRS AND MAINTENANCE OF MUNICIPAL PLANT, FLEET AND EQUIPMENT FOR A PERIOD OF THREE YEARS`,
      "T28-2023-2024",
      "2024-04-05",
      "12:24",
      "2024-05-07",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T28-2023-2024.pdf",
      "Matzikama Service Centre,Boebies Body Works,Lutzville Motors and Towing",
      "4,1,4",
      "Per hour rate",
      "Closed"
    ),
    new TendersModule(
      `FQ72-2023-2024 SUPPLY AND DELIVERY OF BUILDING MATERIAL FOR RENOVATIONS AT THE EBENHAESER LIBRARY`,
      "FQ72-2023-2024",
      "2024-04-04",
      "11:43",
      "2024-04-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ72-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ71-2023-2024 SUPPLY AND DELIVERY OF SMALL PLANT EQUIPMENT`,
      "FQ71-2023-2024",
      "2024-04-04",
      "11:40",
      "2024-04-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ71-2023-2024.pdf",
      "Memotek Trading",
      "1",
      "R 116 263.32",
      "Closed"
    ),
    new TendersModule(
      `FQ70-2023-2024 APPOINTMENT OF A SERVICE PROVIDER FOR MAINTENANCE OF WATER INFRASTRUCTE PUMPS AND MOTORS`,
      "FQ70-2023-2024",
      "2024-04-04",
      "11:38",
      "2024-04-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ70-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ69-2023-2024 SUPPLY AND DELIVERY OF STREET LIGHT FITTINGS AND AIRDAC CABLE`,
      "FQ69-2023-2024",
      "2024-04-04",
      "11:36",
      "2024-04-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ69-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ68-2023-2024 SUPPLY AND DELIVERY OF LUBRICATION OIL AND GREASE`,
      "FQ68-2023-2024",
      "2024-04-04",
      "11:33",
      "2024-04-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ68-2023-2024.pdf",
      "Afri Mak",
      "1",
      "Award per item",
      "Closed"
    ),
    new TendersModule(
      `FQ67-2023-2024 APPOINTMENT OF A TRAVEL AGENCY FOR MATZIKAMA MUNICIPALITY`,
      "FQ67-2023-2024",
      "2024-04-02",
      "11:50",
      "2024-04-09",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ67-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ66-2023-2024 SUPPLY AND DELIVERY OF SMART ELECTRICITY METERS`,
      "FQ66-2023-2024",
      "2024-03-20",
      "09:35",
      "2024-04-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ66-2023-2024.pdf",
      "Ontec",
      "1",
      "Award per item",
      "Closed"
    ),
    new TendersModule(
      `FQ65-2023-2024 SUPPLY AND DELIVERY OF TRAFFIC SAFETY EQUIPMENT`,
      "FQ65-2023-2024",
      "2024-03-20",
      "09:32",
      "2024-03-28",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ65-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `T27-2023-2024 SHORT-TERM INSURANCE FOR MATZIKAMA MUNICIPALITY FOR A PERIOD OF THREE (3) YEARS`,
      "T27-2023-2024",
      "2024-03-15",
      "11:43",
      "2024-04-15",
      "No later than 12:00",
      `26 March 2024 at 11:00 at Restant 421, Kus Road, Doring Bay`,
      ``,
      "/assets/Tenders/2023-24/T27-2023-2024.pdf",
      "Opulentia Financial Services",
      "1",
      "R 99 364.39 p/m, First Year",
      "Closed"
    ),
    new TendersModule(
      `T26-2023-2024 UPGRADING OF “VLEKPLEK” AREA IN DORING BAY FOR THE FISHERMAN`,
      "T26-2023-2024",
      "2024-03-15",
      "11:41",
      "2024-04-05",
      "No later than 12:00",
      `26 March 2024 at 11:00 at Restant 421, Kus Road, Doring Bay`,
      ``,
      "/assets/Tenders/2023-24/T26-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Not Awarded"
    ),
    new TendersModule(
      `T25-2023-2024 AUTOMATED METER READING SERVICES & SUPPLY & DELIVERY OF METERS AND METERING MATERIALS`,
      "T25-2023-2024",
      "2024-03-15",
      "11:39",
      "2024-04-15",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T25-2023-2024.pdf",
      "Ontec Systems",
      "1",
      "Rate Based",
      "Closed"
    ),
    new TendersModule(
      `T24-2023-2024 UPGRADING OF ROADS AND STORMWATER INFRASTRUCTURE IN VREDENDAL NORTH, PHASE 5`,
      "T24-2023-2024",
      "2024-03-15",
      "11:36",
      "2024-04-15",
      "No later than 12:00",
      `25 March 2024 at 11:00 at the Matzikama Council Chambers, 37 Church Street, Vredendal`,
      ``,
      "/assets/Tenders/2023-24/T24-2023-2024.pdf",
      "PR Civils",
      "2",
      "R19 517 579.65",
      "Closed"
    ),
    new TendersModule(
      `T23-2023-2024 PROVISION OF ANIMAL CARE, POUND, AND CONTROL RELATED SERVICES FOR MATZIKAMA MUNICIPALITY FOR 3 YEARS`,
      "T23-2023-2024",
      "2024-03-15",
      "11:34",
      "2024-04-15",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T23-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Not Awarded"
    ),
    new TendersModule(
      `T22-2023-2024 PROCUREMENT AND INSTALLATION OF BACKUP SERVERS`,
      "T22-2023-2024",
      "2024-03-15",
      "11:31",
      "2024-04-05",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T22-2023-2024.pdf",
      "First Technology",
      "1",
      "R 1 067 657.99",
      "Closed"
    ),
    new TendersModule(
      `FQ64-2023-2024 APPOINTMENT OF A SERVICE PROVIDER FOR THE FULL SANS 241:2015 WATER ANALYSIS FOR MATZIKAMA MUNICIPALITY`,
      "FQ64-2023-2024",
      "2024-03-15",
      "11:29",
      "2024-03-27",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ64-2023-2024.pdf",
      "A L Abbot",
      "2",
      "R 80 500",
      "Closed"
    ),
    new TendersModule(
      `FQ63-2023-2024 SUPPLY AND DELIVERY OF A THREE PHASE INDUCTION MOTOR- SQUIRREL CAGE`,
      "FQ63-2023-2024",
      "2024-03-15",
      "11:27",
      "2024-03-27",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ63-2023-2024.pdf",
      "Vredendal Besproeiing",
      "0",
      "R 126 500",
      "Closed"
    ),
    new TendersModule(
      `FQ62-2023-2024 SUPPLY AND DELIVERY OF A FILLING CABINET`,
      "FQ62-2023-2024",
      "2024-03-15",
      "11:24",
      "2024-03-27",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ62-2023-2024.pdf",
      "Bidvest Waltons",
      "1",
      "R 3349",
      "Closed"
    ),
    new TendersModule(
      `FQ61-2023-2024 SUPPLY AND DELIVERY OF BUILDING MATERIAL FOR MAINTENANCE`,
      "FQ61-2023-2024",
      "2024-03-15",
      "11:22",
      "2024-03-27",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ61-2023-2024.pdf",
      "Let's Trade",
      "0",
      "R 104 819.27",
      "Closed"
    ),
    new TendersModule(
      `FQ60-2023-2024 SUPPLY AND DELIVERY OF GARDEN TOOLS`,
      "FQ60-2023-2024",
      "2024-03-15",
      "11:21",
      "2024-03-27",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ60-2023-2024.pdf",
      "Lets Trade",
      "2",
      "R 23 602.31",
      "Closed"
    ),
    new TendersModule(
      `FQ59-2023-2024 APPOINTMENT OF A SUITABLE QUALIFIED SERVICE PROVIDER FOR THE SERVICE & MAINTENANCE OF DIESEL GENERATORS`,
      "FQ59-2023-2024",
      "2024-03-15",
      "11:19",
      "2024-03-27",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ59-2023-2024.pdf",
      "Flo Specialised Product Solutions",
      "1",
      "price per item line awarded",
      "Closed"
    ),
    new TendersModule(
      `FQ58-2023-2024 SUPPLY AND DELIVERY OF SMALL PLANT EQUIPMENT`,
      "FQ58-2023-2024",
      "2024-03-13",
      "11:47",
      "2024-03-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ58-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ57-2023-2024 SUPPLY AND DELIVERY OF VARIOUS ELECTRICAL EQUIPMENT`,
      "FQ57-2023-2024",
      "2024-03-13",
      "11:45",
      "2024-03-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ57-2023-2024.pdf",
      "Flo Specialised Product Solutions",
      "1",
      "R 164 944.50",
      "Closed"
    ),
    new TendersModule(
      `FQ56-2023-2024 APPOINTMENT OF A QUALIFIED PROFFESSIONAL LAND SURVEYOR`,
      "FQ56-2023-2024",
      "2024-03-12",
      "10:03",
      "2024-03-19",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ56-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ55-2023-2024 SUPPLY AND DELIVERY OF PLUMBING AND WATERLINE MATERIAL FOR REPAIR AND MAINTENANCE`,
      "FQ55-2023-2024",
      "2024-03-12",
      "10:01",
      "2024-03-19",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ55-2023-2024.pdf",
      "Nolada",
      "1",
      "R 141 703.00",
      "Closed"
    ),
    new TendersModule(
      `FQ54-2023-2024 APPOINTMENT OF A SUITABLY QUALIFIED AND ACCREDITED SERVICE PROVIDER FOR THE REMOVAL AND DISPOSAL OF ASBESTOS ROOF`,
      "FQ54-2023-2024",
      "2024-03-12",
      "09:59",
      "2024-03-19",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ54-2023-2024.pdf",
      "Cohoots Trading",
      "0",
      "R 112 256.00",
      "Closed"
    ),
    new TendersModule(
      `FQ53-2023-2024 ONLINE ACCESS TO THE NATIONAL ROAD TRAFFIC ACT (NRTA) 93/96 AND REGULATIONS`,
      "FQ53-2023-2024",
      "2024-03-12",
      "09:56",
      "2024-03-19",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ53-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ52-2023-2024 APPOINTMENT OF A SUITABLE QAULIFIED SERVICE PROVIDER FOR THE SERVICE AND MAINTENANCE OF DIESEL GENERATORS`,
      "FQ52-2023-2024",
      "2024-03-07",
      "11:49",
      "2024-03-13",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ52-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ51-2023-2024 SUPPLY AND DELIVERY OF A L29P ALCAD BATTERIES`,
      "FQ51-2023-2024",
      "2024-03-06",
      "11:33",
      "2024-03-13",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ51-2023-2024.pdf",
      "Actom",
      "1",
      "R 27 727.90",
      "Closed"
    ),
    new TendersModule(
      `FQ50-2023-2024 SUPPLY AND DELIVERY OF AIR CONDITIONERS`,
      "FQ50-2023-2024",
      "2024-03-06",
      "11:31",
      "2024-03-13",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ50-2023-2024.pdf",
      "Vredendal Besproeiing",
      "0",
      "R 71 702.50",
      "Closed"
    ),
    new TendersModule(
      `FQ49-2023-2024 SUPPLY AND DELIVERY OF MATERIAL FOR RENOVATIONS AT THE EBENHAESER LIBRARY`,
      "FQ49-2023-2024",
      "2024-03-06",
      "11:29",
      "2024-03-13",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ49-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ47-2023-2024 SUPPLY AND DELIVERY OF CLEANING DETERGENTS AND MATERIALS`,
      "FQ47-2023-2024",
      "2024-03-06",
      "11:26",
      "2024-03-13",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ47-2023-2024.pdf",
      "Bidvest Waltons",
      "1",
      "R 67 673.88",
      "Closed"
    ),
    new TendersModule(
      `FQ45-2023-2024 SUPPLY AND DELIVERY OF STATIONARY`,
      "FQ45-2023-2024",
      "2024-03-06",
      "11:24",
      "2024-03-13",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ45-2023-2024.pdf",
      "Bidvest Waltons",
      "1",
      "price per item",
      "Closed"
    ),
    new TendersModule(
      `FQ46-2023-2024 HIRING OF A SUPER SUCKER TRUCK`,
      "FQ46-2023-2024",
      "2024-03-04",
      "10:05",
      "2024-03-11",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ46-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ43-2023-2024 APPOINTMENT OF ADVERTISING AGENCIES FOR THE PLACEMENT OF ADVERTISEMENTS IN THE PRINTED MEDIA`,
      "FQ43-2023-2024",
      "2024-02-27",
      "10:50",
      "2024-03-05",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ43-2023-2024.pdf",
      "Ayanda Mbanga",
      "1",
      "R 300 000.00",
      "Closed"
    ),
    new TendersModule(
      `FQ42-2023-2024 SUPPLY AND DELIVERY OF ROADMARKING PAINT`,
      "FQ42-2023-2024",
      "2024-02-26",
      "11:37",
      "2024-03-04",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ42-2023-2024.pdf",
      "Vredendal Verwe/Painters Choice",
      "4",
      "R 55 124.96",
      "Closed"
    ),
    new TendersModule(
      `FQ41-2023-2024 SUPPLY AND DELIVERY OF TWO (2) WALK BEHIND ROLLERS FOR ROAD MAINTENANCE`,
      "FQ41-2023-2024",
      "2024-02-26",
      "11:33",
      "2024-03-04",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ41-2023-2024.pdf",
      "Memotek Trading",
      "1",
      "R 218 872.60",
      "Closed"
    ),
    new TendersModule(
      `FQ40-2023-2024 SUPPLY AND DELIVERY OF PC PARTS FOR REPAIR AND MAINTENANCE`,
      "FQ40-2023-2024",
      "2024-02-26",
      "11:31",
      "2024-03-04",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ40-2023-2024.pdf",
      "Memotek",
      "1",
      "R 67 589.66",
      "Closed"
    ),
    new TendersModule(
      `FQ39-2023-2024 TRAINING: FIRST AID`,
      "FQ39-2023-2024",
      "2024-02-22",
      "11:49",
      "2024-02-29",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ39-2023-2024.pdf",
      "Al Aydarus Service",
      "1",
      "R 13 900",
      "Closed"
    ),
    new TendersModule(
      `FQ38-2023-2024 TRAINING: HANDLING AND STORAGE OF DANGEROUS GOODS`,
      "FQ38-2023-2024",
      "2024-02-22",
      "11:44",
      "2024-02-29",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ38-2023-2024.pdf",
      "Al Aydarus Service",
      "1",
      "R 13 900",
      "Closed"
    ),
    new TendersModule(
      `FQ37-2023-2024 TRAINING: SHE REPRESENTATIVE`,
      "FQ37-2023-2024",
      "2024-02-22",
      "11:44",
      "2024-02-29",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ37-2023-2024.pdf",
      "Al Aydarus Service",
      "1",
      "R 14 875.00",
      "Closed"
    ),
    new TendersModule(
      `FQ36-2023-2024 SUPPLY AND DELIVERY OF MATERIAL FOR THE REPLACEMENT OF THE ROOF AT EBENHAESER LIBRARY`,
      "FQ36-2023-2024",
      "2024-02-22",
      "11:42",
      "2024-02-29",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ36-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Not Awarded"
    ),
    new TendersModule(
      `FQ35-2023-2024 SUPPLY AND DELIVERY OF BUILDING MATERIAL FOR THE RENOVATIONS AT EUREKA LIBRARY`,
      "FQ35-2023-2024",
      "2024-02-22",
      "11:39",
      "2024-02-29",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ35-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Not Awarded"
    ),
    new TendersModule(
      `FQ34-2023-2024 SUPPLY AND DELIVERY OF THREE (3) DOORS`,
      "FQ34-2023-2024",
      "2024-02-22",
      "11:37",
      "2024-02-29",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ34-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ33-2023-2024 SUPPLY AND DELIVERY OF COPY PAPER`,
      "FQ33-2023-2024",
      "2024-02-16",
      "10:11",
      "2024-02-23",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ33-2023-2024.pdf",
      "Bidvest Waltons",
      "1",
      "Awarded per unit price",
      "Closed"
    ),
    new TendersModule(
      `FQ32-2023-2024 APPOINTMENT OF A CONSULTANT TO HANDLE AND EVALUATE THE DIRECTOR TECHNICAL SERVICE POSITION`,
      "FQ32-2023-2024",
      "2024-02-16",
      "10:07",
      "2024-02-23",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ32-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ31-2023-2024 APPOINTMENT OF A SERVICE PROVIDER FOR THE VERIFICATION OF QUALIFICATIONS`,
      "FQ31-2023-2024",
      "2024-02-14",
      "11:26",
      "2024-02-21",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ31-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ30-2023-2024 ONLINE ACCESS TO THE NATIONAL ROAD TRAFFIC ACT (NRTA) 93/96 AND REGULATIONS`,
      "FQ30-2023-2024",
      "2024-02-14",
      "11:24",
      "2024-02-21",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ30-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ29-2023-2024 SUPPLY AND DELIVERY OF ADDITIONAL LOW VOLTAGE CABLES MATERIALS`,
      "FQ29-2023-2024",
      "2024-02-06",
      "10:09",
      "2024-02-14",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ29-2023-2024.pdf",
      "Kader Technologies",
      "R 211 835.96",
      "1",
      "Closed"
    ),
    new TendersModule(
      `FQ28-2023-2024 SUPPLY AND DELIVERY OF LDV VEHICLES ON A MONTH-TO-MONTH HIRING BASIS`,
      "FQ28-2023-2024",
      "2024-02-06",
      "10:07",
      "2024-02-14",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ28-2023-2024.pdf",
      "Europcar",
      "R 134 828.30",
      "1",
      "Closed"
    ),
    new TendersModule(
      `K26-2023-2024 SALE OF MUNICIPAL PROPERTY`,
      "Download Award List",
      "2024-02-02",
      "07:48",
      "2024-03-01",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2024-25/K26 Award.pdf",
      "Download list of awards",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `T21-2023-2024 SUPPLY AND DELIVERY OF A 75KW PUMP-SET`,
      "T21-2023-2024",
      "2024-01-24",
      "11:14",
      "2024-02-09",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T21-2023-2024.pdf",
      "Vredendal Besproeiing",
      "0",
      "R 622 035.00",
      "Closed"
    ),
    new TendersModule(
      `FQ27-2023-2024 CONTRACTOR SERVICE REQUIRED TO REPAIR PROCLAIMED ROAD MMR 548 IN KLAWER`,
      "FQ27-2023-2024",
      "2024-01-19",
      "18:35",
      "2024-02-05",
      "No later than 12:00",
      `Klawer Community Hall  on 29 January 2024 at 11:00`,
      ``,
      "/assets/Tenders/2023-24/FQ27-2023-2024.pdf",
      "Lisekho Civils",
      "1",
      "R 298 232.00",
      "Closed"
    ),
    new TendersModule(
      `FQ26-2023-2024 SUPPLY AND DELIVERY OF LOW VOLTAGE CABLES`,
      "FQ26-2023-2024",
      "2024-01-19",
      "11:35",
      "2024-01-26",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ26-2023-2024.pdf",
      "ARB Electrical Wholesalers",
      "1",
      "R 78 443.80",
      "Closed"
    ),
    new TendersModule(
      `FQ25-2023-2024 SUPPLY AND DELIVERY OF OIL VOOLED 11KV & 22KV POLE MOUNTED DISTRIBUTION TRANSFORMERS`,
      "FQ25-2023-2024",
      "2023-11-23",
      "11:42",
      "2023-11-30",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ25-2023-2024.pdf",
      "Actom Electrical Products",
      "1",
      "R 195 500.00",
      "Closed"
    ),
    new TendersModule(
      `FQ24-2023-2024 SUPPLY AND DELIVERY OF A WATER PUMP WITH MOTOR FOR VREDENAL- NORTH`,
      "FQ24-2023-2024",
      "2023-11-21",
      "11:37",
      "2023-11-27",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ24-2023-2024.pdf",
      "Diphororo Consulting",
      "1",
      "R 158 642.50",
      "Closed"
    ),
    new TendersModule(
      `FQ23-2023-2024 SUPPLY AND DELIVERY OF FILTER SAND`,
      "FQ23-2023-2024",
      "2023-11-21",
      "11:34",
      "2023-11-27",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ23-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ22-2023-2024 SUPPLY AND DELIVERY OF LOW VOLTAGE CABLES`,
      "FQ22-2023-2024",
      "2023-11-16",
      "11:23",
      "2023-11-23",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ22-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ21-2023-2024 SUPPLY AND DELIVERY OF A ROLLER`,
      "FQ21-2023-2024",
      "2023-11-16",
      "11:21",
      "2023-11-23",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ21-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `T20-2023-2024 PROVISION OF SERVICES AS AN IMPLEMENTING AGENT FOR SPECIFIED HUMAN SETTLEMENT	PROJECTS IN THE MUNICIPAL AREA`,
      "T20-2023-2024",
      "2023-11-14",
      "11:37",
      "2023-12-14",
      "No later than 12:00",
      `29 November 2023 at 11:00 at the Matzikama Council Chambers.`,
      ``,
      "/assets/Tenders/2023-24/T20-2023-2024.pdf",
      `Asla & Power Construction`,
      `1 respectively`,
      `0% project management fee mark-up measured against a total project value of R 1 000 000`,
      "Closed"
    ),
    new TendersModule(
      `T19-2023-2024 SUPPLY AND DELIVERY OF HEAVY AND CONSTRUCTION VEHICLES FOR MATZIKAMA MUNICIPALITY`,
      "T19-2023-2024",
      "2023-11-14",
      "11:34",
      "2023-12-14",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T19-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Not Awarded"
    ),
    new TendersModule(
      `T18-2023-2024 SUPPLY AND DELIVERY OF SEWER JET TRAILER FOR MATZIKAMA MUNICIPALITY`,
      "T18-2023-2024",
      "2023-11-14",
      "11:32",
      "2023-12-14",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T18-2023-2024.pdf",
      "Memotek Trading",
      "1",
      "R 704 909.52",
      "Closed"
    ),
    new TendersModule(
      `T17-2023-2024 SUPPLY AND DELIVERY OF FLEET VEHICLES ON RENTAL FOR MATZIKAMA MUNICIPALITY`,
      "T17-2023-2024",
      "2023-11-14",
      "11:30",
      "2023-12-14",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T17-2023-2024.pdf",
      "Standard Bank",
      "1",
      "R231 572.16 per month",
      "Closed"
    ),
    new TendersModule(
      `T16-2023-2024 FINANCE LEASE OF R 7 760 000 TO FINANCE MUNICIPAL VEHICLES TO MATZIKAMA MUNICIPALITY`,
      "T16-2023-2024",
      "2023-11-14",
      "11:29",
      "2023-12-14",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T16-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Not Awarded"
    ),
    new TendersModule(
      `T15-2023-2024 SUPPLY AND DELIVERY OF ROAD STONE AND ROAD BUILDING MATERIAL FOR ROAD MAINTENANCE IN THE MATZIKAMA AREA FOR A PERIOD OF ONE (1) YEAR `,
      "T15-2023-2024",
      "2023-11-10",
      "11:41",
      "2023-12-11",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T15-2023-2024.pdf",
      "Tru Tru Trading",
      "1",
      "R 2 378.20 per 34 ton",
      "Closed"
    ),
    new TendersModule(
      `T14-2023-2024 APPOINTMENT OF AN ACCREDITED SERVICE PROVIDER TO RENDER SERVICES REGARDING THE VERIFICATION OF QUALIFICATIONS FOR MATZIKAMA
       MUNICIPALITY FOR A PERIOD OF THIRTY-SIX (36) MONTHS `,
      "T14-2023-2024",
      "2023-11-10",
      "11:38",
      "2023-12-11",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T14-2023-2024.pdf",
      "Octali Institute",
      "2",
      "Awarded per unit price",
      "Closed"
    ),
    new TendersModule(
      `T13-2023-2024 PURCHASE AND DELIVERY OF ADDITIONAL PROTECTIVE/SAFETY CLOTHING THROUGH A 1 YEAR CONTRACT WITH A POSSIBILITYOF A FURTHER 2 YEAR
       EXTENTION DEPENDING ON PERFORMANCE IN THE FIST YEAR.`,
      "T13-2023-2024",
      "2023-11-10",
      "11:36",
      "2023-12-11",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T13-2023-2024.pdf",
      "Blackbird Trading, Safepro, Martin All You Need",
      "1 respectively",
      "Tender is awarded per item line",
      "Closed"
    ),
    new TendersModule(
      `T12-2023-2024 SUPPLY AND DELIVERY OF VARIOUS LOW VOLTAGE MATERIALS FOR A THREE (3) YEAR PERIOD`,
      "T12-2023-2024",
      "2023-11-10",
      "11:33",
      "2023-12-11",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/T12-2023-2024.pdf",
      "Actom, ARB Electrical, South Ocean Electric Wire, Kader Technologies, Zonart Labour Distributions ",
      "1 respectively",
      "Awarded per unit price",
      "Closed"
    ),
    new TendersModule(
      `FQ20-2023-2024 SUPPLY AND DELIVERY OF WATER METERS`,
      "FQ20-2023-2024",
      "2023-11-06",
      "10:56",
      "2023-11-14",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ20-2023-2024.pdf",
      "PPD Engineering and Hardware Suppliers",
      "1",
      "R 200 000.00",
      "Closed"
    ),
    new TendersModule(
      `FQ19-2023-2024 SUPPLY AND DELIVERY OF TONER CARTRIDGES FOR MATZIKAMA MUNICIPALITY`,
      "FQ19-2023-2024",
      "2023-11-06",
      "10:51",
      "2023-11-14",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ19-2023-2024.pdf",
      "CHM Vuwani",
      "1",
      "R 98 922.00",
      "Closed"
    ),
    new TendersModule(
      `FQ18-2023-2024 HIRING OF A CRANE TRUCK AND CHERRY PICKER- AS AND WHEN REQUIRED IN THE MATZIKAMA AREA`,
      "FQ18-2023-2024",
      "2023-10-25",
      "09:42",
      "2023-11-01",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ18-2023-2024.pdf",
      "N1 Hydraulics",
      "0",
      "price per kilometres travelled and rate per hour",
      "Closed"
    ),
    new TendersModule(
      `FQ17-2023-2024 SUPPLY AND DELIVERY OF OIL COOLED 11KV & 22KV POLE MOUNTED DISTRIBURION TRANSFORMERS`,
      "FQ17-2023-2024",
      "2023-10-25",
      "09:40",
      "2023-11-01",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ17-2023-2024.pdf",
      "Vredendal Besproeiing",
      "0",
      "R 149 260.80",
      "Closed"
    ),
    new TendersModule(
      `FQ16-2023-2024 APPOINTMENT OF A CONSULTANT TO HANDLE AND EVALUATE THE DIRECTOR CORPORATE AND DIRECTOR TECHNICAL SERVICE POSITIONS`,
      "FQ16-2023-2024",
      "2023-10-25",
      "09:37",
      "2023-11-01",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ16-2023-2024.pdf",
      "Roy Steele and Associates",
      "4",
      "R 92 000.00",
      "Closed"
    ),
    new TendersModule(
      `FQ15-2023-2024 SERVICES REQUIRED FOR THE REPAIRS OF CLARIFIER EQUIPMENT AT THE VREDENDAL-NORTH AND VREDENDAL
      SOUTH WASTE WATER TREATMENT `,
      "FQ15-2023-2024",
      "2023-10-16",
      "09:42",
      "2023-10-27",
      "No later than 12:00",
      `FQ is cancelled`,
      ``,
      "/assets/Tenders/2023-24/FQ15 Cancellation.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Not Awarded"
    ),
    new TendersModule(
      `FQ14-2023-2024 SUPPLY AND DELIVERY OF WATER METERS `,
      "FQ14-2023-2024",
      "2023-10-16",
      "09:38",
      "2023-10-27",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ14-2023-2024.pdf",
      "Vredendal Besproeiing",
      "0",
      "R 37 605.00",
      "Closed"
    ),
    new TendersModule(
      `FQ13-2023-2024 HIRING OF DOZER FOR MATZIKAMA MUNICIPALITY `,
      "FQ13-2023-2024",
      "2023-10-16",
      "09:36",
      "2023-10-27",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ13-2023-2024.pdf",
      "Diphororo Consulting",
      "1",
      "price per hourly rate for dozer and per lowbed site estabishment",
      "Closed"
    ),
    new TendersModule(
      `FQ12-2023-2024 SUPPLY AND DELIVERY OF SHELVING FOR BULK STORAGE `,
      "FQ12-2023-2024",
      "2023-10-12",
      "11:47",
      "2023-10-23",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ12-2023-2024.pdf",
      "Univeral Storage System SA (PTY) Ltd",
      "0",
      "R 129 039.08",
      "Closed"
    ),
    new TendersModule(
      `FQ11-2023-2024 ANTIVIRUS/PROTECTION LISENSING FOR MUNICIPALITY ICT SYSTEM `,
      "FQ11-2023-2024",
      "2023-10-06",
      "11:05",
      "2023-10-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ11-2023-2024.pdf",
      "CJJ Business Solutions",
      "4",
      "R 89 700.00",
      "Closed"
    ),
    new TendersModule(
      `FQ10-2023-2024 SUPPLY AND DELIVERY OF TOOLS FOR MAINTENACE FOR MATZIKAMA MUNICIPALITY `,
      "FQ10-2023-2024",
      "2023-10-06",
      "11:02",
      "2023-10-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2023-24/FQ10-2023-2024.pdf",
      "Nolada8",
      "1",
      "price per Item line awarded",
      "Closed"
    ),
    new TendersModule(
      `T11-2023-2024 UPGRADING OF "VLEKPLEK" AREA IN DORING BAY FOR THE FISHERMAN`,
      "T11-2023-2024",
      "2023-09-29",
      "11:46",
      "2023-10-20",
      "No later than 12:00",
      `09 October 2023 at 11:00 at Restant 421, Kus Road, Doring Bay `,
      ``,
      "/assets/Tenders/2023-24/T11-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Not Awarded"
    ),
    new TendersModule(
      `T10-2023-2024 ELECTRIFICATION OF INFORMAL HOUSES IN VREDENDAL NORTH `,
      "T10-2023-2024",
      "2023-09-22",
      "10:36",
      "2023-10-17",
      "No later than 12:00",
      `4 October 2023 at 11:00 at the Municipal Offices at 1 Sirkelweg, Vredendal  `,
      ``,
      "/assets/Tenders/2023-24/T10-2023-2024.pdf",
      "Anchor Powerlines",
      "1",
      "R 4 091 397.73",
      "Closed"
    ),
    new TendersModule(
      `T09-2023-2024 UPGRADING OF ROADS AND STORMWATER INFRASTRUCTURE IN EBENHAESER, PHASE 2 `,
      "T09-2023-2024",
      "2023-09-22",
      "10:34",
      "2023-10-23",
      "No later than 12:00",
      `5 October 2023 at 11:00 at the Community Hall, 118 Louis Street, Ebenhaeser`,
      ``,
      "/assets/Tenders/2023-24/T09-2023-2024.pdf",
      "Westland Construction (PTY) LTD",
      "1",
      "R11 983 127.17",
      "Closed"
    ),
    new TendersModule(
      `FQ09-2023-2024 HIRING OF A LOWBED ON AN AS AND WHEN NEEDED BASIS IN THE MATZIKAMA AREA `,
      "FQ09-2023-2024",
      "2023-09-22",
      "10:32",
      "2023-10-03",
      "No later than 12:00",
      ` `,
      ``,
      "/assets/Tenders/2023-24/FQ09-2023-2024.pdf",
      "Tru Tru Trading",
      "1",
      "price per trip line awarded",
      "Closed"
    ),
    new TendersModule(
      `FQ08-2023-2024 SUPPLY AND DELIVERY OF FENCING FOR LUTZVILLE WWTW `,
      "FQ08-2023-2024",
      "2023-09-19",
      "11:32",
      "2023-09-28",
      "No later than 12:00",
      ` `,
      ``,
      "/assets/Tenders/2023-24/FQ08-2023-2024.pdf",
      "Pietjie Eiman",
      "1",
      "R31 499.9",
      "Closed"
    ),
    new TendersModule(
      `FQ07-2023-2024 SUPPLY AND DELIVERY OF BOREHOLE PUMPS`,
      "FQ07-2023-2024",
      "2023-09-07",
      "11:35",
      "2023-09-15",
      "No later than 12:00",
      ` `,
      ``,
      "/assets/Tenders/2023-24/FQ07-2023-2024.pdf",
      "Diphororo Consulting & Afri Mak",
      "1 & 1 Respectively",
      "R 77 326.00 & R 7 705.00 Respectively",
      "Closed"
    ),
    new TendersModule(
      `FQ06-2023-2024 SUPPLY AND DELIVERY OF TRAFFIC / LAW ENFORCEMENT UNIFORMS`,
      "FQ06-2023-2024",
      "2023-09-07",
      "11:32",
      "2023-09-15",
      "No later than 12:00",
      ` `,
      ``,
      "/assets/Tenders/2023-24/FQ06-2023-2024.pdf",
      "Sparks and Ellis",
      "1",
      "price per item line awarded",
      "Closed"
    ),
    new TendersModule(
      `K09-2023-2024 SALE OF MUNICIPAL PROPERTY `,
      "K09-2023-2024",
      "2023-08-25",
      "11:27",
      "2023-09-29",
      "No later than 12:00",
      ` `,
      ``,
      "/assets/Tenders/2023-24/K9-23-24_2 Awards.pdf",
      "See attached document",
      "N/A",
      "See attached document",
      "Closed"
    ),
    new TendersModule(
      `T08-2023-2024 APPOINTMENT OF A PANEL OF LEGAL PRACTICIONERS FOR THE RENDERING OF LEGAL SERVICES FOR A THREE (3) YEAR PERIOD `,
      "T08-2023-2024",
      "2023-08-25",
      "11:23",
      "2023-09-27",
      "No later than 12:00",
      ` `,
      ``,
      "/assets/Tenders/2023-24/T08-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Not Awarded"
    ),
    new TendersModule(
      `T07-2023-2024 PROVISION OF ANIMAL CARE, POUND, AND CONTROL RELATED SERVICES FOR MATZIKAMA MUNICIPALITY FOR A
        THREE (3) YEAR PERIOD.`,
      "T07-2023-2024",
      "2023-08-25",
      "11:19",
      "2023-09-27",
      "No later than 12:00",
      ` `,
      ``,
      "/assets/Tenders/2023-24/T07-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Not Awarded"
    ),
    new TendersModule(
      `T06-2023-2024 PRIVATISATION OF MATZIKAMA MUNICIPAL VEHICLE TESTING STATION IN VREDENDAL:
      APPOINTMENT OF A PRIVATE SERVICE PROVIDER FOR A PERIOD OF NINE (9) YEARS AND ELEVEN (11) MONTHS`,
      "T06-2023-2024",
      "2023-08-25",
      "11:15",
      "2023-09-27",
      "No later than 12:00",
      ` `,
      ``,
      "/assets/Tenders/2023-24/T06-2023-2024.pdf",
      "VTS Support Services",
      "1",
      "R40 000.00 per month",
      "Closed"
    ),
    new TendersModule(
      `FQ05-2023-2024 APPOINTMENT OF A SERVICE PROVIDER FOR THE VERIFICATION OF QUALIFICATIONS`,
      "FQ05-2023-2024",
      "2023-08-25",
      "11:10",
      "2023-09-06",
      "No later than 12:00",
      ` `,
      ``,
      "/assets/Tenders/2023-24/FQ05-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `T05-2023-2024 SUPPLY AND DELIVERY OF PROTECTIVE SAFETY CLOTHING AND EQUIPMENT FOR A PERIOD OF ONE (1)
       YEAR WITH THE POSSIBILITY TO EXENT FOR AN EXTRA TWO (2) YEARS BASED ON PERFORMANCE`,
      "T05-2023-2024",
      "2023-08-11",
      "11:29",
      "2023-09-11",
      "No later than 12:00",
      ` `,
      ``,
      "/assets/Tenders/2023-24/T05-2023-2024.pdf",
      "Safepro & Select PPE",
      "1",
      "price per item line awarded",
      "Closed"
    ),
    new TendersModule(
      `T04-2023-2024 SUPPLY AND DELIVERY OF CHEMICALS FOR MATZIKAMA AREA FOR A PERIOD ENDING 30 JUNE 2024`,
      "T04-2023-2024",
      "2023-08-11",
      "11:26",
      "2023-09-11",
      "No later than 12:00",
      ` `,
      ``,
      "/assets/Tenders/2023-24/T04-2023-2024.pdf",
      "Chemcape",
      "2",
      "Prices was awared per item",
      "Closed"
    ),
    new TendersModule(
      `T03-2023-2024 LIFE INSURANCE SERVICES FOR MATZIKAMA MUNICIPALITY FOR A 3 YEAR PERIOD`,
      "T03-2023-2024",
      "2023-08-11",
      "11:22",
      "2023-09-11",
      "No later than 12:00",
      ` `,
      ``,
      "/assets/Tenders/2023-24/T03-2023-2024.pdf",
      "Old Mutual Corp Consultants",
      "1",
      "Prices is R0.301 per 1000",
      "Closed"
    ),
    new TendersModule(
      `FQ04-2023-2024 SUPPLY AND DELIVERY OF AIRDAC CABLES AND MEDIUM VOLTAGE FUSE CARRIER`,
      "FQ04-2023-2024",
      "2023-08-08",
      "10:02",
      "2023-08-17",
      "No later than 12:00",
      ` `,
      ``,
      "/assets/Tenders/2023-24/FQ04-2023-2024.pdf",
      "Take Note Trading",
      "1",
      "R 69 688.85",
      "Closed"
    ),
    new TendersModule(
      `FQ03-2023-2024 SUPPLY AND DELIVERY OF A TRASH PUMP/WATER PUMP 80MM 3" FOR MATZIKAMA MUNICIPALITY`,
      "FQ03-2023-2024",
      "2023-08-03",
      "07:55",
      "2023-08-11",
      "No later than 12:00",
      ` `,
      ``,
      "/assets/Tenders/2023-24/FQ03-2023-2024.pdf",
      "Afrimak (PTY) LTD",
      "1",
      "R138 144.90",
      "Closed"
    ),
    new TendersModule(
      `FQ02-2023-2024 SUPPLY AND DELIVERY OF TRAFFIC/LAW ENFORCEMENT UNIFORMS`,
      "FQ02-2023-2024",
      "2023-08-03",
      "07:58",
      "2023-08-11",
      "No later than 12:00",
      ` `,
      ``,
      "/assets/Tenders/2023-24/FQ02-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `T02-2023-2024 UPGRADING OF ROADS AND STORMWATER INFRASTRUCTURE IN VREDENDAL NORTH, PHASE 5 AND COMPLETION OF PH1.3 OF UITKYK ROADS PROJECT IN LUTZVILLE`,
      "T02-2023-2024",
      "2023-07-28",
      "10:06",
      "2023-08-28",
      "No later than 12:00",
      ` `,
      ``,
      "/assets/Tenders/2023-24/T02-2023-2024.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Not Awarded"
    ),
    new TendersModule(
      `T01-2023-2024 SUPPLY AND DELIVERY OF MEDIUM VOLTAGE MATERIALS (AS & WHEN REQUIRED) FOR A PERIOD OF THREE-YEARS `,
      "T01-2023-2024",
      "2023-07-21",
      "09:44",
      "2023-08-21",
      "No later than 12:00",
      ` `,
      ``,
      "/assets/Tenders/2023-24/T01-2023-2024.pdf",
      "ARB Electrical",
      "1",
      "Prices was awared per item",
      "Closed"
    ),
    new TendersModule(
      `FQ01-2023-2024 APPOINTMENT OF A CONSULTANT TO HANDLE AND EVALUATE THE MUNICIPAL MANAGER AND DIRECTOR POSITIONS `,
      "FQ01-2023-2024",
      "2023-07-20",
      "11:42",
      "2023-07-27",
      "No later than 12:00",
      ` `,
      ``,
      "/assets/Tenders/2023-24/FQ01-2023-2024.pdf",
      "Roy Steele",
      "4",
      "R189 750.00",
      "Closed"
    )
  ];

  archivedTenders:TendersModule[] = [
    new TendersModule(
      `FQ50-2022-2023 APPOINTMENT OF PROFESSIONAL CONSULTING ENGINEERS FOR CONSTRUCTION OF TWO NETBALL COURTS IN DORING BAY `,
      "FQ50-2022-2023",
      "2023-06-07",
      "09:29",
      "2023-06-14",
      "No later than 12:00",
      ` `,
      ``,
      "/assets/Tenders/2022-23/FQ50-2022-2023.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ49-2022-2023 SUPPLY AND DELIVERY OF MOULDED CASE CIRCUIT BREAKERS FOR MATZIKAMA MUNICIPALITY `,
      "FQ49-2022-2023",
      "2023-05-26",
      "11:52",
      "2023-06-02",
      "No later than 12:00",
      ` `,
      ``,
      "/assets/Tenders/2022-23/FQ49-2022-2023.pdf",
      "Ikapa Express Auctireers",
      "1",
      "10,35% commission",
      "Closed"
    ),
    new TendersModule(
      `FQ48-2022-2023 SUPPLY AND DELIVERY OF SURGE WAVE RECEIVER FOR MATZIKAMA MUNICIPALITY `,
      "FQ48-2022-2023",
      "2023-05-26",
      "11:49",
      "2023-06-02",
      "No later than 12:00",
      ` `,
      ``,
      "/assets/Tenders/2022-23/FQ48-2022-2023.pdf",
      "Flo Specialized Product Solutions",
      "1",
      "R 91 367.50",
      "Closed"
    ),
    new TendersModule(
      `FQ47-2022-2023 SUPPLY AND DELIVERY OF CHEMICALS (CALCLUIM HYPOLCLORITE TABLETTS) FOR MATZIKAMA MUNICIPALITY `,
      "FQ47-2022-2023",
      "2023-05-17",
      "11:37",
      "2023-05-26",
      "No later than 12:00",
      ` `,
      ``,
      "/assets/Tenders/2022-23/FQ47-2022-2023.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ46-2022-2023 APPOINTMENT OF AN AUCTIONEER `,
      "FQ46-2022-2023",
      "2023-05-17",
      "11:36",
      "2023-05-26",
      "No later than 12:00",
      ` `,
      ``,
      "/assets/Tenders/2022-23/FQ46-2022-2023.pdf",
      "Ikapa Express Auctioneers",
      "1",
      "10.35%",
      "Closed"
    ),
    new TendersModule(
      `FQ45-2022-2023 BUILDING PLANS FOR REPLACEMENT OF ROOF AT EBENHAEZER LIBRARY `,
      "FQ45-2022-2023",
      "2023-05-17",
      "11:33",
      "2023-05-29",
      "No later than 12:00",
      `24 May 2023 at 14:00 at Ebenhaezer Library, Main Road , Ebenhaezer.`,
      ``,
      "/assets/Tenders/2022-23/FQ45-2022-2023.pdf",
      "Noordweste Glas",
      "4",
      "R 15 800",
      "Closed"
    ),
    new TendersModule(
      `FQ44-2022-2023 BUILDING PLANS FOR RENOVATIONS AT EUREKA LIBRARY, VREDENDAL NORTH `,
      "FQ44-2022-2023",
      "2023-05-17",
      "11:31",
      "2023-05-29",
      "No later than 12:00",
      `24 May 2023 at 11:00 at Eureka Library, Vredendal North, AAS Le Fleur Street.`,
      ``,
      "/assets/Tenders/2022-23/FQ44-2022-2023.pdf",
      "Noordweste Glas",
      "4",
      "R8600",
      "Closed"
    ),
    new TendersModule(
      `FQ43-2022-2023 REPAIR TO PORTION OF DAMAGED ROOF AT ELECTRICAL DEPOT, VREDENDAL`,
      "FQ43-2022-2023",
      "2023-05-12",
      "10:10",
      "2023-06-12",
      "No later than 12:00",
      `18 May 2023 at 11:00 at Matzikama Municipality’s Electrical Depot Vredendal, Voortrekker Street.`,
      ``,
      "/assets/Tenders/2022-23/FQ43-2022-2023.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `SUPPLY AND DELIVERY OF THREE-PHASE STAND-BY GENERATORS`,
      "SUPPLY AND DELIVERY OF THREE-PHASE STAND-BY GENERATORS",
      "2023-05-12",
      "09:10",
      "2023-05-17",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/Supply and Delivery of Three-Phase Generators.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"

    ),
    new TendersModule(
      `T27-2022-23 SUPPLY AND DELIVERY OF PLUMBING AND ENGINEERING SUPPLIES`,
      "T27-2022-23",
      "2023-05-11",
      "14:25",
      "2023-06-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/T27-2022-23.pdf",
      "Take Note Trading",
      "1",
      "Prices was awared per item",
      "Closed"
    ),
    new TendersModule(
      `T26-2022-23 VEGETATION CONTROL FOR POWER LINE SERVITUDES`,
      "T26-2022-23",
      "2023-05-11",
      "14:25",
      "2023-06-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/T26-2022-23.pdf",
      "Ngcungu Trading",
      "1",
      "R2 587.04 unit price per item",
      "Closed"
    ),
    new TendersModule(
      `T25-2022-23 HIRING OF CRANE TRUCK, CHERRY PICKER AND TLB `,
      "T25-2022-23",
      "2023-05-11",
      "14:23",
      "2023-06-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/T25-2022-23.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `T24-2022-23 SUPPLY AND DELIVERY OF STREET LIGHTING EQUIPMENT `,
      "T24-2022-23",
      "2023-05-11",
      "14:20",
      "2023-06-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/T24-2022-23.pdf",
      "Kader Technologies, Vredendal Besproeiing, Antley Distributors, Memotec Trading ",
      "1,0,1,1 respectively",
      "price per item line awarded",
      "Closed"
    ),
    new TendersModule(
      `T23-2022-23 SUPPLY AND DELIVERY OF VARIOUS ELECTRICAL TESTERS AND EQUIPMENT `,
      "T23-2022-23",
      "2023-05-11",
      "14:18",
      "2023-06-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/T23-2022-23.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ42-2022-2023 SUPPLY AND DELIVERY OF CHEMICALS FOR MATZIKAMA MUNICIPALITY `,
      "FQ42-2022-2023",
      "2023-05-04",
      "08:30",
      "2023-05-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ42-2022-2023.pdf",
      "Chemcape",
      "2",
      "R 34 385.00",
      "Closed"
    ),
    new TendersModule(
      `FQ41-2022-2023 APPOINTMENT OF A CONSULTANT TO HANDLE AND EVALUATE THE MUNICIPAL MANAGER POSITION`,
      "FQ41-2022-2023",
      "2023-05-03",
      "10:03",
      "2023-05-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ41-2022-2023.pdf",
      "Roy Steele",
      "4",
      "R 51 750.00",
      "Closed"
    ),
    new TendersModule(
      `FQ40-2022-2023 SUPPLY AND DELIVERY OF MAINTENANCE MATERIAL FOR WATER NETWORK `,
      "FQ40-2022-2023",
      "2023-04-21",
      "11:48",
      "2023-05-05",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ40-2022-2023.pdf",
      "Vredendal Besproeiing",
      "0",
      "R 187 546.32",
      "Closed"
    ),
    new TendersModule(
      `FQ39-2022-2023 REPLACED AND RE-PROGRAM OF BULK ELECTRICITY METERS `,
      "FQ39-2022-2023",
      "2023-04-18",
      "11:56",
      "2023-05-05",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ39-2022-2023.pdf",
      "Ontec",
      "1",
      "R 144 842.50",
      "Closed"
    ),
    new TendersModule(
      `FQ38-2022-2023 SUPPLY AND DELIVERY OF LAPTOPS `,
      "FQ38-2022-2023",
      "2023-03-28",
      "15:44",
      "2023-04-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ38-2022-2023.pdf",
      "CJJ Business Solutions, CHM Vuwani ",
      "4 , 1 respectively",
      "R 117 530.00 , R 27 292.95 respectively",
      "Closed"
    ),
    new TendersModule(
      `FQ37-2022-2023 SUPPLY AND DELIVERY OF CHEMICALS FOR MATZIKAMA MUNICIPALITY `,
      "FQ37-2022-2023",
      "2023-03-28",
      "15:41",
      "2023-04-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ37-2022-2023.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ36-2022-2023 SERVICE, REPAIR AND REFILL OF FIRE EQUIPMENT `,
      "FQ36-2022-2023",
      "2023-03-28",
      "15:39",
      "2023-04-12",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ36-2022-2023.pdf",
      "ESS Fire and Medical Services",
      "2",
      "R 25 806.00",
      "Closed"

    ),
    new TendersModule(
      `T21-2022-23 SUPPLY AND DELIVERY OF LIGHT DELIVERY VEHICLES `,
      "T21-2022-23",
      "2023-03-17",
      "12:33",
      "2023-04-21",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/T21-2022-23.pdf",
      "QG Brand Holdings",
      "2",
      "R 470 000.00",
      "Closed"
    ),
    new TendersModule(
      `T20-2022-23  SUPPLY AND DELIVERY OF DIGGER LOADER`,
      "T20-2022-23",
      "2023-03-17",
      "12:32",
      "2023-04-21",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/T20-2022-23.pdf",
      "Rustic Living Trading 155",
      "1",
      "R 1 213 185.60",
      "Closed"
    ),
    new TendersModule(
      `T19-2022-23  SERVICE PROVIDER FOR DEBTOR’S DATA CLEANSING  `,
      "T19-2022-23",
      "2023-03-17",
      "12:28",
      "2023-04-21",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/T19-2022-23.pdf",
      "Mubesko",
      "2",
      "R 453 882.00",
      "Closed"
    ),
    new TendersModule(
      `T18-2022-23  SUPPLY & DELIVERY OF DISTRIBUTION TRANSFORMERS FOR A PERIOD OF THREE (3) YEARS `,
      "T18-2022-23",
      "2023-03-17",
      "12:26",
      "2023-04-21",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/T18-2022-23.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `T17-2022-23  SUPPLY, DELIVERY, INSTALLATION & COMMISSIONING OF 400V THREE-PHASE DIESEL GENERATORS `,
      "T17-2022-23",
      "2023-03-17",
      "12:23",
      "2023-04-21",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/T17-2022-23.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `T16-2022-23  SUPPLY & DELIVERY OF ARC-RATED PPE FOR A PERIOD OF ONE (1) YEAR WITH THE POSSIBILITY TO EXTENT FOR 2 YEARS`,
      "T16-2022-23",
      "2023-03-17",
      "12:16",
      "2023-04-21",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/T16-2022-23.pdf",
      "Essential Safety Wear",
      "2",
      "R 84 802",
      "Closed"
    ),
    new TendersModule(
      `FQ35-2022-2023 SUPPLY AND DELIVERY OF AIRDAC CABLE`,
      "FQ35-2022-2023",
      "2023-03-09",
      "11:24",
      "2023-03-16",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ35-2022-2023.pdf",
      "Vredendal Besproeiing",
      "0",
      "R 44 763.75",
      "Closed"
    ),
    new TendersModule(
      `FQ34-2022-2023 SUPPLY AND INSTALLATION OF AN INTERMEDIATE PUMP BETWEEN VREDENDAL AND VANRHYNSDORP`,
      "FQ34-2022-2023",
      "2023-03-09",
      "11:21",
      "2023-03-16",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ34-2022-2023.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `K29-2022-2023 SALE OF MUNICIPAL PROPERTY`,
      "K29-2022-2023",
      "2023-03-03",
      "11:55",
      "2023-04-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/K29 AWARDS.pdf",
      "See list of awards by downloading document",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `T15-2022-23  TOWN AND REGIONAL/SPATIAL PROFESSIONAL PLANNING SERVICES FOR VARIOUS MUNICIPAL
      LAND DEVELOPMENT PROJECTS FOR A THREE-YEAR PERIOD FOR MATZIKAMA MUNICIPALITY`,
      "T15-2022-23",
      "2023-03-03",
      "11:52",
      "2023-04-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/T15-2022-23.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `T10-2022-23  PROVISION OF PROFESSIONAL CIVIL AND ELECTRICAL ENGINEERING SERVICES FOR VARIOUS PROJECTS FOR A THREE-YEAR PERIOD FOR MATZIKAMA MUNICIPALITY`,
      "T10-2022-23",
      "2023-03-03",
      "11:47",
      "2023-04-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/T10-2022-23.pdf",
      `Naidu Consulting,Daveng Consulting Engineers, WEC Consulting,JPCE ,Nadeson Consulting,EAS Infrastructure,
      Tess Engineers,Techq Development,IX Engineers, Quantra Consulting,UDS Africa, Neil Lyners, Zutari,Bigen Africa`,
      "1,1,1,1,1,1,2,2,1,1,1,1,1,1 Respectively",
      `R 1219000.00,R 1207500.00,R 1207500.00,R 1207500.00,R 1207500.00,R 1207500.00,R 1506500.00,R 1276500.00,R 1207500.00,R 1207500.00,
      R 1207500.00,R 1207500.00,R 1207500.00,R 1207500.00 Respectively`,
      "Closed"
    ),
    new TendersModule(
      `FQ33-2022-2023  COURIER SERVICES FOR MATZIKAMA MUNICIPALITY`,
      "FQ33-2022-2023",
      "2023-03-02",
      "11:41",
      "2023-03-10",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ33-2022-2023.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ32-2022-2023  SUPPLY AND DELIVERY OF CHEMICALS FOR MATZIKAMA MUNICIPALITY`,
      "FQ32-2022-2023",
      "2023-03-02",
      "11:36",
      "2023-03-10",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ32-2022-2023.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ31-2022-2023  TARIFF MODELING FOR SERVICE AND RATES TARIFFS FOR MATZIKAMA MUNICIPALITY`,
      "FQ31-2022-2023",
      "2023-03-02",
      "11:32",
      "2023-03-10",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ31-2022-2023.pdf",
      "Ducharme",
      "2",
      "R 175 950.00",
      "Closed"
    ),
    new TendersModule(
      `FQ30-2022-2023  SUPPLY AND DELIVERY OF LED STREET LIGHT FITTINGS`,
      "FQ30-2022-2023",
      "2023-02-28",
      "11:26",
      "2023-03-07",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ30-2022-2023.pdf",
      "Obejane Trading",
      "1",
      "R 95 220.00 ",
      "Closed"
    ),
    new TendersModule(
      `T14-2022-23  ICT SERVER INFRASTRUCTURE UPGRADE`,
      "T14-2022-23",
      "2023-02-24",
      "10:31",
      "2023-03-24",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/T14-2022-23.pdf",
      "Was Awarded to First Technology, but First Technology withdrew from the tender",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ29:2022-2023  SUPPLY AND DELIVERY OF BUILDING MATERIAL`,
      "FQ29:2022-2023",
      "2023-02-24",
      "10:28",
      "2023-03-03",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ29-2022-2023.pdf",
      "Build It",
      "0",
      "R 113 982.60",
      "Closed"
    ),
    new TendersModule(
      `FQ28:2022-2023  INSTALLATION AND COMMISSION OF TRAFFIC LIGHT CONTROLLER`,
      "FQ28:2022-2023",
      "2023-02-15",
      "09:16",
      "2023-02-22",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ28-2022-2023.pdf",
      "Syntell",
      "1",
      "R 114 373.25",
      "Closed"
    ),
    new TendersModule(
      `FQ27:2022-2023  SERVICE PROVIDER TO COLLECT RAW DATA REGARDING BULK ELECTRICITY METERS`,
      "FQ27:2022-2023",
      "2023-02-13",
      "11:41",
      "2023-02-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ27-2022-2023.pdf",
      "Ontec",
      "1",
      "R 149 500",
      "Closed"
    ),
    new TendersModule(
      `FQ26:2022-2023 SUPPLY AND DELIVERY OF LED STREETLIGHT FITTINGS`,
      "FQ26:2022-2023",
      "2022-11-29",
      "11:17",
      "2022-12-06",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ26-2022-2023.pdf",
      "Vredendal Besproeiing",
      "0",
      "R 116 610.00",
      "Closed"
    ),
    new TendersModule(
      `FQ25:2022-2023 SUPPLY AND DELIVERY OF SMART PHONES`,
      "FQ25:2022-2023",
      "2022-11-24",
      "10:42",
      "2022-12-01",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ25-2022-2023.pdf",
      "Not Awarded",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `T13-2022-23 PROVISION OF INTERNET (FIBRE), WIRELESS NETWORK AND MAINTENANCE SERVICE FOR A THREE (3) YEAR PERIOD.`,
      "T13-2022-23",
      "2022-11-18",
      "08:22",
      "2022-12-05",
      "No later than 12:00",
      `24 November 2022 at the Council Chambers 37 Church Street, Vredendal at 11:00`,
      ``,
      "/assets/Tenders/2022-23/T13-2022-23.pdf",
      "Wanspot Wireless Network",
      "4",
      "R 2,424,784.20 ",
      "Closed"
    ),
    new TendersModule(
      `FQ24:2022-2023 SUPPLY AND DELIVERY OF A HYDROSTATIC 13 HP ROLLER`,
      "FQ24:2022-2023",
      "2022-11-15",
      "10:55",
      "2022-11-22",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ24-2022-2023.pdf",
      "Not Awarded",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ23:2022-2023 SUPPLY AND DELIVERY OF A CONCRETE MIXER`,
      "FQ23:2022-2023",
      "2022-11-15",
      "10:53",
      "2022-11-22",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ23-2022-2023.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ22:2022-2023 APPOINTMENT OF A CONSULTANT TO HANDLE AND EVALUATE TWO (2) DIRECTOR POSITIONS`,
      "FQ22:2022-2023",
      "2022-11-10",
      "09:22",
      "2022-11-17",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ22-2022-2023.pdf",
      "Roy Steele and Associates",
      "4",
      "R 81 520.00",
      "Closed"
    ),
    new TendersModule(
      `FQ19:2022-2023 SUPPLY AND DELIVERY OF BUILDING MATERIALS FOR GRAVES`,
      "FQ19:2022-2023",
      "2022-11-10",
      "08:57",
      "2022-11-18",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ19-2022-2023.pdf",
      "Not Awarded",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ18:2022-2023 LABOUR COST FOR BUILDING OF GRAVE PLOTS `,
      "FQ18:2022-2023",
      "2022-11-10",
      "08:54",
      "2022-11-21",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ18-2022-2023.pdf",
      "Cape Mountain Enterprises",
      "1",
      "R2 500.00 per grave",
      "Closed"
    ),
    new TendersModule(
      `FQ21:2022-2023 SUPPLY AND REPLACE OF OUTLET VALVES AND CLEANING OF THE LUTZVILLE RAW WATER DAM `,
      "FQ21:2022-2023",
      "2022-11-04",
      "11:04",
      "2022-11-15",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ21-2022-2023.pdf",
      "Vredendal Besproeiing",
      "0",
      "R 199 000.00",
      "Closed"
    ),
    new TendersModule(
      `FQ20:2022-2023 SUPPLY AND DELIVERY OF FENCING FOR KLAWER WASTEWATE TREATMENT WORKS `,
      "FQ20:2022-2023",
      "2022-11-04",
      "11:04",
      "2022-11-15",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ20-2022-2023.pdf",
      "Diphororo Consulting",
      "1",
      "R 92 000.00",
      "Closed"
    ),
    new TendersModule(
      `K18:2022-2023 SALE OF MUNICIPAL PROPERTY`,
      "K18:2022-2023",
      "2022-11-04",
      "11:01",
      "2022-12-05",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/K18-2022-2023.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `K17:2022-2023 LEASING OF MUNICIPAL FACILITIES FOR A 3 YEAR PERIOD `,
      "K17:2022-2023",
      "2022-10-21",
      "11:36",
      "2022-11-22",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/K17-2022-2023.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ17:2022-2023 SUBSCRIPTION SERVICE FOR A GEOGRAFPHIC INFORMATION SYSTEM USED BY TOWN PLANNING AND BUILDING CONTROL DEPARTMENT `,
      "FQ17:2022-2023",
      "2022-10-19",
      "08:46",
      "2022-10-31",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ17-2022-2023.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Not Awarded"
    ),
    new TendersModule(
      `FQ16:2022-2023 SUPPLY AND DELIVERY OF TRAFFIC / LAW ENFORCEMENT UNIFORMS `,
      "FQ16:2022-2023",
      "2022-10-19",
      "08:44",
      "2022-11-01",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ16-2022-2023.pdf",
      "FG Uniforms",
      "1",
      "per item awarded",
      "Closed"
    ),
    new TendersModule(
      `FQ15:2022-2023 ANTIVIRUS/PROTECTION LICENSING FOR MUNICIPALITY ICT SYSTEM`,
      "FQ15:2022-2023",
      "2022-10-17",
      "09:43",
      "2022-10-24",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ15-2022-2023.pdf",
      "First Technogly Western Cape",
      "1",
      "R 85 509.40",
      "Closed"
    ),
    new TendersModule(
      `K16-2022-23 SALE OF MUNICIPAL PROPERTY`,
      "K16-2022-23",
      "2022-10-14",
      "08:10",
      "2022-11-14",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/K16 AWARDS.pdf",
      "See list of awards by downloading document",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ14-2022-2023 SUPPLY AND DELIVERY OF AIRCONDITIONERS`,
      "FQ14-2022-2023",
      "2022-10-13",
      "08:55",
      "2022-10-20",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ14-2022-2023.pdf",
      "Top's Meubels",
      "4",
      "R 81 520.00",
      "Closed"
    ),
    new TendersModule(
      `FQ13-2022-2023 SUPPLY AND DELIVERY OF MATERIALS FOR UPGRADING OF TRAFFIC CENTRE`,
      "FQ13-2022-2023",
      "2022-10-07",
      "11:37",
      "2022-10-14",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ13-2022-2023.pdf",
      "Prinro Building and Steel Merchants",
      "3",
      "R 115 559.23",
      "Closed"
    ),
    new TendersModule(
      `FQ12-2022-2023 SUPPLY AND DELIVERY OF TRAFFIC / LAW ENFORCEMENT UNIFORMS `,
      "FQ12-2022-2023",
      "2022-10-04",
      "09:43",
      "2022-10-11",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ12-2022-2023.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Not Awarded"
    ),
    new TendersModule(
      `FQ10-2022-2023 SUBSCRIPTION SERVICE FOR A GEOGRAFPHIC INFORMATION SYSTEM USED BY TOWN PLANNING AND BUILDING CONTROL DEPARTMENT`,
      "FQ10-2022-2023",
      "2022-10-04",
      "09:40",
      "2022-10-11",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ10-2022-2023.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Not Awarded"
    ),
    new TendersModule(
      `T12-2022-2023 PRIVATISATION OF MATZIKAMA MUNICIPAL VEHICLE TESTING STATION IN VREDENDAL`,
      "T12-2022-2023",
      "2022-09-29",
      "09:41",
      "2022-10-31",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/T12-2022-23.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `T11-2022-2023 PROVISION OF SOFTWARE LICENCE FOR TRAFFIC-AND LAW ENFORCEMENT CONTRAVENTION SYSTEM,
      RENTAL OF SPEED CAMERAS AND PROCESSING OF SPEED FINES`,
      "T11-2022-2023",
      "2022-09-29",
      "09:39",
      "2022-10-31",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/T11-2022-23.pdf",
      "Total Computer Services (PY) LTD",
      "4",
      "R 683,864.00",
      "Closed"
    ),
    new TendersModule(
      `FQ11-2022-2023 APPOINTMENT OF AN AUCTIONEER`,
      "FQ11-2022-2023",
      "2022-09-30",
      "09:36",
      "2022-10-07",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ11-2022-2023.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Not Awarded"
    ),
    new TendersModule(
      `FQ09-2022-2023 SUPPLY AND DELIVERY OF 60 x 19KG LP GAS TO STRANDFONTEIN  `,
      "FQ09-2022-2023",
      "2022-09-14",
      "10:56",
      "2022-09-21",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2022-23/FQ09-2022-2023.pdf",
      "JFD Lochner CC",
      "0",
      "R 28 815.60",
      "Closed"
    ),
    new TendersModule(
      `T09-2022-2023 UPGRADING OF ROADS AND STORMWATER INFRASTRUCTURE IN VREDENDAL NORTH, PHASE 4 `,
      "T09-2022-2023",
      "2022-08-30",
      "14:00",
      "2022-09-30",
      "No later than 12:00",
      `2 August 2021 at 11:00 at the Municipal Offices in Church Street, Vredendal  (Covid 19 protocol to be observed)`,
      ``,
      "/assets/Tenders/2022-23/T09-2022-23.pdf",
      "JVZ Construction",
      "1",
      "R  4,500,647.50",
      "Closed"
    ),
    new TendersModule(
    `FQ08-2022-2023 APPOINTMENT OF A CONSULTANT TO COMPILE THE 2021/2022 WSPD PERFORMANCE & WATER SERVICE AUDIT REPORT `,
    "FQ08-2022-2023",
    "2022-08-30",
    "10:55",
    "2022-09-06",
    "No later than 12:00",
    ``,
    `N/A`,
    "/assets/Tenders/2022-23/FQ08-2022-2023.pdf",
    "IX Engineers",
    "1",
    "R 195 500.01",
    "Closed"
  ),
    new TendersModule(
      `FQ07-2022-2023 SUPPLY AND DELIVERY OF BUILDING MATERIALS FOR MAINTENANCE `,
      "FQ07-2022-2023",
      "2022-08-30",
      "10:50",
      "2022-09-06",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2022-23/FQ07-2022-2023.pdf",
      "Build It",
      "0",
      "R 33 655.25",
      "Closed"
    ),
    new TendersModule(
      `FQ06-2022-2023 HIRING OF EARTHMOVING MACHINERY `,
      "FQ06-2022-2023",
      "2022-08-30",
      "10:47",
      "2022-09-06",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2022-23/FQ06-2022-2023.pdf",
      "JE Civils",
      "2",
      "Hourly rate R720.00 for 20-ton Excavator, R835 for 40-ton Dumper and R550.00 for TLB",
      "Closed"
    ),
    new TendersModule(
      `T08-2022-23 SUPPLY, DELIVERY AND INSTALLATION OF COPY MACHINES FOR MATZIKAMA MUNICIPAL AREA FOR A THREE YEAR RENTAL PERIOD `,
      "T08-2022-23",
      "2022-08-26",
      "08:22",
      "2022-09-19",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2022-23/T08-2022-23.pdf",
      "Konica Minolta & Sky Metro",
      "1 & 1 respectively",
      "Various",
      "Closed"
    ),
    new TendersModule(
      `T07-2022-23 APPOINTMENT OF A SERVICE PROVIDER FOR THE LICENCE MAINTENANCE, UPGRADE AND SUPPORT OF AN INTEGRATED
       MUNICPAL FINANCIAL MANAGEMENT SYSTEM (ERP) FOR A THREE (3) YEAR PERIOD.`,
      "T07-2022-23",
      "2022-08-26",
      "08:22",
      "2022-09-26",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2022-23/T07-2022-23.pdf",
      "Phoenix",
      "0",
      "R R208 437.50 per month",
      "Closed"
    ),
    new TendersModule(
      `T06-2022-23 APPOINTMENT OF A SERVICE PROVIDER FOR THE PROVISION OF INTERNET, WIRELESS NETWORK,
      NETWORK MAINTENANCE SERVICES FOR A THREE (3) YEAR PERIOD `,
      "T06-2022-23",
      "2022-08-26",
      "08:20",
      "2022-09-26",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2022-23/T06-2022-23.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `T05-2022-23 SUPPLY, DELIVERY AND CONSTRUCTION OF SLURRY SEAL IN VREDENDAL `,
      "T05-2022-23",
      "2022-08-26",
      "08:18",
      "2022-09-26",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2022-23/T05_2-2022-23.pdf",
      "Amandla Construction",
      "1",
      "R 2,666,735.00",
      "Closed"
    ),
    new TendersModule(
      `T04-2022-23 PROVISION OF SUPER SUCKER UNIT AS AND WHEN NEEDED UNTIL 30 JUNE 2023`,
      "T04-2022-23",
      "2022-08-26",
      "08:16",
      "2022-09-26",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2022-23/T04-2022-23.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ05-2022-2023 SUPPLY AND DELIVERY OF ROAD MARKING PAINT FOR MATZIKAMA MUNICIPALITY`,
      "FQ05-2022-2023",
      "2022-08-19",
      "09:39",
      "2022-08-26",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2022-23/FQ05-2022-2023.pdf",
      "Take Note Trading",
      "1",
      "R 93 423.00",
      "Closed"
    ),
    new TendersModule(
      `FQ04-2022-2023 APPOINTMENT OF A CONSULTANT TO COMPILE THE 2021/2022 WSPD PERFOMANCE & WATER SERVICE AUDIT REPORT`,
      "FQ04-2022-2023",
      "2022-08-19",
      "09:34",
      "2022-08-26",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2022-23/FQ04-2022-2023.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Not Awarded"
    ),
    new TendersModule(
      `FQ03-2022-2023 SUPPLY AND DELIVERY OF BUILDING MATERIALS FOR MAINTENANCE`,
      "FQ03-2022-2023",
      "2022-08-11",
      "10:58",
      "2022-08-19",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2022-23/FQ03-2022-2023.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ02:2022-2023 SUPPLY AND DELIVERY OF MATERIAL (PIPES AND FITTINGS) FOR MAINTENANCE ON WATER NETWORK `,
      "FQ02:2022-2023",
      "2022-08-03",
      "14:06",
      "2022-08-12",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2022-23/FQ02-2022-2023.pdf",
      "Vredendal Besproeiing",
      "0",
      "R 136 416.45",
      "Closed"
    ),
    new TendersModule(
      `FQ1:2022-2023 ACCREDITED TRAINING: MEDIUM VOLTAGE THEORY AND PRACTICAL TRAINING`,
      "FQ1:2022-2023",
      "2022-07-27",
      "11:44",
      "2022-08-03",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2022-23/FQ1-2022-2023.pdf",
      "Effect Human Intervention",
      "2",
      "R 111 950.2",
      "Closed"
    ),
    new TendersModule(
      `K2:2022-2023 SALE OF MUNICIPAL PROPERTY`,
      "K2:2022-2023",
      "2022-07-15",
      "15:00",
      "2022-08-22",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2022-23/K2- 2022-2023 Awarded.pdf",
      "See list of awards by downloading document",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `T3:2022-2023 SUPPLY AND DELIVERY OF ELECTRICAL MATERIAL `,
      "T3:2022-2023",
      "2022-07-15",
      "14:47",
      "2022-08-15",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2022-23/T03-2022-23.pdf",
      "Vredendal Besproeiing",
      "0",
      "R 752 614.97",
      "Closed"
    ),
    new TendersModule(
      `T2:2022-2023 SUPPLY AND DELIVERY OF ELECTRICAL CABLES `,
      "T2:2022-2023",
      "2022-07-15",
      "14:45",
      "2022-08-15",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2022-23/T02-2022-23.pdf",
      "ARB Electrical",
      "1",
      "R 452 226.50",
      "Closed"
    ),
    new TendersModule(
      `T1:2022-2023 SUPPLY AND DELIVERY OF SAFETY PROTECTIVE CLOTHING FOR A PERIOD OF ONE (1) YEAR WITH THE POSSIBILITY TO  EXTENT FOR 2 YEARS`,
      "T1:2022-2023",
      "2022-07-15",
      "14:43",
      "2022-08-15",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2022-23/T01-2022-23.pdf",
      "Martin All You Need Services, Blackbird Trading",
      "1 , 1 ",
      "Unit Price as per tender document",
      "Closed"
    ),
    new TendersModule(
      `T22:2021-2022 ELECTRICITY COST OF SUPPLY AND PRICING STRATEGY FOR MATZIKAMA MUNICIPALITY`,
      "T22:2021-2022",
      "2022-06-10",
      "09:07",
      "2022-07-11",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2021-22/T22-2021-2022.pdf",
      "Elexpert",
      "2",
      "R 790 000.5",
      "Closed"
    ),
    new TendersModule(
      `T15:2021-2022 UPGRADING OF VREDENDAL RAW WATER PUMP STATION, WATER TREATMENT AND BULK WATER PUMP STATIONS: PHASE 1.1`,
      "T15:2021-2022",
      "2022-06-10",
      "09:05",
      "2022-07-08",
      "No later than 12:00",
      `21 June 2022 at 11:00   at the Council Chambers of Matzikama Municipality, 37 Church Street, Vredendal. (Covid 19 protocol to be observed). The documents will also be available at the offices of LYNERS CONSULTING ENGINEERS , South Gate Building, Ground Floor,
      South Gate Entrance, Carl Cronje Drive, Tygervalley Waterfront, Bellville weekdays from 09h00 until 16h00.`,
      `N/A`,
      "/assets/Tenders/2021-22/T15-2021-2022.pdf",
      "Tess Engineering RSA (PTY) Ltd",
      "1",
      "R15 953 178.62",
      "Closed"
    ),
    new TendersModule(
      `T21:2021-2022 SUPPLY OF SPEED LAW ENFORCEMENT EQUIPMENT AND PROVIDING OF BACK UP OFFICE FOR COLLECTION OF TRAFFIC AND MUNICIPAL BY LAW FINES FOR A THREE (3) YEAR PERIOD.`,
      "T21:2021-2022",
      "2022-06-03",
      "08:55",
      "2022-07-04",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2021-22/T21-2021-2022.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `T20:2021-2022 SUPPLY AND DELIVERY OF CHEMICALS FOR MATZIKAMA MUNICIPALITY UNTIL 30 JUNE 2023`,
      "T20:2021-2022",
      "2022-06-03",
      "08:52",
      "2022-07-04",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2021-22/T20-2021-2022.pdf",
      "Diphororo",
      "1",
      "Unit prices as per tender document",
      "Closed"
    ),
    new TendersModule(
      `T17:2021-2022 UPGRADING OF ROADS AND STORMWATER INFRASTRUCTURE IN MASKAMSIG, VANRHYNSDORP- PHASE 6`,
      "T17:2021-2022",
      "2022-05-27",
      "09:14",
      "2022-06-24",
      "No later than 12:00",
      `7 June 2022 at 11:00 at the Municipal Offices in Vanrhynsdorp (Covid 19 protocol to be observed) `,
      `N/A`,
      "/assets/Tenders/2021-22/T17-2021-2022.pdf",
      "PR Civils",
      "1",
      "R 6 700 342.7",
      "Closed"
    ),
    new TendersModule(
      `FQ29:2021-2022 SUPPLY AND DELIVERY OF ONE (1)  LIGHT SEDAN VEHICLE`,
      "FQ29:2021-2022",
      "2022-05-25",
      "11:16",
      "2022-05-23",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2021-22/FQ29-2021-2022.pdf",
      "Not Awarded",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ28:2021-2022 SUPPLY AND DELIVERY OF A CONCRETE MIXER AND ROLLER`,
      "FQ28:2021-2022",
      "2022-05-25",
      "11:05",
      "2022-06-01",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2021-22/FQ28-2021-2022.pdf",
      "Not Awarded",
      "N/A",
      "N/A",
      "Closed"
    ),
     new TendersModule(
      `FQ27:2021-2022 SUPPLY AND DELIVERY OF FIVE (5) LAPTOPS`,
      "FQ27:2021-2022",
      "2022-05-25",
      "10:50",
      "2022-06-01",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2021-22/FQ27-2021-2022.pdf",
      "CHM Vuwani, Dekatse Consulting",
      "1 , 1",
      "R 125 111.95, R 28 681.00",
      "Closed"
    ),
    new TendersModule(
      `FQ26:2021-2022 APPOINTMENT OF A CONSULTANT TO HANDLE AND EVALUATE TWO (2) DIRECTOR POSITIONS`,
      "FQ26:2021-2022",
      "2022-05-20",
      "09:12",
      "2022-05-27",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2021-22/FQ26-2021-2022.pdf",
      "Roy Steele and Associates",
      "4",
      "R 89 999.99",
      "Closed"
    ),
    new TendersModule(
      `T19:2021-2022 DESIGN, PRINTING AND DISTRIBUTION OF MUNICIPAL ACCOUNTS FOR A THREE (3) YEAR PERIOD`,
      "T19:2021-2022",
      "2022-05-20",
      "08:45",
      "2022-06-21",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2021-22/T19-2021-2022.pdf",
      "CAB Holdings",
      "2",
      "862.50 per 1000 accounts per month for year 1, Year 2 with a 5% Escalation, year 3 with a 5% Escalation ",
      "Closed"
    ),
    new TendersModule(
      `FQ23:2021-2022 SUPPLY AND DELIVERY OF AIR VALVES`,
      "FQ23:2021-2022",
      "2022-05-19",
      "08:25",
      "2022-05-26",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2021-22/FQ23-2021-2022.pdf",
      "Universal Trading",
      "1",
      "R53 292.15",
      "Closed"
    ),
    new TendersModule(
      `FQ22:2021-2022 SUPPLY AND DELIVERY OF A 75KW WATER PUMP`,
      "FQ22:2021-2022",
      "2022-05-19",
      "08:22",
      "2022-05-26",
      "No later than 12:00",
      `Not Awarded`,
      `N/A`,
      "/assets/Tenders/2021-22/FQ22-2021-2022.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ21:2021-2022 SUPPLY AND DELIVERY OF A NEW IMPELLAR FOR A WATER PUMP`,
      "FQ21:2021-2022",
      "2022-05-19",
      "08:19",
      "2022-05-26",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2021-22/FQ21-2021-2022.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ25:2021-2022 SUPPLY AND DELIVERY OF ONE (1)  LIGHT SEDAN VEHICLE`,
      "FQ25:2021-2022",
      "2022-05-16",
      "11:46",
      "2022-05-23",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2021-22/FQ25-2021-2022.pdf",
      "Not Awarded",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ24:2021-2022 SUPPLY AND DELIVERY OF A CONCRETE MIXER AND ROLLER`,
      "FQ24:2021-2022",
      "2022-05-16",
      "11:40",
      "2022-05-23",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2021-22/FQ24-2021-2022.pdf",
      "Not Awarded",
      "N/A",
      "N/A",
      "Closed"
    ),
     new TendersModule(
      `FQ20:2021-2022 SUPPLY AND DELIVERY OF FIVE (5) LAPTOPS`,
      "FQ20:2021-2022",
      "2022-05-16",
      "11:35",
      "2022-05-23",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2021-22/FQ20-2021-2022.pdf",
      "Not Awarded",
      "N/A",
      "N/A",
      "Closed"
    ),
    // new TendersModule(
    //   `FQ19:2021-2022 SUPPLY AND DELIVERY OF A CONCRETE MIXER AND ROLLER`,
    //   "FQ19:2021-2022",
    //   "2022-02-28",
    //   "09:00",
    //   "2022-03-07",
    //   "No later than 12:00",
    //   `N/A`,
    //   `N/A`,
    //   "/assets/Tenders/2021-22/FQ19-2021-2022.pdf",
    //   "N/A",
    //   "N/A",
    //   "N/A",
    //   "Open"
    // ),
    // new TendersModule(
    //   `FQ18:2021-2022 SUPPLY AND DELIVERY OF AIR-VALVES`,
    //   "FQ18:2021-2022",
    //   "2022-02-28",
    //   "08:55",
    //   "2022-03-07",
    //   "No later than 12:00",
    //   `N/A`,
    //   `N/A`,
    //   "/assets/Tenders/2021-22/FQ18-2021-2022.pdf",
    //   "N/A",
    //   "N/A",
    //   "N/A",
    //   "Open"
    // ),
    // new TendersModule(
    //   `FQ17:2021-2022 SUPPLY AND DELIVERY OF  AN 150-50 NEW IMPELLAR`,
    //   "FQ17:2021-2022",
    //   "2022-02-28",
    //   "08:52",
    //   "2022-03-07",
    //   "No later than 12:00",
    //   `N/A`,
    //   `N/A`,
    //   "/assets/Tenders/2021-22/FQ17-2021-2022.pdf",
    //   "N/A",
    //   "N/A",
    //   "N/A",
    //   "Open"
    // ),
    // new TendersModule(
    //   `FQ16:2021-2022 SUPPLY AND DELIVERY OF A VERTICAL MULTISTAGE 75KW WATER PUMP`,
    //   "FQ16:2021-2022",
    //   "2022-02-28",
    //   "08:50",
    //   "2022-03-07",
    //   "No later than 12:00",
    //   `N/A`,
    //   `N/A`,
    //   "/assets/Tenders/2021-22/FQ16-2021-2022.pdf",
    //   "N/A",
    //   "N/A",
    //   "N/A",
    //   "Open"
    // ),
    new TendersModule(
      `FQ15:2021-2022 SERVICES FOR ACCREDITED TRAINING PROVIDER: MUNICIPAL FINANCIAL MANAGEMENT PROGRAMME`,
      "FQ15:2021-2022",
      "2022-02-02",
      "10:29",
      "2022-02-08",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2021-22/FQ15-2021-2022.pdf",
      "Summat Training Institute",
      "1",
      "R 172 800.00 ",
      "Closed"
    ),
    new TendersModule(
      `T14/2021-2022 SUPPLY, DELIVERY AND INSTALLATION OF COPY MACHINES FOR MATZIKAMA MUNICIPAL AREA FOR A THREE YEAR RENTAL PERIOD`,
      "T14/2021-2022",
      "2022-01-28",
      "09:46",
      "2022-02-28",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2021-22/T14-2021-2022.pdf",
      "Not Awarded",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `T13/2021-2022 CELLPHONE CONTRACTS FOR MUNICIPAL- AND STANDBY PHONES FOR A TWO (2) YEAR PERIOD`,
      "T13/2021-2022",
      "2022-01-28",
      "09:57",
      "2022-02-28",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2021-22/T13-2021-2022.pdf",
      "Not Awarded",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ14:2021-2022 SERVICE, REPAIR AND REFILL OF FIRE EQUIPMENT FOR MATZIKAMA MUNICIPALITY FOR THE PERIOD ENDING  30 JUNE 2022`,
      "FQ14:2021-2022",
      "2022-01-24",
      "11:51",
      "2022-01-31",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2021-22/FQ14-2021-2022.pdf",
      "ESS Fire and Medical Services",
      "2",
      "R 24 035.00",
      "Closed"
    ),
    new TendersModule(
      `FQ13:2021/2022 APPOINTMENT OF A CONSULTANT TO HANDLE AND EVALUATE THE MUNICIPAL MANAGER POSITION`,
      "FQ13:2021/2022",
      "2021-12-07",
      "11:24",
      "2021-12-14",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2021-22/FQ13-2021-2022.pdf",
      "Roy Steele and Associates",
      "4",
      "R45 000",
      "Closed"
    ),
    new TendersModule(
      `FQ12:2021/2022 UPDATE OF DATA LINES`,
      "FQ12:2021/2022",
      "2021-11-29",
      "10:57",
      "2021-12-06",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2021-22/FQ12-2021-2022.pdf",
      "Chatz Connect Vredendal",
      "0",
      "R 199 458.60",
      "Closed"
    ),
    new TendersModule(
      `T12:2021/2022 KLAWER BULK WATER INFRASTRUCTURE UPGRADE`,
      "T12:2021/2022",
      "2021-11-19",
      "07:35",
      "2021-12-15",
      "No later than 12:00",
      `26 November 2021 at 11:00 at the Community Hall in Klawer in Alfa Street (Covid 19 protocol to be observed)`,
      `N/A`,
      "/assets/Tenders/2021-22/T12-2021-2022.pdf",
      "PR Civils",
      "1",
      "R 15 730 590.00",
      "Closed"
    ),
    new TendersModule(
      `T11:2021/2022 THE PROVISION OF AN INTERNAL AUDIT SERVICE FOR MATZIKAMA MUNICIPALITY FOR A THREE (3) YEAR PERIOD.`,
      "T11:2021/2022",
      "2021-11-12",
      "08:59",
      "2021-12-13",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2021-22/T11-2021-2022.pdf",
      "Claassen Stone Inc",
      "4",
      "R 3 363 750",
      "Closed"
    ),
    new TendersModule(
      `T10:2021/2022 INFORMATION AND COMMUNICATION TECHNOLOGY SERVICES FOR A THREE (3) YEAR PERIOD`,
      "T10:2021/2022",
      "2021-11-12",
      "08:57",
      "2021-12-13",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2021-22/T10-2021-2022.pdf",
      "Not Awarded",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `T9:2021/2022 SUPPLY AND DELIVERY OF ELECTRICAL MATERIAL, EQUIPMENT AND TOOLS`,
      "T9:2021/2022",
      "2021-11-12",
      "08:55",
      "2021-11-26",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2021-22/T9-2021-2022.pdf",
      "Not Awarded",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `K16:2021-2022 VERKOOP VAN MUNISIPALE EIENDOMME`,
      "K16:2021-2022",
      "2021-10-22",
      "08:45",
      "2021-11-22",
      "No later than 12:00",
      ``,
      ``,
      "/assets/Tenders/2021-22/K16-2021-2022_Awards.pdf",
      "See Advert Download below for full list",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ10:2021-2022 SUPPLY AND DELIVERY OF A 7.5KW WATER PUMP`,
      "FQ10:2021-2022",
      "2021-10-19",
      "08:53",
      "2021-10-26",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2021-22/FQ10-2021-2022.pdf",
      "Not Awarded",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ09:2021-2022 TRAFFIC AND LAW ENFORCEMENT CONTRAVENTION SYSTEM`,
      "FQ09:2021-2022",
      "2021-10-01",
      "11:38",
      "2021-10-12",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2021-22/FQ09-2021-2022.pdf",
      "Total Computer Services",
      "3",
      "R 10 350.00",
      "Closed"
    ),
    new TendersModule(
      `FQ08:2021-2022 SERVER ROOM UPS SERVICE AND BATTERY REPLACEMENT `,
      "FQ08:2021-2022",
      "2021-10-01",
      "11:35",
      "2021-10-08",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2021-22/FQ08-2021-2022.pdf",
      "Not Awarded",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ07:2021-2022 ANTIVIRUS/PROTECTION LICENSING FOR MUNICIPALITY ICT SYSTEM`,
      "FQ07:2021-2022",
      "2021-10-01",
      "11:31",
      "2021-10-08",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2021-22/FQ07-2021-2022.pdf",
      "First Technologies",
      "2",
      "R 88 550.00",
      "Closed"
    ),
    new TendersModule(
      `FQ06:2021-2022 SUPPLY AND DELIVERY OF ELECTRICAL CABLES`,
      "FQ06:2021-2022",
      "2021-10-01",
      "11:27",
      "2021-10-12",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2021-22/FQ06-2021-2022.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ05:2021-2022 SUPPLY AND DELIVERY OF TRAFFIC/LAW ENFORCEMENT UNIFORMS`,
      "FQ05:2021-2022",
      "2021-09-17",
      "09:02",
      "2021-09-28",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2021-22/FQ05-2021-2022.pdf",
      "Sparks & Ellis & Blackbird Trading",
      "2 & 1",
      "R 84 435.30 & R 62 127.97",
      "Closed"
    ),
    new TendersModule(
      `FQ04:2021-2022 SUPPLY AND DELIVERY OF LP GAS TO STRANDFONTEIN`,
      "FQ04:2021-2022",
      "2021-09-09",
      "10:09",
      "2021-09-15",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2021-22/FQ04-2021-2022.pdf",
      "VGA Suppliers",
      "1",
      "R 55 000,00",
      "Closed"
    ),
    new TendersModule(
      `T08:2021-2022 SECURITY SERVICES`,
      "T08:2021-2022",
      "2021-09-03",
      "10:48",
      "2021-10-04",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2021-22/T8-2021-2022.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Award-In-Progress"
    ),
    new TendersModule(
      `FQ03:2021-2022 SUPPLY AND DELIVERY OF BUILDING MATERIAL`,
      "FQ03:2021-2022",
      "2021-08-17",
      "14:03",
      "2021-08-27",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2021-22/FQ03-2021-2022.pdf",
      "Buclabo",
      "2",
      "R132 155.60",
      "Closed"
    ),
    new TendersModule(
      `K6:2021-2022 Verhuring van die ou ongebruikte kleedkamers te Vredendal-Noord`,
      "K6:2021-2022",
      "2021-08-06",
      "11:58",
      "2021-09-03",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2021-22/K5-K6.pdf",
      "Not Awarded",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `K5:2021-2022 Verhuring van die kiosk te Strandfontein`,
      "K5:2021-2022",
      "2021-08-06",
      "11:56",
      "2021-09-03",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2021-22/K5-K6.pdf",
      "Not Awarded",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `T6:2021-2022 APPOINTMENT OF A SERVICE PROVIDER FOR AN AUTOMATED RISK AND PERFORMANCE MANAGEMENT SYSTEM FOR THE PERIOD ENDING 30 JUNE 2024`,
      "T6:2021-2022",
      "2021-08-06",
      "11:47",
      "2021-09-06",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2021-22/T6-2021-2022.pdf",
      "Ignite Advisory",
      "0",
      "R1 554 491.80",
      "Closed"
    ),
    new TendersModule(
      `T7:2021-2022 SUPPLY AND DELIVERY OF PRE-PAID ELECTRICAL METER TO VREDENDAL`,
      "T7:2021-2022",
      "2021-08-06",
      "11:43",
      "2021-09-06",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2021-22/T7-2021-2022.pdf",
      "Ontec Systems",
      "1",
      "R 795 550.45",
      "Closed"
    ),
    new TendersModule(
      `FQ02:2021-2022 SUPPLY AND DELIVERY OF BUILDING MATERIAL`,
      "FQ02:2021-2022",
      "2021-08-03",
      "11:19",
      "2021-08-11",
      "No later than 12:00",
      ``,
      `N/A`,
      "/assets/Tenders/2021-22/FQ02-2021-2022.pdf",
      "No Award Made",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `T5:2021-2022 SUPPLY AND DELIVERY OF WATER METERS TO VREDENDAL`,
      "T5:2021-2022",
      "2021-07-30",
      "11:12",
      "2021-08-30",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2021-22/T5-2021-2022.pdf",
      "Take Note Trading 245 cc ",
      "1",
      "20mm water meter in box is R 569,25 each; 25mmx20mm female to male coupling adaptor is R 12,42 each; 20 keys is R 64,40",
      "Closed"
    ),
    new TendersModule(
      `T4:2021-2022 APPOINTMENT OF AN SERVICE PROVIDER FOR THE PROVISION OF TRAFFIC LAW ENFORCEMENT EQUIPMENT, BACK-OFFICE SYSTEMS
                    AND RELATED SERVICES FOR A PERIOD ENDING 30 JUNE 2024`,
      "T4:2021-2022",
      "2021-07-30",
      "11:09",
      "2021-08-30",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2021-22/T4-2021-2022.pdf",
      "Not Awarded",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `T1:2021-2022 UPGRADING OF ROADS AND STORMWATER INFRASTRUCTURE IN UITKYK, LUTZVILLE- PHASE 1.3`,
      "T1:2021-2022",
      "2021-07-23",
      "10:59",
      "2021-08-12",
      "No later than 12:00",
      `3 August 2021 at 11:00 at the Municipal Offices in Du Toit Street in Lutzville (Covid 19 protocol to be observed)`,
      `N/A`,
      "/assets/Tenders/2021-22/T1-2021-2022.pdf",
      "Shar Civils ",
      "1",
      "R 5 593 734.69",
      "Closed"
    ),
    new TendersModule(
      `T2:2021-2022 UPGRADING OF ROADS AND STORMWATER INFRASTRUCTURE IN MASKAMSIG, VANRHYNSDORP- PHASE 5`,
      "T2:2021-2022",
      "2021-07-23",
      "10:44",
      "2021-08-13",
      "No later than 12:00",
      `4 August 2021 at 11:00 at the Municipal Offices in Vanrhynsdorp (Covid 19 protocol to be observed) `,
      `N/A`,
      "/assets/Tenders/2021-22/T2-2021-2022.pdf",
      "JVZ Construction (Pty) Ltd",
      "1",
      "R 4 649 610.57",
      "Closed"
    ),
        new TendersModule(
      `T3:2021-2022 UPGRADING OF ROADS AND STORMWATER INFRASTRUCTURE IN VREDENDAL NORTH- PHASE3`,
      "T3:2021-2022",
      "2021-07-23",
      "10:38",
      "2021-08-12",
      "No later than 12:00",
      `2 August 2021 at 11:00 at the Municipal Offices in Church Street, Vredendal  (Covid 19 protocol to be observed)`,
      `N/A`,
      "/assets/Tenders/2021-22/T3-2021-2022.pdf",
      "JVZ Construction (Pty) Ltd",
      "1",
      "R 4 462 697.87 ",
      "Closed"
    ),
    new TendersModule(
      `FQ01:2021/2022 Compilation of Annual Performance Report 2020/2021 and Annual Report 2020/2021`,
      "FQ01:2021/2022",
      "2021-07-22",
      "11:36",
      "2021-07-29",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2021-22/FQ01-2021-2022.pdf",
      "Ignite Advisory",
      "0",
      "R129 375.00",
      "Closed"
    ),
    new TendersModule(
      `T55:2020-2021 PROVISION OF CASH-IN-TRANSIT SERVICES FOR THE PERIOD ENDING 30 JUNE 2024 `,
      "T55:2020-2021",
      "2021-06-25",
      "09:11",
      "2021-07-26",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/T55-2020-2021.pdf",
      "Fidelity  Cash Soluitons",
      "1",
      "25 730.10 per month",
      "Closed"
    ),
    new TendersModule(
      `T54:2020-2021 SUPPLY AND DELIVERY OF SAFETY PROTECTIVE CLOTHING FOR A PERIOD OF ONE (1) YEAR WITH THE POSSIBILITY TO  EXTENT FOR 2 YEARS `,
      "T54:2020-2021",
      "2021-06-25",
      "09:11",
      "2021-07-09",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/T54-2020-2021.pdf",
      "Blackbird Trading,Safepro,Kaap Agri",
      "1,2,3",
      "As per tender document",
      "Closed"
    ),
    new TendersModule(
      `K17/2021 SALE OF MUNICIPAL PROPERTY`,
      "SALE OF MUNICIPAL PROPERTY",
      "2021-06-18",
      "09:39",
      "2021-07-19",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2021-22/K17_Awards_2021.pdf",
      "See advert attachment",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ60:2020-2021 SUPPLY AND DELIVERY OF HOSE AND COUPLINGS FOR WATER SUPPLY LINE TO RAW WATER DAM`,
      "FQ60:2020-2021",
      "2021-06-17",
      "10:41",
      "2021-06-24",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/FQ60-2020-2021.pdf",
      "Afri Mak",
      "1",
      "R 189 281.95",
      "Closed"
    ),
    new TendersModule(
      `FQ59:2020-2021 SUPPLY AND DELIVERY OF 3 x CHLORINATORS & 10 x CHLORINE PIGTAIL CONNECTIONS`,
      "FQ59:2020-2021",
      "2021-06-11",
      "11:54",
      "2021-06-21",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/FQ59-2020-2021.pdf",
      "NOT AWARDED",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ58:2020-2021 SUPPLY AND DELIVERY OF COMPUTER CONSUMABLES`,
      "FQ58:2020-2021",
      "2021-06-04",
      "11:20",
      "2021-06-11",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/FQ58-2020-2021.pdf",
      "CJJ Business Solutions & Imperatech",
      "4 & 1 respectively",
      "R 11 264.25 & R 23 921.40 respectively",
      "Closed"
    ),
    new TendersModule(
      `FQ57:2020-2021 SUPPLY AND REPLACE ACTUATOR ON BERMAD VALVE (MODEL NR:705) WITH NEW ACTUATOR `,
      "FQ57:2020-2021",
      "2021-06-03",
      "09:47",
      "2021-06-10",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/FQ57-2020-2021.pdf",
      "Vredendal Besproeiing",
      "0",
      "R37 950",
      "Closed"
    ),
    new TendersModule(
      `FQ56:2020-2021 SUPPLY AND DELIVERY OF A SUBMERSIBLE SEWERAGE PUMP : 22KW `,
      "FQ56:2020-2021",
      "2021-06-03",
      "09:44",
      "2021-06-10",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/FQ56-2020-2021.pdf",
      "Trautmann Trading",
      "1",
      "R133 285",
      "Closed"
    ),
    new TendersModule(
      `T53:2020-2021 PROVISION OF VEHICLE TRACKING SYSTEM FROM THE PERIOD ENDING 30 JUNE 2024`,
      "T53:2020-2021",
      "2021-05-31",
      "11:57",
      "2021-07-02",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/T53-2020-2021.pdf",
      "Realm Track",
      "2",
      "R 16 016.50 per month and R199 per added vechile",
      "Closed"
    ),
    new TendersModule(
      `T52:2020-2021 INFORMATION AND COMMUNICATION TECHNOLOGY SERVICES FOR THE PERIOD ENDING 30 JUNE 2024`,
      "T52:2020-2021",
      "2021-05-31",
      "11:55",
      "2021-07-02",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/T52-2020-2021.pdf",
      "NOT AWARDED",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `T51:2020-2021 SUPPLY AND DELIVERY OF ROAD MATERIAL FOR ROAD MAINTENANCE IN THE MATZIKAMA AREA FOR THE PERIOD UNTIL 30 JUNE 2024.`,
      "T51:2020-2021",
      "2021-05-31",
      "11:54",
      "2021-07-02",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/T51-2020-2021.pdf",
      "Setlatlapi Business Enterprise",
      "1",
      "R 791 077.99",
      "Closed"
    ),
    new TendersModule(
      `T49:2020-2021 SUPPLY AND DELIVERY OF ROADMARKING PAINT FOR THE PERIOD OF TWO(2) YEARS ENDING 30 JUNE 2023`,
      "T49:2020-2021",
      "2021-05-31",
      "11:57",
      "2021-07-02",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/T49-2020-2021.pdf",
      "Central Road Products",
      "1",
      "R 76 650.90",
      "Closed"
    ),
    new TendersModule(
      `T50:2020-2021 SUPPLY, DELIVERY AND CONSTRUCTION OF SLURRY SEAL FOR MATZIKAMA MUNICIPALITY`,
      "T50:2020-2021",
      "2021-05-31",
      "11:52",
      "2021-07-02",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/T50-2020-2021.pdf",
      "Colas South Africa",
      "1",
      "1 006 407.32",
      "Closed"
    ),
    new TendersModule(
      `T48:2020-2021 HIRING OF MACHINERY FOR THE MATZIKAMA MUNICIPALITY LANDFILL SITES TO PERFORM CONSTRUCTION WORK UNTIL 30 JUNE 2022.`,
      "T48:2020-2021",
      "2021-05-31",
      "11:51",
      "2021-07-02",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/T48-2020-2021.pdf",
      "Aqua Transport",
      "4",
      "R1 179 910.30",
      "Closed"
    ),
    new TendersModule(
      `T47:2020-2021 HIRING OF A HIGH-PRESSURE JETTING MACHINE ENDING 30 JUNE 2024`,
      "T47:2020-2021",
      "2021-05-31",
      "11:49",
      "2021-07-02",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/T47-2020-2021.pdf",
      "Pelepele investment",
      "1",
      "5750 daily rate",
      "Closed"
    ),
    new TendersModule(
      `T46:2020-2021 HIRING OF GRADER AND ROLLER FOR THE MATZIKAMA MUNICIPALITY AREA TO PERFORM GRAVEL ROADS MAINTENANCE UNTIL 30 JUNE 2022`,
      "T46:2020-2021",
      "2021-05-31",
      "11:47",
      "2021-07-02",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/T46-2020-2021.pdf",
      "Aqua Transport",
      "1",
      "R 480 872.50",
      "Closed"
    ),
    new TendersModule(
      `T45:2020-2021 SUPPLY AND DELIVERY OF BITUMEN PRODUCTS FOR ROAD MAINTENANCE FOR MATZIKAMA MUNICIPALITY ENDING 30 JUNE 2024`,
      "T45:2020-2021",
      "2021-05-31",
      "11:42",
      "2021-07-02",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/T45-2020-2021.pdf",
      "Colas South Africa",
      "1",
      "As per bid document",
      "Closed"
    ),
    new TendersModule(
      `T44:2020-2021 SUPPLY AND DELIVERY OF CHEMICALS FOR MATZIKAMA MUNICIPALITY UNTIL 30 JUNE 2022`,
      "T44:2020-2021",
      "2021-05-31",
      "11:40",
      "2021-07-02",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/T44-2020-2021.pdf",
      "Diphororo Consulting ",
      "1",
      "Prices per item as indicated in documents",
      "Closed"
    ),
    new TendersModule(
      `T41:2020-2021 SUPPLY AND DELIVERY OF CHLORINE GAS FOR MATZIKAMA MUNICIPALITY UNTIL 30 JUNE 2024`,
      "T41:2020-2021",
      "2021-05-31",
      "11:37",
      "2021-07-02",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/T41-2020-2021.pdf",
      "Chlorecape",
      "2",
      "R45 595.20 for 24 x 70kg Cylinder per order year one, R49 072.80 year two, R52 936.80 year three",
      "Closed"
    ),
    new TendersModule(
      `T40:2020-2021 SUPPLY AND DELIVERY OF FILTER SAND FOR MATZIKAMA MUNICIPALITY UNTIL 30 JUNE 2024 `,
      "T40:2020-2021",
      "2021-05-31",
      "11:34",
      "2021-07-02",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/T40-2020-2021.pdf",
      "Cape Silica ",
      "1",
      "R 94.30 for the 10/30 and 7/16 40kg in  year 1,R 100.90 for the 10/30 and 7/16 40kg in  year 2,R 107.96 for the 10/30 and 7/16 40kg in  year 3",
      "Closed"
    ),
    new TendersModule(
      `T39:2020-2021 ANALYTICAL ANALYSIS OF WATER AND WASTE WATER SAMPLES UNTIL 30 JUNE 2024. `,
      "T39:2020-2021",
      "2021-05-31",
      "11:32",
      "2021-07-02",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/T39-2020-2021.pdf",
      "Al Abbott",
      "2",
      "R1 187.00 for water sample R606.00 waste water sample",
      "Closed"
    ),
    new TendersModule(
      `FQ55:2020-2021 RE-ADVERTISEMENT SUPPLY AND DELIVERY OF TWO (2) LAPTOPS AND ONE (1) ONE PRINTER  `,
      "FQ55:2020-2021",
      "2021-05-27",
      "10:58",
      "2021-06-03",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/FQ55-2020-2021.pdf",
      "Dekatshe",
      "1",
      "R 69 297.67",
      "Closed"
    ),
    new TendersModule(
      `FQ54:2020-2021 APPOINTMENT OF A SERVICE PROVIDER TO PREPARE A REPORT TO IMPLEMENT THE INTEGRATED REVENUE MANAGEMENT FRAMEWORK `,
      "FQ54:2020-2021",
      "2021-05-27",
      "11:01",
      "2021-06-03",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/FQ54-2020-2021.pdf",
      "NOT AWARDED",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `T43:2020-2021 ACCOUNTING SUPPORT SERVICES FOR ASSETS INCLUDING UNBUNDLING FOR A THREE YEAR PERIOD ENDING 30 JUNE 2024.  `,
      "T43:2020-2021",
      "2021-05-21",
      "14:44",
      "2021-06-23",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/T43-2020-2021.pdf",
      "Ducharme",
      "2",
      "R 569 595.00 per year for the accounting support services for assets including unbundling. R76.82 per item for asset verification and R11 327.50 per session for skills transfer",
      "Closed"
    ),
    new TendersModule(
      `T42:2020-2021 ACCOUNTING SUPPORT SERVICES FOR ANNUAL FINANCIAL STATEMENTS FOR A 3 YEAR PERIOD ENDING 30 JUNE 2024.  `,
      "T42:2020-2021",
      "2021-05-21",
      "14:38",
      "2021-06-23",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/T42-2020-2021.pdf",
      "Siyanda Business Solutions",
      "4",
      "R 475 737.75 for year one (R 943.00 per hourly rate for audit queries and budget services),R 498 274.30 for year two (R 989.00 per hourly rate for audit queries and budget services), R 521 401.38 for year three (R 1 035.00 per hourly rate)",
      "Closed"
    ),
    new TendersModule(
      `T38:2020-2021 UNDERWRITING OF MATZIKAMA MUNICIPALITY’S SHORT TERM INSURANCE PORTFOLIO FOR THE PERIOD 01 JULY 2021 TO 30 JUNE 2024 `,
      "T38:2020-2021",
      "2021-05-14",
      "14:50",
      "2021-06-15",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/T38-2020-2021.pdf",
      "Opulentia Finance Services ",
      "1",
      "R 1 462 116.07 for year one,  R 1 491 358.39 for year two,R 1 521 185.56 for year three",
      "Closed"
    ),
    new TendersModule(
      `FQ52:2020-2021 SUPPLY AND DELIVERY OF ELECTRICAL PREPAID METERS`,
      "FQ52:2020-2021",
      "2021-04-26",
      "10:45",
      "2021-05-04",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/FQ52-2020-2021.pdf",
      "NOT AWARDED",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ51:2020-2021 SUPPLY AND DELIVERY OF TWO (2) LAPTOPS AND ONE (1) ONE PRINTER`,
      "FQ51:2020-2021",
      "2021-04-26",
      "10:40",
      "2021-05-04",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/FQ51-2020-2021.pdf",
      "NOT AWARDED",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ50:2020-2021 SUPPLY AND DELIVERY OF BUILDING MATERIALS FOR THE HOMEWORK HUB VREDENDAL NORTH THUSONG CENTRE`,
      "FQ50:2020-2021",
      "2021-04-09",
      "11:52",
      "2021-04-16",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/FQ50-2020-2021.pdf",
      "Prinro Building and Steel",
      "4",
      "R 168,385.37",
      "Closed"
    ),
    new TendersModule(
      `FQ47:2020-2021 SUPPLY AND DELIVERY OF SAFETY CLOTHING`,
      "FQ47:2020-2021",
      "2021-04-09",
      "10:00",
      "2021-04-16",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/FQ47-2020-2021.pdf",
      "Safepro",
      "1",
      "R 28,141.16 ",
      "Closed"
    ),
    new TendersModule(
      `FQ49:2020-2021 TRAINING:SUPERVISORY MANAGEMENT`,
      "FQ49:2020-2021",
      "2021-03-19",
      "17:58",
      "2021-03-29",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/FQ49-2020-2021.pdf",
      "NOT AWARDED",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ48:2020-2021 TRAINING: SMALL MACHINE OPERATOR `,
      "FQ48:2020-2021",
      "2021-03-19",
      "18:02",
      "2021-03-29",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/FQ48-2020-2021.pdf",
      "NOT AWARDED",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `T36:2020-2021 CONSTRUCTION/REPAIR WORKS AT LUTZVILLE MUNICIPAL BUILDINGS`,
      "T36:2020-2021",
      "2021-02-19",
      "09:59",
      "2021-03-12",
      "No later than 12:00",
      `02 March 2021 at 12:00 at the Lutzville Community Hall`,
      `N/A`,
      "/assets/Tenders/2020-21/T36-2020-2021.pdf",
      "NOT AWARDED",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ42:2020-2021 CONSTRUCTION/REPAIR WORKS AT  VANRYHNSDORP MUNICIPAL BUILDINGS`,
      "FQ42:2020-2021",
      "2021-02-19",
      "09:55",
      "2021-03-12",
      "No later than 12:00",
      `02 March 2021 at 10:00 at the Vanrhynsdorp Community Hall`,
      `N/A`,
      "/assets/Tenders/2020-21/FQ42-2020-2021.pdf",
      "NOT AWARDED",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ43:2020-2021 SUPPLY AND DELIVERY OF BUILDING MATERIALS FOR THE UPGRADE OF VREDENDAL TRAFFIC DEPARTMENT`,
      "FQ43:2020-2021",
      "2021-02-16",
      "10:21",
      "2021-02-23",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/FQ43-2020-2021.pdf",
      "Kaap Agri",
      "3",
      "R188 962,34",
      "Closed"
    ),
    new TendersModule(
      `FQ41:2020-2021 APPOINTMENT OF AN AUTIONEER`,
      "FQ41:2020-2021",
      "2021-02-09",
      "11:53",
      "2021-02-16",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/FQ41-2020-2021.pdf",
      "Vendu Cape Auctineers",
      "1",
      "2,6%",
      "Closed"
    ),
    new TendersModule(
      `FQ40:2020-2021 APPOINTMENT OF A SERVICE PROVIDER FOR THE IMPLEMENTATION OF ELECTRICITY RATES`,
      "FQ40:2020-2021",
      "2021-02-09",
      "11:49",
      "2021-02-16",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/FQ40-2020-2021.pdf",
      "Electrical Energy Management Systems",
      "4",
      "R180 658,10",
      "Closed"
    ),
    new TendersModule(
      `T33:2020-2021 UPGRADING OF KLAWER SPORT FACILITIES`,
      "T33:2020-2021",
      "2021-02-05",
      "10:07",
      "2021-03-08",
      "No later than 12:00",
      `16 February 2021 at 11:00 at the Klawer North Community Hall`,
      `N/A`,
      "/assets/Tenders/2020-21/T33-2020-2021.pdf",
      "Ruwacon",
      "1",
      "R23 300 000",
      "Closed"
    ),
    new TendersModule(
      `T35:2020-2021 SUPPLY AND DELIVERY OF SAFETY CLOTHING FOR A PERIOD OF ONE (1) YEAR WITH THE POSIBILITY TO  EXTENT FOR EXTRA 2 YEARS `,
      "T35:2020-2021",
      "2021-01-29",
      "09:21",
      "2021-03-01",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/T35-2020-2021.pdf",
      "NOT AWARDED",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `T34:2020-2021 APPOINTMENT OF A PANEL OF ATTORNEYS FOR THE RENDERING OF LEGAL SERVICES FOR A PERIOD ENDING 30 JUNE 2023`,
      "T34:2020-2021",
      "2021-01-29",
      "09:16",
      "2021-03-01",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/T34-2020-2021.pdf",
      "NOT AWARDED",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ39:2020-2021 TRAINING: SMALL MACHINE OPERATOR TRAINING `,
      "FQ39:2020-2021",
      "2021-01-20",
      "11:13",
      "2021-01-26",
      "No later than 12:00",
      `NOT AWARDED`,
      `N/A`,
      "/assets/Tenders/2020-21/FQ39-2020-2021.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ38:2020-2021 TRAINING: SUPERVISORY MANAGEMENT `,
      "FQ38:2020-2021",
      "2021-01-20",
      "11:20",
      "2021-01-26",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/FQ38-2020-2021.pdf",
      "NOT AWARDED",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ37:2020-2021 CONTRACTUAL SERVICE FOR PROFESSIONAL SERVICE PROVIDERS TO INVESTIGATE
      DISCIPLINARY PROCESSES TO EXPEDITE FINALIZATION OF ALL OUTSTANDING DISCIPLINARY CASES AT
      MATZIKAMA MUNICIPALITY FOR INITIAL PERIOD OF 3 MONTHS `,
      "FQ37:2020-2021",
      "2021-01-15",
      "15:27",
      "2021-01-26",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/FQ37-2020-2021.pdf",
      "NOT AWARDED",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ36:2020-2021 SUPPLY AND DELIVERY OF MATERIALS FOR MAINTENACE AND UPGRADE OF MUNICIPAL BUILDINGS`,
      "FQ36:2020-2021",
      "2020-11-30",
      "11:56",
      "2020-12-09",
      "No later than 12:00",
      `Friday 4 December at 10:00 at the Vredendal – North Cemetery `,
      `N/A`,
      "/assets/Tenders/2020-21/FQ36-2020-2021.pdf",
      "Kaap Agri",
      "3",
      "R 188 962,34",
      "Closed"
    ),
    new TendersModule(
      `FQ34:2020-2021 LABOUR COST FOR BUILING OF GRAVE PLOTS`,
      "FQ34:2020-2021",
      "2020-11-30",
      "11:53",
      "2020-12-09",
      "No later than 12:00",
      `4 December 2020 at 10:00 at the Vredendal North Cemetery`,
      `N/A`,
      "/assets/Tenders/2020-21/FQ34-2020-2021.pdf",
      "Niklaas Valentyn",
      "1",
      "R 2 800,00 per plot",
      "Closed"
    ),
    new TendersModule(
      `T32:2020-2021 SECURITY SERVICES TO MATZIKAMA MUNICIPALITY  FOR ONE YEAR WITH THE POSIBILLITY
      TO EXTENT FOR ANOTHER TWO YEARS.`,
      "T32:2020-2021",
      "2020-11-27",
      "19:12",
      "2020-12-14",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/T32-2020-2021.pdf",
      "NOT AWARDED",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ29:2020-2021 APPOINTMENT OF AN AUTIONEER`,
      "FQ29:2020-2021",
      "2020-11-19",
      "11:04",
      "2020-11-26",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/FQ29-2020-2021.pdf",
      "NOT AWARDED",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ28:2020-2021 SUPPLY AND DELIVERY OF  FILTERSAND (10/30) `,
      "FQ28:2020-2021",
      "2020-11-17",
      "09:10",
      "2020-11-24",
      "No later than 12:00",
      `N/A`,
      `N/A`,
      "/assets/Tenders/2020-21/FQ28-2020-2021.pdf",
      "Africa Distributors",
      "1",
      "R199 934,46",
      "Closed"
    ),
    new TendersModule(
      `EOI /2020/2021 EXPRESSION OF INTEREST FOR VARIOUS MUNICIPAL PROJECTS `,
      "EOI /2020/2021",
      "2020-11-13",
      "10:18",
      "2020-12-14",
      "No later than 12:00",
      `Matzikama Municipality invites interested contractors for the Expression of Interest for various municipal projects.
      Potentially emerging contractors who satisfy criteria stated in the Expression of Interest pack may be contacted to
      submit quotations for works in future.`,
      `N/A`,
      "/assets/Tenders/2020-21/EOI20202021.pdf",
      "N/A",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `T31:2020-2021 APPOINTMENT OF A SERVICE PROVIDER FOR AN AUTOMATED RISK AND PERFORMANCE MANAGEMENT SYSTEM FOR THE PERIOD
      OF 3 YEARS `,
      "T31:2020-2021",
      "2020-11-13",
      "10:18",
      "2020-12-14",
      "No later than 12:00",
      `N/A`,
      `T31:2020-2021 APPOINTMENT OF A SERVICE PROVIDER FOR AN AUTOMATED RISK AND PERFORMANCE MANAGEMENT SYSTEM FOR THE PERIOD
      OF 3 YEARS `,
      "/assets/Tenders/2020-21/T31-2020-2021.pdf",
      "NOT AWARDED",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ35:2020-2021  SUPPLY AND DELIVERY OF MATERIALS FOR MAINTENACE AND UPGRADE OF MUNICIPAL BUILDINGS `,
      "FQ35:2020-2021",
      "2020-11-10",
      "17:17",
      "2020-11-18",
      "No later than 12:00",
      `N/A`,
      `FQ35:2020-2021  SUPPLY AND DELIVERY OF MATERIALS FOR MAINTENACE AND UPGRADE OF MUNICIPAL BUILDINGS `,
      "/assets/Tenders/2020-21/FQ35-2020-2021.pdf",
      "NOT AWARDED",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `FQ33:2020-2021  SUPPLY AND DELIVERY OF ELECTRICAL PREPAID METERS `,
      "FQ33:2020-2021",
      "2020-11-10",
      "17:02",
      "2020-11-18",
      "No later than 12:00",
      `N/A`,
      `FQ33:2020-2021  SUPPLY AND DELIVERY OF ELECTRICAL PREPAID METERS `,
      "/assets/Tenders/2020-21/FQ33-2020-2021.pdf",
      "Landis and Gyr",
      "1",
      "R176 847,00",
      "Closed"
    ),
    new TendersModule(
      `FQ32:2020-2021  SUPPLY AND DELIVERY OF TWO RIDE ON LAWN MOWERS`,
      "FQ32:2020-2021",
      "2020-11-10",
      "16:56",
      "2020-11-18",
      "No later than 12:00",
      `N/A`,
      `FQ32:2020-2021  SUPPLY AND DELIVERY OF TWO RIDE ON LAWN MOWERS`,
      "/assets/Tenders/2020-21/FQ32-2020-2021.pdf",
      "RDB Building and Construction",
      "1",
      "R 124 875",
      "Closed"
    ),
    new TendersModule(
      `FQ31:2020-2021  SUPPLY AND DELIVERY OF BLACK REFUSE BAGS`,
      "FQ31:2020-2021",
      "2020-11-10",
      "16:53",
      "2020-11-18",
      "No later than 12:00",
      `N/A`,
      `FQ31:2020-2021  SUPPLY AND DELIVERY OF BLACK REFUSE BAGS`,
      "/assets/Tenders/2020-21/FQ31-2020-2021.pdf",
      "Memotek Trading",
      "1",
      "R57 981,53",
      "Closed"
    ),
    new TendersModule(
      `FQ30:2020-2021  SUPPLY AND DELIVERY OF 100 x 19KG LP GAS TO STRANDFONTEIN `,
      "FQ30:2020-2021",
      "2020-11-10",
      "16:47",
      "2020-11-18",
      "No later than 12:00",
      `N/A`,
      `FQ30:2020-2021  SUPPLY AND DELIVERY OF 100 x 19KG LP GAS TO STRANDFONTEIN `,
      "/assets/Tenders/2020-21/FQ30-2020-2021.pdf",
      "JFD Lochner",
      "0",
      "R34 960,00",
      "Closed"
    ),
    new TendersModule(
      `FQ27:2020-2021  SUPPLY AND DELIVERY OF SODUIM HYPOCHLORITE SOLUTION (Concentration 12%)`,
      "FQ27:2020-2021",
      "2020-11-11",
      "14:05",
      "2020-11-19",
      "No later than 12:00",
      `N/A`,
      `FQ27:2020-2021  SUPPLY AND DELIVERY OF SODUIM HYPOCHLORITE SOLUTION (Concentration 12%)`,
      "/assets/Tenders/2020-21/FQ27-2020-2021.pdf",
      "Memotek",
      "1",
      "R36 379.56",
      "Closed"
    ),
    new TendersModule(
      `FQ26:2020-2021  SUPPLY AND DELIVERY OF CALCIUM HYPOCHLORITE (Chips, Tables and Granules)`,
      "FQ26:2020-2021",
      "2020-11-10",
      "16:37",
      "2020-11-18",
      "No later than 12:00",
      `N/A`,
      `FQ26:2020-2021  SUPPLY AND DELIVERY OF CALCIUM HYPOCHLORITE (Chips, Tables and Granules)`,
      "/assets/Tenders/2020-21/FQ26-2020-2021.pdf",
      "Memotek",
      "1",
      "R102 275.76",
      "Closed"
    ),
    new TendersModule(
      `FQ25:2020-2021  SUPPLY AND DELIVERY OF SODA ASH (Sodium Carbonate) DENSE`,
      "FQ25:2020-2021",
      "2020-11-10",
      "16:33",
      "2020-11-18",
      "No later than 12:00",
      `N/A`,
      `FQ25:2020-2021  SUPPLY AND DELIVERY OF SODA ASH (Sodium Carbonate) DENSE`,
      "/assets/Tenders/2020-21/FQ25-2020-2021.pdf",
      "NOT AWARDED",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `T30:2020-2021  UPGRADING OF MATZIKAMA SEWER PUMP STATIONS : PHASE 2`,
      "T30:2020/2021",
      "2020-11-06",
      "12:53",
      "2020-12-07",
      "No later than 12:00",
      `16 November 2020 at 14:00 at Council Chambers, Matzikama Municipality, 37 Church Street, Vredendal`,
      ``,
      "/assets/Tenders/2020-21/T30-2020-2021.pdf",
      "PR Civils",
      "1",
      "R19 381 390,81",
      "Closed"
    ),
    new TendersModule(
      `FQ24:2020/2021 SUPPLY AND DELIVERY OF TRAFFIC/LAW ENFORCEMENT UNIFORMS FOR THE PERIOD UNTIL 30 JUNE 2021`,
      "FQ24:2020/2021",
      "2020-10-06",
      "10:39",
      "2020-10-13",
      "No later than 12:00",
      ``,
      `FQ24:2020/2021 SUPPLY AND DELIVERY OF TRAFFIC/LAW ENFORCEMENT UNIFORMS FOR THE PERIOD UNTIL 30 JUNE 2021`,
      "/assets/Tenders/2020-21/FQ24-2020-2021.pdf",
      "Blackbird Trading 480 CC",
      "1",
      "As indicated in bid documents",
      "Closed"
    ),
    new TendersModule(
      `T29:2020/2021 APPOINTMENT OF A SERVICE PROVIDER TO CONDUCT A VALUE ADDED TAX(VAT) REVIEW FOR MATZIKAMA MUNICIPALITY`,
      "T29:2020/2021",
      "2020-10-02",
      "11:34",
      "2020-10-20",
      "No later than 12:00",
      ``,
      `T29:2020/2021 APPOINTMENT OF A SERVICE PROVIDER TO CONDUCT A VALUE ADDED TAX(VAT)REVIEW FOR MATZIKAMA MUNICIPALITY`,
      "/assets/Tenders/2020-21/T29-2020-2021.pdf",
      "OMA Chartered Accountants",
      "1",
      "5,175%",
      "Closed"
    ),
    new TendersModule(
      `T16:2020/2021 UPGRADING OF WATER AND SEWER INFRASTRUCTURE IN PAPENDORP `,
      "T16:2020/2021",
      "2020-10-02",
      "11:09",
      "2020-10-30",
      "No later than 12:00",
      `13 October 2020 @ EBENHAEZER COMMUNITY HALL at 11:00`,
      `T16:2020/2021 UPGRADING OF WATER AND SEWER INFRASTRUCTURE IN PAPENDORP`,
      "/assets/Tenders/2020-21/T16-2020-2021.pdf",
      "Amandla Khubeka JV",
      "1",
      "R10 325 548,59",
      "Closed"
    ),
    new TendersModule(
      `FQ23:2020/2021 CONTRACTUAL SERVICE FOR PROFESSIONAL SERVICE PROVIDERS TO DEAL WITH FELLING TREE
      & PRUNING AT MATZIKAMA MUNICIPALITY UNTIL 30 JUNE 2021`,
      "FQ23:2020/2021",
      "2020-09-17",
      "10:22",
      "2020-09-28",
      "No later than 12:00",
      ``,
      `FQ23:2020/2021 CONTRACTUAL SERVICE FOR PROFESSIONAL SERVICE PROVIDERS TO DEAL WITH FELLING TREE
      & PRUNING AT MATZIKAMA MUNICIPALITY UNTIL 30 JUNE 2021 `,
      "/assets/Tenders/2020-21/FQ23-2020-2021.pdf",
      "NOT AWARDED",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `T28:2020/2021 MUNICIPAL PROPERTIES FOR SALE `,
      "T28:2020/2021",
      "2020-09-10",
      "17:05",
      "2020-10-01",
      "No later than 12:00",
      ``,
      `MUNICIPAL PROPERTIES FOR SALE `,
      "/assets/Tenders/2020-21/T28-2020-2021.pdf",
      "NOT AWARDED",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      `T27:2020/2021 THE SUPPLY AND DELIVERY OF ROAD STONE AND MATERIAL FOR ROAD MAINTENANCE IN THE MATZIKAMA AREA
       30 JUNE 2021 `,
      "T27:2020/2021",
      "2020-09-09",
      "16:13",
      "2020-10-30",
      "No later than 12:00",
      ``,
      `THE SUPPLY AND DELIVERY OF ROAD STONE AND MATERIAL FOR ROAD MAINTENANCE IN THE MATZIKAMA AREA
      30 JUNE 2021 `,
      "/assets/Tenders/2020-21/T27-2020-2021.pdf",
      "Tru Tru Trading (Pty) Ltd",
      "2",
      "R341 952.50",
      "Closed"
    ),
    new TendersModule(
      `T26:2020/2021 THE HIRING OF MACHINERY FOR THE MATZIKAMA MUNICIPALITY LANDFILL SITES TO PERFORM CONSTRUCTION
      MAINTENANCE WORK UNTIL 30 JUNE 2021 `,
      "T26:2020/2021",
      "2020-09-09",
      "16:07",
      "2020-10-30",
      "No later than 12:00",
      ``,
      `THE HIRING OF MACHINERY FOR THE MATZIKAMA MUNICIPALITY LANDFILL SITES TO PERFORM CONSTRUCTION
      MAINTENANCE WORK UNTIL 30 JUNE 2021 `,
      "/assets/Tenders/2020-21/T26-2020-2021.pdf",
      "Aqua Transport",
      "1",
      "R 715 587,50",
      "Closed"
    ),
    new TendersModule(
      `T25:2020/2021 THE HIRING OF A GRADER AND ROLLER FOR THE MATZIKAMA MUNICIPALITY AREA TO PERFORM CONSTRUCTION
      MAINTENANCE WORK ON GRAVEL ROADS UNTILL 30 JUNE 2021 `,
      "T25:2020/2021",
      "2020-09-09",
      "16:04",
      "2020-10-30",
      "No later than 12:00",
      ``,
      `HIRING OF A GRADER AND ROLLER FOR THE MATZIKAMA MUNICIPALITY AREA TO PERFORM CONSTRUCTION
      MAINTENANCE WORK ON GRAVEL ROADS UNTILL 30 JUNE 2021`,
      "/assets/Tenders/2020-21/T25-2020-2021.pdf",
      "Aqua Transport",
      "1",
      "R259 164,00",
      "Closed"
    ),
    new TendersModule(
      `T24:2020/2021 SUPPLY AND DELIVERY OF BITUMENT PRODUCTS FOR ROAD MAINTENANCE IN THE MATZIKAMA AREA
      UNTIL 30 JUNE 2021 `,
      "T24:2020/2021",
      "2020-09-09",
      "16:01",
      "2020-10-30",
      "No later than 12:00",
      ``,
      `SUPPLY AND DELIVERY OF BITUMENT PRODUCTS FOR ROAD MAINTENANCE IN THE MATZIKAMA AREA
      UNTIL 30 JUNE 2021`,
      "/assets/Tenders/2020-21/T24-2020-2021.pdf",
      "Colas South Africa (Pty) Ltd",
      "1",
      "R 343 528 and R5750.00 per delivery trip",
      "Closed"
    ),
    new TendersModule(
      `T23:2020/2021 REPAIR OF SUBMERSIBLE SEWERAGE PUMPS FOR MATZIKAMA MUNICIPALITY UNTIL 30 JUNE 2021 FOR AN OPTION
      TO EXTEND FOR 2 YEARS `,
      "T23:2020/2021",
      "2020-09-09",
      "15:50",
      "2020-10-09",
      "No later than 12:00",
      ``,
      `REPAIR OF SUBMERSIBLE SEWERAGE PUMPS FOR MATZIKAMA MUNICIPALITY UNTIL 30 JUNE 2021 FOR AN OPTION
      TO EXTEND FOR 2 YEARS `,
      "/assets/Tenders/2020-21/T23-2020-2021.pdf",
      "M Bond Engineering",
      "1",
      "prices is per repair of items listed",
      "Closed"
    ),
    new TendersModule(
      `T18:2020/2021 UPGRADING OF OXIDATION PONDS, SEWER PIPELINES AND RESERVOIRS IN RIETPOORT, MOLSVLEI AND BITTERFONTEIN `,
      "T18:2020/2021",
      "2020-09-09",
      "15:44",
      "2020-09-30",
      "No later than 12:00",
      ` 17 SEPTEMBER 2020 at 12:00 at Rietpoort Community Hall`,
      `UPGRADING OF OXIDATION PONDS, SEWER PIPELINES AND RESERVOIRS IN RIETPOORT, MOLSVLEI AND BITTERFONTEIN `,
      "/assets/Tenders/2020-21/T18-2020-2021.pdf",
      "Joetsie",
      "1",
      "R 10 213 097,79",
      "Closed"
    ),
    new TendersModule(
      `FQ22:2020/2021 SERVICE, REPAIR AND REFILL OF FIRE EQUIPMENT FOR MATZIKAMA MUNICIPALITY FOR THE PERIOD ENDING 30 JUNE 2021 `,
      "FQ22:2020/2021",
      "2020-08-19",
      "16:50",
      "2020-08-26",
      "No later than 12:00",
      ``,
      `SERVICE, REPAIR AND REFILL OF FIRE EQUIPMENT FOR MATZIKAMA MUNICIPALITY FOR THE PERIOD ENDING 30 JUNE 2021`,
      "/assets/Tenders/2020-21/FQ22-202021.pdf",
      "ESS Fire & Medical Services",
      "0",
      "As indicated in bid documents",
      "Closed"
    ),
    new TendersModule(
      `T19:2020/2021 SUPPLY AND DELIVERY OF PERSONAL PROTECTIVE EQUIPMENT FOR PERIOD UNTIL 30 JUNE 2021
      WITH THE OPTION TO EXTEND FOR EXTRA 2 YEARS`,
      "T19:2020/2021",
      "2020-08-18",
      "13:13",
      "2020-09-01",
      "No later than 12:00",
      ``,
      `SUPPLY AND DELIVERY OF PERSONAL PROTECTIVE EQUIPMENT FOR PERIOD UNTIL 30 JUNE 2021
      WITH THE OPTION TO EXTEND FOR EXTRA 2 YEARS`,
      "/assets/Tenders/2020-21/T19-202021.pdf",
      "Blackbird Trading 480, Abafazi Babantu, Hychem",
      "1",
      "Item prices as listed in bid",
      "Closed"
    ),
    new TendersModule(
      "FQ21:2020/2021 COMPILATION OF ANNUAL PERFORMANCE REPORT 2019-2020 AND ANNUAL REPORT 2019-2020",
      "FQ21:2020/2021",
      "2020-07-30",
      "16:21",
      "2020-08-06",
      "No later than 12:00",
      ``,
      `COMPILATION OF ANNUAL PERFORMANCE REPORT 2019-2020 AND ANNUAL REPORT 2019-2020`,
      "/assets/Tenders/FQ21-2020-21.pdf",
      "Ignite Advisory",
      "0",
      "R120 750,00",
      "Closed"
    ),
    new TendersModule(
      "T09/2020  UPGRADING OF ROADS AND STORMWATER INFRASTRUCTURE IN UITKYK, LUTZVILLE - PHASE 1.2",
      "T09/2020",
      "2020-07-17",
      "18:58",
      "2020-08-07",
      "No later than 12:00",
      ``,
      `UPGRADING OF ROADS AND STORMWATER INFRASTRUCTURE IN UITKYK, LUTZVILLE - PHASE 1.2`,
      "/assets/Tenders/T09-2020.pdf",
      "SR Civils",
      "1",
      "R3 393 251.81",
      "Closed"
    ),
    new TendersModule(
      "FQ20:2020-21 APPOINTMENT OF A CONSULTANT TO HANDLE AND EVALUATE THE CHIEF FINANCIAL OFFICER POSITION",
      "FQ20:2020-21",
      "2020-07-14",
      "17:01",
      "2020-07-21",
      "No later than 12:00",
      ``,
      `APPOINTMENT OF A CONSULTANT TO HANDLE AND EVALUATE THE CHIEF FINANCIAL OFFICER POSITION`,
      "/assets/Tenders/FQ20-2020-21.pdf",
      "N/A",
      "N/A",
      "Not Awarded",
      "Closed"
    ),
    new TendersModule(
      "T15/2020 ANALYTICAL ANALYSIS OF WATER AND WASTE WATER SAMPLES FOR THE PERIOD UNTIL 30 JUNE 2021",
      "T15/2020",
      "2020-07-01",
      "15:33",
      "2020-08-03",
      "No later than 12:00",
      ``,
      `SUPPLY AND DELIVERY OF CHLORINE GAS FOR WATER AND SEWER WATER FOR MATZIKAMA MUNICIPALITY  UNTIL 30 JUNE 2021`,
      "/assets/Tenders/T15-2020.pdf",
      "A.L Abbott and Associates",
      "2",
      "R 1 895.00 per sample",
      "Closed"
    ),
    new TendersModule(
      "T14/2020 SUPPLY AND DELIVERY OF CHLORINE GAS FOR WATER AND SEWER WATER FOR MATZIKAMA MUNICIPALITY  UNTIL 30 JUNE 2021",
      "T14/2020",
      "2020-07-01",
      "15:31",
      "2020-08-03",
      "No later than 12:00",
      ``,
      `SUPPLY AND DELIVERY OF CHLORINE GAS FOR WATER AND SEWER WATER FOR MATZIKAMA MUNICIPALITY  UNTIL 30 JUNE 2021`,
      "/assets/Tenders/T14-2020.pdf",
      "Jevdo",
      "1",
      "R 35 065.80 per delivery",
      "Closed"
    ),
    new TendersModule(
      "T13/2020 SUPPLY AND DELIVERY OF FILTERSAND FOR MATZIKAMA MUNICIPALITY  UNTIL 30 JUNE 2021",
      "T13/2020",
      "2020-07-01",
      "15:26",
      "2020-08-03",
      "No later than 12:00",
      ``,
      `SUPPLY AND DELIVERY OF FILTERSAND FOR MATZIKAMA MUNICIPALITY  UNTIL 30 JUNE 2021`,
      "/assets/Tenders/T13-2020.pdf",
      "NOT AWARDED",
      "N/A",
      "N/A",
      "Closed"
    ),
    new TendersModule(
      "T08/2020 UPGRADING OF ROADS AND STORMWATER INFRASTRUCTURE IN VANRHYNSDORP, MASKAMSIG - PHASE 4",
      "T08/2020",
      "2020-06-15",
      "14:55",
      "2020-07-06",
      "No later than 12:00",
      `23 June  2020 at 11:00 at the Municipal Offices in Vanrhynsdorp`,
      `UPGRADING OF ROADS AND STORMWATER INFRASTRUCTURE IN VANRHYNSDORP, MASKAMSIG - PHASE 4`,
      "/assets/Tenders/T08-2020.pdf",
      "PR Civils",
      "1",
      "R 4 327 153.88",
      "Closed"
    ),
    new TendersModule(
      "T12/2020 LIFE INSURANCE SERVICES FOR MATZIKAMA MUNICIPALITY.",
      "T12/2020",
      "2020-05-29",
      "15:36",
      "2020-06-30",
      "No later than 12:00",
      `N/A`,
      `LIFE INSURANCE SERVICES FOR MATZIKAMA MUNICIPALITY.`,
      "/assets/Tenders/T12-2020.pdf",
      "Momentum",
      "1",
      "R182 017,45",
      "Closed"
    ),
    new TendersModule(
      "T11/2020 SUPPLY OF SPEED LAW ENFORCEMENT EQUIPMENT AND PROVIDING OF BACK UP OFFICE FOR COLLECTION OF TRAFFIC AND MUNICIPAL BY LAW FINES.",
      "T11/2020",
      "2020-04-09",
      "15:23",
      "2020-05-18",
      "No later than 12:00",
      `N/A`,
      `SUPPLY OF SPEED LAW ENFORCEMENT EQUIPMENT AND PROVIDING OF BACK UP OFFICE FOR COLLECTION OF TRAFFIC AND MUNICIPAL BY LAW FINES.`,
      "/assets/Tenders/T11-2020 web advert.pdf",
      "HH Equity Holdings and Kgatelope Traffic Solutions PTY LTD",
      " ",
      "R110 per paid fine",
      "Closed"
    ),
    new TendersModule(
      "FQ18/2020 APPOINTMENT OF A CONSULTANT TO HANDLE AND EVALUATE THE MUNICIPAL MANAGER POSITION",
      "FQ18/2020",
      "2020-03-31",
      "15:45",
      "2020-05-08",
      "No later than 12:00",
      `N/A`,
      `APPOINTMENT OF A CONSULTANT TO HANDLE AND EVALUATE THE MUNICIPAL MANAGER POSITION`,
      "/assets/Tenders/FQ18-2020.pdf",
      "Roy Steele and Associates",
      "4",
      "R 36 000.00",
      "Closed"
    ),
    new TendersModule(
      "T10/2020 PROVISION OF SECURITY SERVICES FOR MATZIKAMA MUNICIPALITY",
      "T10/2020",
      "2020-03-26",
      "11:14",
      "2020-05-27",
      "No later than 12:00",
      `N/A`,
      `PROVISION OF SECURITY SERVICES FOR MATZIKAMA MUNICIPALITY`,
      "/assets/Tenders/T10-2020 SECURITY.pdf",
      "N/A",
      "N/A",
      "Not Awarded",
      "Closed"
    ),
    new TendersModule(
     "FQ17/2020 CIVIL WORKS FOR THE UPGRADING OF VREDENDAL SEWAGE PUMP STATION:PHASE1(RE-ADVERTISED)",
     "FQ17/2020",
     "2020-03-19",
     "12:30",
     "2020-03-26",
     "No later than 12:00",
     `N/A`,
     `CIVIL WORKS FOR THE UPGRADING OF VREDENDAL SEWAGE PUMP STATION:PHASE1
     (RE-ADVERTISED)`,
     "/assets/Tenders/FQ17-2020.pdf",
     "PR Civils",
     "1",
     "R199 406.55",
     "Closed"
   ),
    new TendersModule(
     "FQ16/2020 SUPPLY,DELIVERY AND INSTALLATION OF FENCING MATERIAL FOR THE UPGRADING OF VREDENDAL PUMP STATION:PHASE1(RE-ADVERTISED)",
     "FQ16/2020",
     "2020-03-19",
     "12:27",
     "2020-03-26",
     "No later than 12:00",
     `N/A`,
     `SUPPLY,DELIVERY AND INSTALLATION OF FENCING MATERIAL FOR THE UPGRADING OF VREDENDAL PUMP STATION:PHASE1
     (RE-ADVERTISED)`,
     "/assets/Tenders/FQ16-2020.pdf",
     "PR Civils",
     "1",
     "R173 305.00",
     "Closed"
   ),
    new TendersModule(
     "FQ15/2020 ALTERATIONS TO KOELBLY PUMP STATION BUILDING FOR THE UPGRADING OF VREDENDAL SEWAGE PUMP STATION:PHASE1(RE-ADVERTISED)",
     "FQ15/2020",
     "2020-03-19",
     "12:26",
     "2020-03-26",
     "No later than 12:00",
     `N/A`,
     `ALTERATIONS TO KOELBLY PUMP STATION BUILDING FOR THE UPGRADING OF VREDENDAL SEWAGE PUMP STATION:PHASE1
     (RE-ADVERTISED)`,
     "/assets/Tenders/FQ15-2020.pdf",
     "N/A",
     "N/A",
     "Not Awarded",
     "Closed"
   ),
    new TendersModule(
     "T07/2020 ACCOUNTING SUPPORT SERVICES FOR ANNUAL FINANCIAL STATEMENTS AND UNBUNDLING OF INFRASTRUCTURE ASSETS",
     "T07/2020",
     "2020-03-12",
     "13:44",
     "2020-05-08",
     "No later than 12:00",
     `N/A`,
     `ACCOUNTING SUPPORT SERVICES FOR ANNUAL FINANCIAL STATEMENTS AND UNBUNDLING OF INFRASTRUCTURE ASSETS`,
     "/assets/Tenders/T07-2020.pdf",
     "Mubesko Africa",
     "0",
     "R414000",
     "Closed"
   ),
    new TendersModule(
      "T05/2020 BANKING SERVICES",
      "T05/2020",
      "2020-02-24",
      "15:59",
      "2020-05-29",
      "No later than 12:00",
      `N/A`,
      `BANKING SERVICES`,
      "/assets/Tenders/T05-2020.pdf",
      "STANDARD BANK",
      "1",
      "Cash in Transito- R 26 642.19 + tarrifs as in MBD3",
      "Closed"
    ),
    new TendersModule(
     "FQ14/2020 CIVIL WORKS FOR THE UPGRADING OF VREDENDAL SEWAGE PUMP STATION:PHASE1(NOT AWARDED. RE-ADVERTISED)",
     "FQ14/2020",
     "2020-03-09",
     "11:53",
     "2020-03-16",
     "No later than 12:00",
     `N/A`,
     `CIVIL WORKS FOR THE UPGRADING OF VREDENDAL SEWAGE PUMP STATION:PHASE1`,
     "/assets/Tenders/FQ14-2020.pdf",
     "(NOT AWARDED. RE-ADVERTISED)",
     "(NOT AWARDED. RE-ADVERTISED)",
     "NOT AWARDED. RE-ADVERTISED",
     "Closed"
   ),
    new TendersModule(
     "FQ13/2020 SUPPLY,DELIVERY AND INSTALLATION OF FENCING MATERIAL FOR THE UPGRADING OF VREDENDAL PUMP STATION:PHASE1(NOT AWARDED. RE-ADVERTISED)",
     "FQ13/2020",
     "2020-03-09",
     "11:56",
     "2020-03-16",
     "No later than 12:00",
     `N/A`,
     `SUPPLY,DELIVERY AND INSTALLATION OF FENCING MATERIAL FOR THE UPGRADING OF VREDENDAL PUMP STATION:PHASE1`,
     "/assets/Tenders/FQ13-2020.pdf",
     "(NOT AWARDED. RE-ADVERTISED)",
     "(NOT AWARDED. RE-ADVERTISED)",
     "NOT AWARDED. RE-ADVERTISED",
      "Closed"
   ),
    new TendersModule(
     "FQ11/2020 ALTERATIONS TO KOELBLY PUMP STATION BUILDING FOR THE UPGRADING OF VREDENDAL SEWAGE PUMP STATION:PHASE1(NOT AWARDED. RE-ADVERTISED)",
     "FQ11/2020",
     "2020-03-09",
     "11:59",
     "2020-03-19",
     "No later than 12:00",
     `N/A`,
     `ALTERATIONS TO KOELBLY PUMP STATION BUILDING FOR THE UPGRADING OF VREDENDAL SEWAGE PUMP STATION:PHASE1`,
     "/assets/Tenders/FQ11-2020.pdf",
     "NOT AWARDED. RE-ADVERTISED",
     "NOT AWARDED. RE-ADVERTISED",
     "NOT AWARDED. RE-ADVERTISED",
      "Closed"
   ),
    new TendersModule(
     "T04/2020 APPOINTMENT OF AN AUCTIONEER FOR SALE OF MUNICIPAL PROPERTY",
     "T04/2020",
     "2020-02-20",
     "15:42",
     "2020-03-20",
     "No later than 12:00",
     `N/A`,
     `APPOINTMENT OF AN AUCTIONEER FOR SALE OF MUNICIPAL PROPERTY`,
     "/assets/Tenders/T04-2020.pdf",
     "Home and Hectare",
     "5",
     "4,6%",
      "Closed"
   ),

     new TendersModule(
     "FQ12/2020 APPOINTMENT OF A CONSULTANT FOR THE COMPILATION OF MSCOA BUSINESS PROCESS PROCEDURES",
     "FQ12/2020",
     "2020-02-19",
     "14:07",
     "2020-02-26",
     "No later than 12:00",
     `N/A`,
     `APPOINTMENT OF A CONSULTANT FOR THE COMPILATION OF MSCOA BUSINESS PROCESS PROCEDURES`,
     "/assets/Tenders/FQ12-2020.pdf",
     "Gerald Seas",
     "0",
     "R165 000",
      "Closed"
   ),
   new TendersModule(
     "T01/2020 MUNICIPAL PROPERTIES FOR SALE",
     "T01/2020",
     "2020-02-14",
     "14:28",
     "2020-03-06",
     "No later than 12:00",
     `N/A`,
     `MUNICIPAL PROPERTIES FOR SALE`,
     "/assets/Tenders/TENDER AWARDS OF T01-2020.pdf",
     "Download full list of awards made  on the link below.",
     "N/A",
     "N/A",
      "Closed"
   ),
   new TendersModule(
     "T03/2020 RENTAL OF MUNICIPAL AGRICULTURAL IRRIGATION WATER FROM THE LORWUA SCHEME",
     "T03/2020",
     "2020-02-14",
     "13:49",
     "2020-02-28",
     "No later than 12:00",
     `N/A`,
     `RENTAL OF MUNICIPAL AGRICULTURAL IRRIGATION WATER FROM THE LORWUA SCHEME`,
     "/assets/Tenders/T03-2020.pdf",
     "N/A",
     "N/A",
     "Not Awarded",
      "Closed"
   ),
   new TendersModule(
     "FQ10/2020 APPOINTMENT OF A CONSULTANT TO HANDLE AND EVALUATE THE MUNICIPAL MANAGER POSITION",
     "FQ10/2020",
     "2020-02-14",
     "13:44",
     "2020-02-21",
     "No later than 12:00",
     `N/A`,
     `APPOINTMENT OF A CONSULTANT TO HANDLE AND EVALUATE THE MUNICIPAL MANAGER POSITION`,
     "/assets/Tenders/FQ10-2020.pdf",
     "N/A",
     "N/A",
     "Not Awarded",
      "Closed"
   ), new TendersModule(
    "FQ08/2020 SUPPLY AND DELIVERY OF PINE TRANSMISSION POLES(160/180)(RE-ADVERTISED)",
    "FQ08/2020",
    "2020-02-06",
    "11:26",
    "2020-02-13",
    "12:00",
    `N/A`,
    `SUPPLY AND DELIVERY OF PINE TRANSMISSION POLES(160/180)`,
    "/assets/Tenders/FQ02-2020.pdf",
    "Kaap Agri",
    "3",
    "R 70 242,00",
     "Closed"
  ),

  new TendersModule(
    "FQ09/2020 SUPPLY AND DELIVERY OF TWO RIDE ON LAWNMOWERS TO VREDENDAL (RE-ADVERTISED)",
    "FQ09/2020",
    "2020-02-06",
    "11:15",
    "2020-02-13",
    "12:00",
    `N/A`,
    `SUPPLY AND DELIVERY OF TWO RIDE ON LAWNMOWERS TO VREDENDAL`,
    "/assets/Tenders/FQ09-2020.pdf",
    "BEE Turf Suppliers",
    "2",
    "R 62 532,00",
     "Closed"
  ), new TendersModule(
    "FQ6/2020 APPOINTMENT OF A SERVICE PROVIDER FOR THE IMPLEMENTATION OF ELECTRICITY RATES(RE-ADVERTISED)",
    "FQ6/2020",
    "2020-02-06",
    "11:36",
    "2020-02-13",
    "12:00",
    `N/A`,
    `APPOINTMENT OF A SERVICE PROVIDER FOR THE IMPLEMENTATION OF ELECTRICITY RATES(RE-ADVERTISED)`,
    "/assets/Tenders/FQ06-2020.pdf",
    "Electrical Engery Management Systems",
    "0",
    "R 145 175",
     "Closed"
  ),
  new TendersModule(
    "FQ07/2020 SUPPLY AND DELIVERY OF HI-FOAM MATRESSES TO VREDENDAL (RE-ADVERTISED)",
    "FQ07/2020",
    "2020-02-06",
    "11:09",
    "2020-02-13",
    "12:00",
    `N/A`,
    `SUPPLY AND DELIVERY OF HI-FOAM MATRESSES TO VREDENDAL`,
    "/assets/Tenders/FQ07-2020.pdf",
    "Tops Meubels",
    "4",
    "R 86 160",
     "Closed"
  ),   new TendersModule(
    "FQ03/2020 LABOUR COSTS FOR UPGRADING OF MIRIAM OWIES COMMUNITY HALL AT DORING BAY",
    "FQ03/2020",
    "2020-01-31",
    "11:41",
    "2020-01-29",
    "12:00",
    `At Miriam Owies Community Hall in Doring Bay on 24 January 2020 at 11:00`,
    `LABOUR COSTS FOR UPGRADING OF MIRIAM OWIES COMMUNITY HALL AT DORING BAY`,
    "/assets/Tenders/FQ03-2020.pdf",
    "APA Supplies",
    "1",
    "R 70 850",
     "Closed"
  ),
   new TendersModule(
     "T02/2019 SUPPLY OF SPEED LAW ENFORCEMENT EQUIPMENT AND PROVIDING OF BACK UP OFFICE FOR COLLECTION OF TRAFFIC AND MUNICIPAL BY LAW FINES.",
     "T02/2019",
     "2020-02-12",
     "10:57",
     "2020-03-12",
     "No later than 12:00",
     `N/A`,
     `SUPPLY OF SPEED LAW ENFORCEMENT EQUIPMENT AND PROVIDING OF BACK UP OFFICE FOR COLLECTION OF TRAFFIC AND MUNICIPAL BY LAW FINES.`,
     "/assets/Tenders/T02-2020 WEB ADVERT.pdf",
     "N/A",
     "N/A",
     "N/A",
      "Not Awarded Readvertised"
   ),

   new TendersModule(
     "T34/2019 RE-ADVERTISEMENT OF UPGRADING OF VREDENDAL SOUTH WWTW: MECHANICAL AND ELECTRICAL WORKS",
     "T34/2019",
     "2020-01-28",
     "11:47",
     "2020-02-14",
     "12:00",
     `04 February 2020 at 10h00 Matzikama Municipal Technical Offices Boardroom,
     1 Sirkel Street, Vredendal South Industrial Area, Vredendal,8160`,
     `RE-ADVERTISEMENT OF UPGRADING OF VREDENDAL SOUTH WWTW: MECHANICAL AND ELECTRICAL WORKS`,
     "/assets/Tenders/T34-2019.pdf",
     "Hidro-Tech Systems",
     "2",
     "R5 278 608.45",
      "Closed"
   ),
   new TendersModule(
     "T36/2019 RE-ADVERTISEMENT OF NEW WATERBORNE SEWER SYSTEM AND ASSOCIATED INFRASTRUCTURE IN KLIPRAND",
     "T36/2019",
     "2020-01-28",
     "11:59",
     "2020-02-14",
     "12:00",
     `05 February 2020 at 11h00 at the Community Hall Kliprand`,
     `RE-ADVERTISEMENT OF NEW WATERBORNE SEWER SYSTEM AND ASSOCIATED INFRASTRUCTURE IN KLIPRAND`,
     "/assets/Tenders/T36-2019.pdf",
     "Ikapa Reticulation and Flow CC",
     "1",
     "R8 136 868.36",
      "Closed"
   ),
   new TendersModule(
     "T37/2019 RE-ADVERTISEMENT OF SUPPLY AND INSTALLATION SECTIONAL STEEL RESERVOIR IN KLIPRAND",
     "T37/2019",
     "2020-01-28",
     "12:05",
     "2020-02-14",
     "12:00",
     `05 February 2020 at 11h00 at the Community Hall Kliprand`,
     `RE-ADVERTISEMENT OF NEW WATERBORNE SEWER SYSTEM AND ASSOCIATED INFRASTRUCTURE IN KLIPRAND`,
     "/assets/Tenders/T37-2019.pdf",
     "Structa Technology (PTY) LTD",
     " 1 ",
     "R 544 522,13",
      "Closed"
   ),
    new TendersModule(
      "T38/2019 RE-ADVERTISEMENT CONSTRUCTION OF NEW TOILET STRUCTURES & ASSOCIATED INFRASTRUCTURE WORKS IN KLIPRAND",
      "T38/2019",
      "2020-01-28",
      "12:11",
      "2020-02-14",
      "12:00",
      `05 February 2020 at 11h00 at the Community Hall Kliprand`,
      `RE-ADVERTISEMENT CONSTRUCTION OF NEW TOILET STRUCTURES & ASSOCIATED INFRASTRUCTURE WORKS IN KLIPRAND`,
      "/assets/Tenders/T38-2019.pdf",
      "Ikapa Reticulation and Flow CC",
      "1",
      "R2 686 800.55",
       "Closed"
    ),
    new TendersModule(
      "FQ04/2020 SUPPLY AND DELIVERY OF HI-FOAM MATRESSES TO VREDENDAL",
      "FQ04/2020",
      "2020-01-22",
      "11:27",
      "2020-01-29",
      "12:00",
      `N/A`,
      `SUPPLY AND DELIVERY OF HI-FOAM MATRESSES TO VREDENDAL`,
      "/assets/Tenders/FQ04-2020.pdf",
      "N/A",
      "N/A",
      "Tender to be Re-advertised",
       "Closed"
    ),
    new TendersModule(
      "T29-2019 UPGRADING OF VREDENDAL SEWAGE PUMP STATIONS",
      "T29-2019",
      "2019-10-23",
      "09:00",
      "2019-11-13",
      "12:00",
      `29 October 2019 11:00 at Matzikama Municipality Technical Offices Boardroom,
      1 Sirkel Street, Vredendal South`,
      `UPGRADING OF VREDENDAL SEWAGE PUMP STATIONS: PHASE 1: MECHANICAL AND ELECTRICAL WORKS`,
      "/assets/Tenders/T29-2019 WEB ADVERT.pdf",
      "Hidro-Tech Systems",
      "2",
      "R6 742 075.56",
       "Closed"
    ),
    new TendersModule(
      "T28-2019 UPGRADING OF VREDENDAL SOUTH WWTW",
      "T28-2019",
      "2019-10-12",
      "13:20",
      "2019-11-01",
      "12:00",
      `18 October 2019 at 11:00 in the Matzikama Municipal Council Chambers`,
      `UPGRADING OF VREDENDAL SOUTH WWTW:CIVIL, MECHANICAL AND ELECTRICAL WORKS AND UPGRADING OF PUMP STATION:
       CIVIL WORK`,
      "/assets/Tenders/T28-2019 WEB ADVERT.pdf",
      "",
      "",
      "Not Awarded Readvertised",
       "Closed"
    ),
    new TendersModule(
      "T27-2019 CONSTRUCTION OF 1,0ML RESERVOIR AT KOEKENAAP",
      "T27-2019",
      "2019-09-19",
      "09:05",
      "2019-10-11",
      "12:00",
      `02 October 2019 at 11:00 in the Community Hall Koekenaap`,
      `CONSTRUCTION OF 1,0ML RESERVOIR AT KOEKENAAP`,
      "/assets/Tenders/T27-2019 WEB ADVERT.pdf",
      "EMPA Structures",
      "1",
      "R 4 794 620,40",
       "Closed"
    ),
    new TendersModule(
      "T26-2019 ROAD STONE AND ROAD BUILDING MATERIAL",
      "T26-2019",
      "2019-09-06",
      "10:25",
      "2019-10-07",
      "12:00",
      ``,
      `THE SUPPLY AND DELIVERY OF ROAD STONE AND ROAD BUILDING MATERIAL
       FOR ROAD MAINTENANCE IN THE MATZIKAMA AREA UNTILL 30 JUNE 2020`,
      "/assets/Tenders/t26.pdf",
      "TRU TRU TRADING",
      "2",
      "R 833 980",
       "Closed"
    ),
    new TendersModule(
      "T25-2019 BITUMEN PRODUCTS FOR ROAD MAINTENANCE",
      "T26-2019",
      "2019-09-06",
      "10:30",
      "2019-10-07",
      "12:00",
      ``,
      `SUPPLY AND DELIVERY OF BITUMEN PRODUCTS
       FOR ROAD MAINTENANCE IN THE MATZIKAMA AREA UNTILL 30 JUNE 2020`,
      "/assets/Tenders/t25.pdf",
      "TOSAS",
      " 1 ",
      "R754 032.00",
       "Closed"
    ),
    new TendersModule(
      "T21-2019 SUPPLY AND DELIVERY OF COMPUTER EQUIPMENT",
      "T21-2019",
      "2019-09-06",
      "12:15",
      "2019-10-07",
      "12:00",
      "Matzikama Head Office Vredendal",
      "T21-2019 SUPPLY AND DELIVERY OF COMPUTER EQUIPMENT FOR MATZIKAMA MUNICIPALITY UNTIL 30 JUNE 2020",
      "/assets/Tenders/T20-2019 NEW WATERBORNE SEWER SYSTEM AND ASSOCIATED INFRASTRUCTURE IN KLIPRAND.pdf",
      `Dekatshe Consulting`,
      `1`,
      `R 77 311.02`,
       "Closed"
    ),
    new TendersModule(
      "T20-2019 NEW WATERBORNE SEWER SYSTEM",
      "T20-2019",
      "2019-10-02",
      "16:15",
      "2019-10-22",
      "16:15",
      "10 October 2019 AT 11:00 AT THE COMMUNITY HALL KLIPRAND",
      "T20-2019 NEW WATERBORNE SEWER SYSTEM AND ASSOCIATED INFRASTRUCTURE",
      "/assets/Tenders/t21.pdf",
      "Ann Doe",
      "Level 2",
      "R900 000",
       "Closed"
    ),
    new TendersModule(
      "K1/2020 APPOINTMENT OF A CONSULTANT FOR A STRATEGIC PLANNING SESSION",
      "K1/2020",
      "2020-01-09",
      "13:35",
      "2020-01-15",
      "12:00",
      `N/A`,
      `UPGRADING OF VREDENDAL SEWAGE PUMP STATIONS: PHASE 1: MECHANICAL AND ELECTRICAL WORKS`,
      "/assets/Tenders/T30-2020 WEB ADVERT.pdf",
      "Kalideen Management Services",
      "0",
      "R 194 300.00",
       "Closed"
    )

  ];

  constructor() { }

  getAllTenders():TendersModule[]{
    return this.allTenders;
  }

  getAllArchivedTenders():TendersModule[]{
    return this.archivedTenders;
  }

}
