<div class="container">
    <h4 class="center">Valuation Related Documents</h4>
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true"> 
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                             <i class="material-icons">keyboard_arrow_down</i>
                             Matzikama Municipality General Valuation Roll 2025                  
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item >
                                Matzikama Municipality General Valuation Roll 2025                  
                                <a href="/assets/valuations/2024-25/MATZIKAMA GENERAL VALUATION ROLL 2025.pdf" mz-secondary-content download>
                                    {{genValFeb2025 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>   
                            </mz-collection-item>  
                            <mz-collection-item >
                                Valuation Roll Objection Form                 
                                <a href="/assets/valuations/2024-25/beswaar_vorm.pdf" mz-secondary-content download>
                                    {{beswaarFeb2025 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>   
                            </mz-collection-item>    
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
                <!-- End of Tussenskatting Collapsible & Collection -->   
            </mz-collapsible>  <!--End of second collapsible-->
        </div>
    </div>  
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true"> 
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                             <i class="material-icons">keyboard_arrow_down</i>
                             Supplementary Valuation Roll 4 - Matzikama Municipality 01 September 2019                   
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item >
                                Supplementary Valuation Roll 4 - Matzikama Municipality 01 September 2019
                                <a href="/assets/valuations/2023-24/Matzikama SV4 Combined.pdf" mz-secondary-content download>
                                    {{suppVal4Nov23 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>   
                            </mz-collection-item>      
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
                <!-- End of Tussenskatting Collapsible & Collection -->   
            </mz-collapsible>  <!--End of second collapsible-->
        </div>
    </div>    
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true"> 
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                             <i class="material-icons">keyboard_arrow_down</i>
                             Supplementary Valuation Roll 3 - Matzikama Municipality 01 September 2019                 
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item >
                                Supplementary Valuation Roll 3 - Matzikama Municipality 01 September 2019
                                <a href="/assets/valuations/2022-23/SV3 Combined Roll.pdf" mz-secondary-content download>
                                    {{suppVal3Feb23 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>   
                            </mz-collection-item>      
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
                <!-- End of Tussenskatting Collapsible & Collection -->   
            </mz-collapsible>  <!--End of second collapsible-->
        </div>
    </div>    
<div class="row">
    <div class="col s12 m12 l12">
        <mz-collapsible [mode]="'expandable'" [popout]="true"> 
            <mz-collapsible-item [active]="true">
                <mz-collapsible-item-header>
                         <i class="material-icons">keyboard_arrow_down</i>
                            Supplementary Valuation Roll 2 - Matzikama Municipality 01 September 2019                      
                </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item >
                            Supplementary Valuation Roll 2 - Matzikama Municipality 01 September 2019
                            <a href="/assets/valuations/2021-22/Combined SV2 Roll.pdf" mz-secondary-content download>
                                {{suppVal2Sept21 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>   
                        </mz-collection-item>      
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
            <!-- End of Tussenskatting Collapsible & Collection -->   
        </mz-collapsible>  <!--End of second collapsible-->
    </div>
    <div class="col s12 m12 l12">
                <!-- Start of Second collapsible -->
                <mz-collapsible
                [mode]="'expandable'"
                [popout]="true"> 
                    <mz-collapsible-item [active]="true">
                        <mz-collapsible-item-header>
                                 <i class="material-icons">keyboard_arrow_down</i>
                                    Supplementary Valuation Roll 1 - Matzikama Municipality 01 September 2019                        
                        </mz-collapsible-item-header>
                        <mz-collapsible-item-body>
                            <mz-collection>
                                <mz-collection-item >
                                    Supplementary Valuation Roll 1 - Matzikama Municipality 01 September 2019  
                                    <a href="/assets/valuations/2020-21/SV1 Valuation Roll Combined.pdf" mz-secondary-content download>
                                        {{suppValJan21 |date : 'MMM, dd yyyy, HH:mm: aaa'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>   
                                </mz-collection-item>      
                            </mz-collection>
                        </mz-collapsible-item-body>
                    </mz-collapsible-item>
                    <!-- End of Tussenskatting Collapsible & Collection -->   
                </mz-collapsible>  <!--End of second collapsible-->
        
        <mz-collapsible
        [mode]="'expandable'"
        [popout]="true">
        <mz-collapsible-item [active]="true">
        <mz-collapsible-item-header>
                 <i class="material-icons">keyboard_arrow_down</i>
                    General Valuation Roll - Matzikama Municipality - Effective from 1 July 2020  <span class="right-align">Upload Date : 13 February 2020 11:11 AM   </span>              
        </mz-collapsible-item-header>
        <mz-collapsible-item-body>
            <mz-collection>
                <mz-collection-item >
                    General Valuation Roll Advertisement
                        <a href="/assets/valuations/General Valuation/Matz Muns K2.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item>  
                <mz-collection-item >
                    Consolidated Roll
                        <a href="/assets/valuations/General Valuation/Combined Rolls.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item>  
                <mz-collection-item >
                    General Valuation: Vredendal
                        <a href="/assets/valuations/General Valuation/PublishedTown Vredendal.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item>
                <mz-collection-item >
                    General Valuation: Vanrhynsdorp RD
                        <a href="/assets/valuations/General Valuation/PublishedTown Vanrhynsdorp RD.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item>   
                 <mz-collection-item >
                    General Valuation: Vanrhynsdorp 
                        <a href="/assets/valuations/General Valuation/PublishedTown Vanrhynsdorp.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item>
                <mz-collection-item >
                    General Valuation: Lutzville 
                    <a href="/assets/valuations/General Valuation/PublishedTown Lutzville.pdf"mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item>   
                <mz-collection-item >
                    General Valuation: Ebenhaeser
                    <a href="/assets/valuations/General Valuation/PublishedTown Ebenezer.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item>
                <mz-collection-item >
                    General Valuation: Doringbaai
                    <a href="/assets/valuations/General Valuation/PublishedTown Doringbaai.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item>  
                <mz-collection-item >
                    General Valuation: Strandfontein
                    <a href="/assets/valuations/General Valuation/PublishedTown Louis Rood (Strandfontein).pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item> 
                <mz-collection-item >
                    General Valuation: Klawer
                    <a href="/assets/valuations/General Valuation/PublishedTown Klawer.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item> 
                <mz-collection-item >
                    General Valuation: Nuwerus
                    <a href="/assets/valuations/General Valuation/PublishedTown Nieuwe Rust.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item> 
                <mz-collection-item >
                    General Valuation: Bitterfontein
                    <a href="/assets/valuations/General Valuation/PublishedTown Bitterfontein.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item> 
                <mz-collection-item >
                    General Valuation: Rietpoort
                    <a href="/assets/valuations/General Valuation/PublishedTown Rietpoort.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item> 
                <mz-collection-item >
                    General Valuation: Kleinhoekiestraat
                    <a href="/assets/valuations/General Valuation/PublishedTown Unregistered Kleinhoekiestraat.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item> 
                <mz-collection-item >
                    General Valuation: Molsvlei
                    <a href="/assets/valuations/General Valuation/PublishedTown Unregistered Molsvleistraat.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item> 
                <mz-collection-item >
                    General Valuation: Put-se-Kloof
                    <a href="/assets/valuations/General Valuation/PublishedTown Unregistered Putsekloofstraat.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item> 
                <mz-collection-item >
                    General Valuation: Samsamshoekstraat
                    <a href="/assets/valuations/General Valuation/PublishedTown Unregistered Samsamshoekstraat.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item> 
                <mz-collection-item >
                    General Valuation: Stofkraal
                    <a href="/assets/valuations/General Valuation/PublishedTown Unregistered Stofkraalstraat.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item> 
                <mz-collection-item >
                    General Valuation: Suurnamesstraat
                    <a href="/assets/valuations/General Valuation/PublishedTown Unregistered Suurnamesstraat.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item> 
                <mz-collection-item >
                    General Valuation: Watertuinpersele
                    <a href="/assets/valuations/General Valuation/PublishedTown Unregistered Watertuinpersele.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item> 
                <mz-collection-item >
                    General Valuation: Olifantsrivier Settlement
                    <a href="/assets/valuations/General Valuation/PublishedTown Olifantsrivier Settlement.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item> 
                <mz-collection-item >
                    General Valuation: Kliprand
                    <a href="/assets/valuations/General Valuation/PublishedTown Kliprand.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item> 
                <mz-collection-item >
                    OBJECTION FORM : FORM A RESIDENTIAL
                        <a href="/assets/valuations/General Valuation/FORM A RESIDENTIAL.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item>
                <mz-collection-item >
                    OBJECTION FORM : FORM B PROPERTIES OTHER THAN RESIDENTIAL OR AGRICULTURAL
                        <a href="/assets/valuations/General Valuation/FORM B PROPERTIES OTHER THAN RESIDENTIAL OR AGRICULTURAL.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item>
                <mz-collection-item >
                    OBJECTION FORM : FORM C AGRICULTURAL HOLDINGS OR FARMS
                        <a href="/assets/valuations/General Valuation/FORM C AGRICULTURAL HOLDINGS OR FARMS.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item>       
                <mz-collection-item >
                    BESWAAR VORM : VORM A RESIDENSIEËL
                        <a href="/assets/valuations/General Valuation/VORM A RESIDENSIEEL.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item>
                <mz-collection-item >
                    BESWAAR VORM : VORM B EIENDOMME ANDERS AS RESIDENSIEËL OF LANDBOUKUNDIG
                        <a href="/assets/valuations/General Valuation/VORM B EIENDOMME ANDERS AS RESIDENSIEEL OF LANDBOUKUNDIG.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item>  
                <mz-collection-item >
                    BESWAAR VORM : VORM C LANDBOUHOEWES OF PLASE
                        <a href="/assets/valuations/General Valuation/VORM C LANDBOUHOEWES OF PLASE.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item>   
        </mz-collection>
        </mz-collapsible-item-body>
    </mz-collapsible-item> 
    <!-- End of General Valuation & Collection -->   
</mz-collapsible>  <!-- End of Valuation Collapsible     -->
        <!-- Start of Second collapsible -->
        <mz-collapsible
        [mode]="'expandable'"
        [popout]="true"> 
            <mz-collapsible-item [active]="true">
                <mz-collapsible-item-header>
                         <i class="material-icons">keyboard_arrow_down</i>
                           1st Supplementary Valuation 2019_20                        
                </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item >
                            Public Notice for inspection of valuation roll
                                <a href="/assets/valuations/Advertensie Aanvullende Waardasierol.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Tussenskatting 1STE 2018_20 DEELTITELSKEMA
                                <a href="/assets/valuations/Tussenskatting 1STE 2018_20 DEELTITELSKEMA.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Tussenskatting 1STE 2018_20 Doringbaai
                                <a href="/assets/valuations/Tussenskatting 1STE 2018_20 Doringbaai.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>       
                        <mz-collection-item >
                            Tussenskatting 1STE 2019_20 BITTERFONTEIN
                                <a href="/assets/valuations/Tussenskatting 1STE 2019_20 BITTERFONTEIN.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Tussenskatting 1STE 2019_20 Ebenezer
                                <a href="/assets/valuations/Tussenskatting 1STE 2019_20 Ebenezer.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>  
                        <mz-collection-item >
                            Tussenskatting 1STE 2019_20 Klawer
                                <a href="/assets/valuations/Tussenskatting 1STE 2019_20 Klawer.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>  
                        <mz-collection-item >
                            Tussenskatting 1STE 2019_20 Lutzville
                                <a href="/assets/valuations/Tussenskatting 1STE 2019_20 Lutzville.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>  
                        <mz-collection-item >
                            Tussenskatting 1STE 2019_20 Olifantsrivier Neder Sett
                                <a href="/assets/valuations/Tussenskatting 1STE 2019_20 Olifantsrivier Neder Sett.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Tussenskatting 1STE 2019_20 RIETPOORT
                                <a href="/assets/valuations/Tussenskatting 1STE 2019_20 RIETPOORT.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>   
                         <mz-collection-item >
                            Tussenskatting 1STE 2019_20 Strandfontein
                                <a href="/assets/valuations/Tussenskatting 1STE 2019_20 Strandfontein.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Tussenskatting 1STE 2019_20 Vanrhynsdorp
                            <a href="/assets/valuations/Tussenskatting 1STE 2019_20 Vanrhynsdorp.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>   
                        <mz-collection-item >
                            Tussenskatting_Landelik 1STE 2019_20 
                            <a href="/assets/valuations/Tussenskatting_Landelik 1STE 2019_20 .pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            Vredendal noord 1STE 2019_20
                            <a href="/assets/valuations/Vredendal noord 1STE 2019_20.pdf" mz-secondary-content download><i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>         
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
            <!-- End of Tussenskatting Collapsible & Collection -->   
        </mz-collapsible>  <!--End of second collapsible-->
    </div>
</div>
</div>








